import {
	CreateAnimation,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonItemDivider,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { fit, position, fitAndPosition } from 'object-fit-math';
/* Styles */
import styles from '../styles/video.scss';
import fonts from '../styles/fonts.scss';
import VideoBtn from '../nav/VideoBtn';
import { TweenMax } from 'gsap/gsap-core';
import { Sine } from 'gsap/gsap-core';
import gsap from 'gsap/gsap-core';
import { TweenLite } from 'gsap/gsap-core';

class VideoSeo extends React.Component {
	render() {
		return <></>;
	}
}

VideoSeo.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withIonLifeCycle(VideoSeo);
