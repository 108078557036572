import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonTitle,
	IonRow,
	IonText,
	withIonLifeCycle,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import React, {
	createRef,
	useEffect,
	useRef,
	useStatem,
	useState,
} from 'react';
import ReactDOM from 'react-dom';
import {
	Canvas,
	useFrame,
	extend,
	useThree,
	useLoader,
} from 'react-three-fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { fit, position, fitAndPosition } from 'object-fit-math';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import ProductItem from '../screens/ProductItem';

import productStyles from '../styles/product.scss';
import factsStyles from '../styles/facts.scss';
import navStyles from '../styles/nav.scss';
import videoStyles from '../styles/video.scss';
import PageBtn from '../nav/PageBtn';
////import CommerceProducts from '../commercetools/CommerceProducts';
//import CommerceCart from '../commercetools/CommerceCart';
import CloseBtn from '../nav/CloseBtn';
import BasketBtn from '../nav/BasketBtn';
//import CommerceAddToCart from '../commercetools/CommerceAddToCart';
import { gql, useQuery } from '@apollo/client';
import VideoBtn from '../nav/VideoBtn';
import BasketNav from '../nav/BasketNav';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
//import CommerceHelperUtil from '../commercetools/CommerceHelperUtil';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GAEvent, {
	CATEGORY_VIDEO_FACTS,
	LABEL_VIDEO_FACTS,
} from '../services/GAEvent';

//calc(24px + 8*(100vw - 320px)/1600);

function Facts(props) {
	const [pageWidth, setWidth] = useState(window.innerWidth);
	const [pageHeight, setHeight] = React.useState(window.innerHeight);
	const [value, setValue] = useState(0);
	const [playing, setPlaying] = useState(true);
	const [loaded, setLoaded] = useState(false);

	const [muted, setMuted] = useState(true);
	const valueRef = useRef();
	const owlRef = useRef();
	const slug = props.match.params.id;
	let playerRef = useRef();
	let playerHeroRef = useRef();
	let factsData = CraftHelperUtil.getFact(props.craftData, slug);

	React.useEffect(() => {
		//console.log('RENDER PRODUCT', slug);
		setLoaded(true);
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);
	useIonViewWillEnter(() => {
		//console.log('ionViewWillEnter event fired');
		if (playerRef) {
			playerRef.seekTo(0);
		}
		if (playerHeroRef) {
			playerHeroRef.seekTo(0);
		}
		setMuted(false);
		setPlaying(true);
	});

	useIonViewWillLeave(() => {
		//console.log('ionViewWillLeave event fired');
		if (playerRef) {
			playerRef.seekTo(0);
		}
		if (playerHeroRef) {
			playerHeroRef.seekTo(0);
		}
		setMuted(true);
		setPlaying(false);
	});

	useIonViewDidEnter(() => {
		setPlaying(true);
		setMuted(true);
	});

	useIonViewDidLeave(() => {
		setPlaying(false);
	});

	const onPlayVideo = () => {
		//console.log('onPlayVideo');
		GAEvent.videoViewed(CATEGORY_VIDEO_FACTS, slug);
	};

	///
	///

	const onProgress = ({ playedSeconds }) => {
		//console.log(valueRef.current);
	};

	const playVideo = (url) => {
		//console.log('playVideo');
		if (playerRef) {
			playerRef.seekTo(0);
		}
		if (playerHeroRef) {
			playerHeroRef.seekTo(0);
		}
		setMuted(false);
		setPlaying(true);
	};

	const onClose = () => {
		if (playerRef) {
			playerRef.seekTo(0);
		}
		if (playerHeroRef) {
			playerHeroRef.seekTo(0);
		}
		setMuted(true);
		setPlaying(false);
		props.history.push('/pano/forest');
	};
	const onBasketClick = () => {
		props.onBasketClick();
	};
	const onEnded = () => {
		GAEvent.videoCompleted(CATEGORY_VIDEO_FACTS, LABEL_VIDEO_FACTS);
	};

	//skus

	//102518,102519
	let navProductBreak = pageWidth < 1200 ? true : false;
	let tabletPortraitBreak = pageWidth < 1030 ? true : false;
	let mobileBreak = pageWidth < 400 ? true : false; //540
	let videoWidthBreak = pageWidth < 1000 ? true : false; //540
	let sidebarEval = 535;
	if (pageWidth < 1172) {
		sidebarEval = 400;
	}
	const heroImageSpaceWidth = tabletPortraitBreak
		? pageWidth
		: pageWidth - sidebarEval - 10;
	const heroImageSpaceHeight = pageHeight;
	const parentSize = {
		width: tabletPortraitBreak ? pageWidth : sidebarEval,
		height: tabletPortraitBreak ? pageWidth * 0.55 : 236,
	};
	const heroParentSize = {
		width: heroImageSpaceWidth,
		height: heroImageSpaceHeight,
	};

	const factsVideoHeight = factsData.productVideoHeight
		? factsData.productVideoHeight
		: 1080;

	const childSize = { width: 1920, height: factsVideoHeight };
	const heroChildSize = { width: 1920, height: 1080 };
	const fitted = fit(parentSize, childSize, 'cover');
	const fittedPosition = fitAndPosition(
		parentSize,
		childSize,
		'cover',
		'50%',
		'50%'
	);
	const heroFittedPosition = fitAndPosition(
		heroParentSize,
		heroChildSize,
		'cover',
		'50%',
		'50%'
	);

	const factHeroImage = factsData.factHeroImage[0];
	let factHeroImageUrl = '';
	if (factHeroImage) {
		factHeroImageUrl = factHeroImage.url;
	}
	if (owlRef.current) {
		// eslint-disable-next-line
		ReactDOM.findDOMNode(owlRef.current).classList.remove('owl-hidden');
	}
	let allowRender = props.allowRender;
	//allowRender = true;
	if (!allowRender) {
		return <></>;
	} else {
		//console.log('#RENDER Facts', factsData.factVideoUrl);
		return (
			<IonPage
				className="ion-no-padding ion-no-margin"
				style={{
					//display: 'flex',

					width: '100%',
					height: '100%',

					backgroundColor: 'white',
				}}
			>
				<IonRow
					style={{
						width: '100%',
						height: '100%',
						//flexDirection: 'column',
						//backgroundColor: 'red',
						//justifyContent: 'center',
						//alignItems: 'center',
						//pointerEvents: 'none',
						//backgroundColor: 'blue',
					}}
				>
					<IonGrid
						className="ion-no-padding ion-no-margin"
						style={{
							//display: 'none',
							//backgroundColor: 'pink',
							//position: 'absolute',
							flexDirection: 'column',
							//justifyContent: 'flex-start',
							//alignItems: 'center',
							width: heroImageSpaceWidth,
							height: '100%',
							//zIndex: 2,
						}}
					>
						<IonRow
							className="ion-no-padding ion-no-margin"
							style={{
								//display: 'flex',
								//flex: 1,
								//marginTop: '50px',
								//	flexDirection: 'column',
								//backgroundColor: 'red',
								//justifyContent: 'center',
								//alignItems: 'center',
								//backgroundColor: 'yellow',
								//height: heroImageSpaceHeight,
								//width: heroImageSpaceWidth,
								justifyContent: 'center',
								alignItems: 'center',
								//backgroundImage: 'url(' + factHeroImageUrl + ')',
								//backgroundRepeat: 'no-repeat',
								margin: '0',
								width: '100%',
								height: '100%',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								//transform: ' scale3d(1, 1, 1)',
							}}
						>
							<IonRow
								className="ion-no-padding ion-no-margin"
								style={{
									//display: 'flex',
									//flex: 1,
									height: heroImageSpaceHeight,
									width: heroImageSpaceWidth,
								}}
							></IonRow>
							<IonRow
								//className="videoContain"
								style={{
									position: 'absolute',
									width: heroFittedPosition.width,
									height: heroFittedPosition.height,
									left: heroFittedPosition.x,
									top: heroFittedPosition.y,
								}}
							>
								<ReactPlayer
									ref={(player) => {
										playerHeroRef = player;
									}}
									loop={true}
									url={factsData.factHeroVideoUrl}
									key={factsData.factHeroVideoUrl}
									playing={playing}
									playsinline={true}
									//controls={tabletPortraitBreak}
									volume={0}
									muted={true}
									width={heroFittedPosition.width}
									height={heroFittedPosition.height}
								></ReactPlayer>
							</IonRow>
						</IonRow>
					</IonGrid>
					{/*Content Bar*/}
					<IonContent
						className="ion-no-padding ion-no-margin"
						style={{
							//display: 'block',
							//flex: 1,
							//float: 'right',
							//zIndex: 10,
							position: tabletPortraitBreak ? 'absolute' : 'relative',
							//backgroundColor: 'red',
							//pointerEvents: 'auto',
							//flexDirection: 'column',
							//zIndex: 1,
							backgroundColor: 'white',
						}}
					>
						<IonRow
							className="ion-no-padding ion-no-margin"
							//className="videoContainSidebar"
							style={{
								position: 'absolute',
								width: '100%',
								marginRight: '32px',
								zIndex: 99,
								justifyContent: 'flex-end',
							}}
						>
							<BasketNav
								showBasket={navProductBreak ? true : false}
								dark={false}
								showClose={true}
								onClose={onClose}
								onBasketClick={props.onBasketClick}
							/>
						</IonRow>

						<IonRow
							//className="videoContain"
							style={{
								position: 'absolute',
								width: fittedPosition.width,
								height: fittedPosition.height,
								left: fittedPosition.x,
								top: 0,
							}}
						>
							{allowRender && (
								<ReactPlayer
									ref={(player) => {
										playerRef = player;
									}}
									loop={false}
									url={factsData.factVideoUrl}
									key={factsData.factVideoUrl}
									playing={playing}
									playsinline={true}
									controls={true}
									volume={1}
									muted={false}
									width={fittedPosition.width}
									height={fittedPosition.height}
									onPlay={onPlayVideo}
								></ReactPlayer>
							)}

							{/*
						<div
							className="ion-no-padding ion-no-margin"
							//className="videoContainSidebar"
							style={{
								//display: 'flex',
								width: fittedPosition.width,
								height: fittedPosition.height - 10,
								position: 'absolute',
								//backgroundColor: 'blue',
								//justifyContent: 'center',
								//alignItems: 'center',
							}}
						>
						
							<VideoBtn
								tabletPortraitBreak={tabletPortraitBreak}
								playing={playing}
								//icon="/ui/SVG/basket.svg"
								btnClass="videoLightBtn"
								btnOverClass="videoLightBtnOver"
								label="WATCH FULL VIDEO"
								onClick={() =>
									playVideo(
										factsData.factVideoUrl
											? factsData.factVideoUrl
											: 'https://vimeo.com/518353278'
									)
								}
							/>
						</div>
							*/}

							<IonRow
								className="ion-no-padding ion-no-margin"
								style={{
									//display: 'block',
									width: '100%',
									//justifyContent: 'center',
									justifyContent: 'center',
								}}
							>
								<IonRow
									className="ion-no-padding ion-no-margin "
									style={{
										display: 'block',
										//flex: 1,
										width: fittedPosition.width - 32,
										//maxWidth: mobileBreak ? '100%' : '1024px',
									}}
								>
									<IonRow
										className="ion-no-padding ion-no-margin"
										style={{
											//display: 'flex',
											flex: 1,
											flexDirection: tabletPortraitBreak ? 'column' : 'column',

											//alignItems: 'flex-end',
											//marginLeft: '16px',
											//marginRight: '16px',
										}}
									>
										<IonCol
											style={{
												display: 'flex',
												flexDirection: 'column',
												width: tabletPortraitBreak ? '100%' : '100%',
												flex: 1,
												paddingRight: '36px',

												//backgroundColor: 'green',
											}}
										>
											<IonRow
												style={
													{
														//marginTop: '-20px',
													}
												}
												className="factsContent"
											>
												<div
													className="factsTitle"
													style={{
														textTransform: 'uppercase',
														marginTop: '40px',
													}}
												>
													{factsData.title}
												</div>

												<div
													style={{
														marginTop: '16px',
													}}
												>
													{factsData.factIntro && <p>{factsData.factIntro}</p>}
												</div>

												{factsData.factBody && (
													<div
														style={{
															marginTop: '0px',
														}}
													>
														{parse(factsData.factBody)}
													</div>
												)}
											</IonRow>
										</IonCol>
										<IonCol
											className="ion-no-padding ion-no-margin"
											style={{
												display: 'flex',
												//height: '320px',
												width: tabletPortraitBreak ? '100%' : '100%',
												marginTop: tabletPortraitBreak ? '0px' : '16px',
												marginBottom: '32px',
												//marginLeft: '16px',
												flex: 1,
												//backgroundColor: 'blue',
											}}
										>
											{factsData.factImages.length > 0 && loaded && (
												<OwlCarousel
													className="owl-theme owl-loaded"
													margin={32}
													items={1}
													ref={owlRef}
												>
													{factsData.factImages.map((image, index) => (
														<div
															className="carousel-item"
															key={'factImage' + index}
														>
															<img
																alt="facts-image"
																id="facts-image"
																src={factsData.factImages[index].url}
															/>
														</div>
													))}
												</OwlCarousel>
											)}
										</IonCol>
									</IonRow>
								</IonRow>
							</IonRow>
						</IonRow>
					</IonContent>
				</IonRow>
			</IonPage>
		);
	}
}
Facts.propTypes = {
	allowRender: PropTypes.array,
	props: PropTypes.object,
	navigation: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	onClick: PropTypes.func,
	onBasketClick: PropTypes.func,
	craftData: PropTypes.object,
	onClose: PropTypes.func,
};

export default Facts;
