import {
	CreateAnimation,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonItemDivider,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import PageBtn from '../nav/PageBtn';
import parse from 'html-react-parser';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
/* Styles */
import styles from '../styles/science.scss';
import btnstyles from '../styles/nav.scss';
import fonts from '../styles/fonts.scss';

import svgScienceCircle from '../assets/ui/SVG/science_circle.svg';
import svgScienceIcon from '../assets/ui/SVG/science_icon.svg';

class Science extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mouseOverBtn: false,
			width: window.innerWidth,
			height: window.innerHeight,
		};
		this.animationRef = createRef();
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.onClick = this.onClick.bind(this);
	}
	componentDidMount(nextProps) {
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	onClick() {
		this.props.history.push('/science/videos');
	}
	onMouseEnter() {
		this.setState({ mouseOverBtn: true });
	}
	onMouseLeave() {
		this.setState({ mouseOverBtn: false });
	}

	render() {
		let tabletPortraitBreak = this.state.width < 824 ? true : false;
		let mobileBreak = this.state.width < 500 ? true : false; //540

		let scienceTitle = CraftHelperUtil.getText(
			this.props.craftData,
			'scienceTitle'
		);
		let scienceBody = CraftHelperUtil.getText(
			this.props.craftData,
			'scienceBody'
		);

		let scienceImage = CraftHelperUtil.getImage(
			this.props.craftData,
			'scienceImage'
		);
		if (!this.props.allowRender) {
			return <></>;
		} else {
			//console.log('#RENDER Science');
			return (
				<IonPage
					style={
						{
							//marginTop: '600px',
						}
					}
				>
					<IonContent>
						<IonRow
							className="scienceBackground"
							style={{
								height: '100%',
								width: '100%',
								display: 'flex',
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center',
								backgroundImage: 'url(' + scienceImage + ')',

								//backgroundColor: 'blue',
							}}
						>
							<IonCol
								style={{
									display: 'flex',

									flex: 1,

									justifyContent: 'center',
								}}
							>
								<div
									style={{
										width: '375px',
										//width: '25%',
										//minWidth: '375px',
										//height: '448px',
										//boxShadow: 'none',
										display: 'flex',
										flexDirection: 'row',

										//marginLeft: tabletPortraitBreak ? '0px' : ' -800px',
										marginTop: 'min(120px, 120px)',
										//marginTop: '-100px',
										borderRadius: '16px',
										backgroundColor: 'rgba(0, 0, 0, 0.8)',

										//overflow: 'block',
										padding: '30px',
										paddingBottom: '40px',
										//textAlign: 'center',
										//marginLeft: '400px',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									></div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<div
											style={{
												marginTop: '-90px',
												marginLeft: '0px',
												marginBottom: '20px',
											}}
										>
											<div className="scienceIconBackground">
												<div
													style={{
														justifyContent: 'center',
														alignItems: 'center',
														width: '100%',
														height: '100%',
													}}
												>
													<img
														className="scienceCircle"
														alt="science_logo"
														id="science_logo"
														src={svgScienceCircle}
													/>
													<img
														className="scienceIcon"
														//className="blended"
														alt="science_icon"
														id="science_icon"
														src={svgScienceIcon}
													/>
												</div>
											</div>
										</div>
										<IonText color="secondary" className="scienceTitle">
											{scienceTitle}
										</IonText>
										<IonText color="secondary" className="scienceBody">
											{parse(scienceBody)}
										</IonText>
										<PageBtn
											marginTop={20}
											id={0}
											icon={svgScienceIcon}
											label="Find out more"
											color="#FFE297"
											btnClass="pageLightBtn"
											btnOverClass="pageLightBtnOver"
											onClick={this.onClick}
										/>
									</div>
								</div>
							</IonCol>
							<IonCol
								style={{
									display: 'flex',
									flex: tabletPortraitBreak ? 0 : 1,
								}}
							></IonCol>
						</IonRow>
					</IonContent>
				</IonPage>
			);
		}
	}
}

Science.propTypes = {
	allowRender: PropTypes.array,
	history: PropTypes.object,
	location: PropTypes.object,
	craftData: PropTypes.object,
};

export default withIonLifeCycle(Science);
