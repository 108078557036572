import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
	IonText,
	withIonLifeCycle,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
	useIonViewWillLeave,
	IonTitle,
} from '@ionic/react';
import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import styles from '../styles/home.scss';
import fonts from '../styles/fonts.scss';
import Spinner from './Spinner';
import gsap from 'gsap/gsap-core';
import { TweenMax } from 'gsap/gsap-core';
import loadingBee01 from '../assets/loading/loading-bee01@4x.jpeg';
import loadingBee02 from '../assets/loading/loading-bee02@4x.jpeg';
import loadedBee from '../assets/loading/loaded-bee03@4x.jpeg';

import imgForestAlpha1 from '../assets/forest/forest_alpha01.jpeg';
import imgForestAlpha2 from '../assets/forest/forest_alpha02.jpeg';
import imgForestAlpha3 from '../assets/forest/forest_alpha03.jpeg';
import imgForestClouds from '../assets/forest/forest_clouds.jpeg';

//import imgForest01 from '../assets/forest/forest01.jpeg';
//import imgForest01Alpha from '../assets/forest/forest01_alpha.jpeg';
import imgForest02 from '../assets/forest/forest02.jpeg';
//import imgForest02Alpha from '../assets/forest/forest02_alpha.jpeg';
import imgForest03 from '../assets/forest/forest03.jpeg';
//import imgForest03Alpha from '../assets/forest/forest03_alpha.jpeg';
import imgForest04 from '../assets/forest/forest04.jpeg';
//import imgClouds from '../assets/clouds/clouds2.jpg';
import imgStore from '../assets/store/store03.jpg';
import imgStoreAlpha from '../assets/store/store03_alpha.jpg';

import imgShelfDesktop from '../assets/shelf/shelfdesktop8.jpg';
import imgShelfMobile from '../assets/shelf/shelfmobile8.jpg';
import imgProductBack from '../assets/products/productback.jpg';
import imgHomeMetal from '../assets/home/metal.jpg';
import envStoreMap from '../assets/env/comvita_store.hdr';

import imgPanel1 from '../assets/ui/2x/panel1@2x.png';
import imgPanel2 from '../assets/ui/2x/panel2@2x.png';
import imgPanel3 from '../assets/ui/2x/panel3@2x.png';
import imgPanel4 from '../assets/ui/2x/panel4@2x.png';
import imgHive from '../assets/store/A002_012.jpeg';

import imgScienceBack from '../assets/science/sciencebackground.jpg';
import imgCircleTexture from '../assets/bees/bee02.png';

var imagesToLoad = [
	imgForestAlpha1,
	imgForestAlpha2,
	imgForestAlpha3,
	imgForestClouds,
	loadedBee,
	//imgForest01,
	//imgForest01Alpha,
	imgForest02,
	//imgForest02Alpha,
	imgForest03,
	//imgForest03Alpha,
	imgForest04,
	//imgClouds,
	imgStore,
	imgStoreAlpha,
	imgShelfDesktop,
	imgShelfMobile,
	imgProductBack,
	imgHomeMetal,
	envStoreMap,
	imgPanel1,
	imgPanel2,
	imgPanel3,
	imgPanel4,
	imgHive,
	imgScienceBack,
	imgCircleTexture,
];
console.log("imgCircleTexture", imgCircleTexture)
export {
	imgForestAlpha1,
	imgForestAlpha2,
	imgForestAlpha3,
	imgForestClouds,
	loadedBee,
	//imgForest01,
	//imgForest01Alpha,
	imgForest02,
	//imgForest02Alpha,
	imgForest03,
	//imgForest03Alpha,
	imgForest04,
	//imgClouds,
	imgStore,
	imgStoreAlpha,
	imgShelfDesktop,
	imgShelfMobile,
	imgProductBack,
	imgHomeMetal,
	envStoreMap,
	imgPanel1,
	imgPanel2,
	imgPanel3,
	imgPanel4,
	imgHive,
	imgScienceBack,
	imgCircleTexture,
};

const IMAGES = gql`
	query ProductBySku($skus: [String!]) {
		products(limit: 100, skus: $skus) {
			total
			results {
				__typename
				key
				id
				skus
				masterData {
					published
					current {
						nameAllLocales {
							locale
							value
						}
						descriptionAllLocales {
							locale
							value
						}
						slugAllLocales {
							locale
							value
						}
						categories {
							__typename
							id
						}
						allVariants {
							__typename
							id
							sku
							attributesRaw {
								name
								value
							}
							availability {
								channels {
									total
									results {
										channel {
											__typename
											id
											key
											roles
										}
										availability {
											isOnStock
											restockableInDays
											availableQuantity
										}
									}
								}
							}
							prices {
								value {
									centAmount
									currencyCode
								}
							}
						}
					}
				}
			}
		}
	}
`;

const Preloader = (props) => {
	const [loaded, setLoaded] = useState(false);

	const loadingImage1 = useRef();
	const loadingImage2 = useRef();
	const cacheAllImages = (paths) => Promise.all(paths.map(cacheImage));

	const cacheImage = (path) =>
		new Promise((resolve) => {
			const img = new Image();
			img.onload = () => resolve({ path, status: 'ok' });
			img.onerror = () => resolve({ path, status: 'error' });
			img.src = path;
		});

	React.useEffect(() => {
		//loading crossfades
		// eslint-disable-next-line
		var imgs = $.makeArray($('#loadingcontainer img'));
		const crossfade = () => {
			TweenMax.to(imgs[0], 1, { autoAlpha: 0, scale: 1 });
			TweenMax.to(imgs[1], 1, { autoAlpha: 1, scale: 1 });
			imgs.push(imgs.shift());
		};
		setInterval(crossfade, 300);
		//preload Wait
		setTimeout(() => {
			//console.log('cacheAllImages');
			cacheAllImages(imagesToLoad)
				.then((data) => props.onLoaded(data))
				.catch((error) => console.error(error));
		}, 1600);
	}, []);

	//

	return (
		<IonPage>
			<div
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
					flex: 1,
					flexDirection: 'column',
					backgroundColor: '#19130D',
					width: '100%',
					height: '100%',
				}}
			>
				<div
					id="loadingcontainer"
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						flex: 1,
						flexDirection: 'column',

						width: '100%',
						height: '100%',
					}}
				>
					<img height="200px" alt="loading1" id="loading1" src={loadingBee01} />
					<img height="200px" alt="loading1" id="loading1" src={loadingBee02} />
				</div>
			</div>
		</IonPage>
	);
};

Preloader.propTypes = {
	props: PropTypes.object,
	onLoaded: PropTypes.func,
};

export default Preloader;
