class CraftHelperUtil {
	getCommerceToolsKey(data, field) {
		let key;
		if (!data) {
			return null;
		}
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				if (field == 'ctDevClientid') {
					key = store.ctDevClientid;
				} else if (field == 'ctDevClientsecret') {
					key = store.ctDevClientsecret;
				} else if (field == 'ctProdClientid') {
					key = store.ctProdClientid;
				} else if (field == 'ctProdClientsecret') {
					key = store.ctProdClientsecret;
				}
			}
		});

		let buff = new Buffer(key);
		let base64data = buff.toString('base64');
		//console.log('##encode', field, key, base64data);

		return key;
	}

	getCommerceToolsKeyEnc(data, field) {
		let key;
		if (!data) {
			return null;
		}
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				if (field == 'ctDevClientidEnc') {
					key = store.ctDevClientidEnc;
				} else if (field == 'ctDevClientsecretEnc') {
					key = store.ctDevClientsecretEnc;
				} else if (field == 'ctProdClientidEnc') {
					key = store.ctProdClientidEnc;
				} else if (field == 'ctProdClientsecretEnc') {
					key = store.ctProdClientsecretEnc;
				}
			}
		});
		const decode = Buffer.from(key, 'base64').toString('ascii');
		//console.log('##decode', field, decode);
		//let buff = new Buffer(key);
		//let base64data = buff.toString('base64');
		//console.log('##key', field, key, base64data);

		return decode;
	}

	getShelfProducts(data, shelfId) {
		let productGroups;
		if (!data) {
			return null;
		}
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				productGroups = store.productGroups;
				store.productGroups.forEach(function (group) {
					//group.productsList.forEach(function (product) {
					//	const item = product.product[0];
					//	console.log('product', product);
					//});
				});
			}
		});
		return productGroups[shelfId].productsList;
	}

	getScienceVideos(data) {
		let videos;
		if (!data) {
			return null;
		}
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				videos = store.videoGridItems;
			}
		});
		return videos;
	}

	getIntroVideo(data) {
		let url;
		if (!data) {
			return null;
		}
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				url = store.introVideoUrl;
			}
		});
		return url;
	}

	getProduct(data, slug) {
		if (!data) {
			return null;
		}
		let productData = null;
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				store.productGroups.forEach(function (group) {
					group.productsList.forEach(function (product) {
						const item = product.product[0];
						//console.log('item:', item);
						if (item.slug === slug) {
							//console.log('product:', item.slug);
							productData = item;
						}
					});
				});
			}
		});

		return productData;
	}

	getFact(data, slug) {
		if (!data) {
			return null;
		}
		let factData;
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				store.panos.forEach(function (pano) {
					pano.panoHotspots.forEach(function (hotspot) {
						//console.log('hotspot', hotspot.panoLink[0].typeHandle);
						if (
							slug === hotspot.panoLink[0].slug &&
							hotspot.panoLink[0].typeHandle === 'facts'
						) {
							factData = hotspot.panoLink[0];
						}
					});
				});
			}
		});
		//console.log('factData', factData);
		return factData;
	}

	getText(data, field) {
		if (!data) {
			return null;
		}
		let text;
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				text = store[field];
			}
		});
		//console.log('factData', factData);
		return text;
	}

	getImage(data, field) {
		if (!data) {
			return null;
		}
		let img;
		data.entries.forEach(function (store) {
			if (store.slug === 'comvita-nz') {
				img = store[field][0].url;
			}
		});
		//console.log('factData', factData);
		return img;
	}
}

export default new CraftHelperUtil();
