import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import svgBtnClose from '../assets/ui/SVG/btn_close.svg';
import svgBtnCloseLight from '../assets/ui/SVG/btn_close_light.svg';
import svgBtnCloseDark from '../assets/ui/SVG/btn_close_dark.svg';

function CloseBtn(props) {
	const [mouseOver, setMouseOver] = useState(false);
	//let btnClass = 'pageBtn ';
	//let btnOverClass = 'pageBtn pageBtnOver';

	return (
		<IonRow
			className="ion-no-padding ion-no-margin"
			style={{
				width: '50px',
				height: '50px',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
				//backgroundColor: 'red',
			}}
			onClick={props.onClick}
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
		>
			<IonRow className="ion-no-padding ion-no-margin">
				<img
					className="closeIcon"
					alt="btn_close"
					height="20px"
					src={
						props.mode == 'dark'
							? svgBtnCloseDark
							: props.mode == 'light'
							? svgBtnCloseLight
							: svgBtnClose
					}
				/>
			</IonRow>
		</IonRow>
	);
}

CloseBtn.propTypes = {
	props: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	id: PropTypes.number,
	color: PropTypes.string,
	icon: PropTypes.string,
	mode: PropTypes.string,
};

export default CloseBtn;
