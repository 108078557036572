import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonList,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonText,
	IonRow,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import fonts from '../styles/shelf.scss';
import styles from '../styles/video.scss';
import { fit, position, fitAndPosition } from 'object-fit-math';
import CloseBtn from '../nav/CloseBtn';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
import ReactPlayer from 'react-player';
import Video from './Video';
import BasketNav from '../nav/BasketNav';
import { imgScienceBack } from './Preloader';
import GAEvent, { CATEGORY_VIDEO_SCIENCE } from '../services/GAEvent';
import VideoThumb from './VideoThumb';
import svgPlayBtn from '../assets/ui/SVG/btn_play.svg';
import svgScienceIcon from '../assets/ui/SVG/emboss_science_icon.svg';
import imgScienceMetal from '../assets/science/4x/sciencegoldback@4x.jpg';
import VideoHero from './VideoHero';

const Videos = (props) => {
	if (!props.allowRender) {
		return <></>;
	}
	//console.log('#RENDER Videos');
	const [pageWidth, setWidth] = useState(window.innerWidth);
	const [pageHeight, setHeight] = useState(window.innerHeight);
	const [videoUrl, setVideoUrl] = useState('');
	const [currentSlug, setCurrentSlug] = useState('');
	const [showVideo, setShowVideo] = useState(false);

	React.useEffect(() => {
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		setShowVideo(false);
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	const onClick = (route, slug) => {
		setCurrentSlug(slug);
		setVideoUrl(route);
		setShowVideo(true);
		GAEvent.videoViewed(CATEGORY_VIDEO_SCIENCE, slug);
		props.history.push('/science/videos/view/' + slug);
	};

	const onClose = () => {
		if (props.location.state) {
			//props.history.goBack();
			props.history.push('/science');
		} else {
			props.history.push('/science');
		}
	};

	const onCloseVideo = () => {
		setShowVideo(false);
		props.history.push('/science/videos');
	};

	let tabletPortraitBreak = pageWidth < 1050 ? true : false;
	let mobileBreak = pageWidth < 700 ? true : false; //540
	let videos = CraftHelperUtil.getScienceVideos(props.craftData);

	const containerWidth = pageWidth * 0.8;
	const colNum = tabletPortraitBreak ? (mobileBreak ? 1 : 2) : 3;
	const shelfItemWidth = containerWidth / colNum;
	const shelfItemHeight = shelfItemWidth * 0.56;
	const heroItem = videos[0];
	let heroWidth = shelfItemWidth * 2 + 32;
	let holderWidth = shelfItemWidth * 3 + 32 * 3;
	let iconRatio = shelfItemWidth / 300;
	let fontRatio = shelfItemWidth / 400;
	if (colNum == 2) {
		heroWidth = shelfItemWidth * 2 + 32;
		holderWidth = shelfItemWidth * 2 + 32 * 2;
		iconRatio = shelfItemHeight / 200;
		fontRatio = shelfItemHeight / 300;
	} else if (colNum == 1) {
		heroWidth = shelfItemWidth;
		holderWidth = shelfItemWidth + 32;
		iconRatio = shelfItemHeight / 200;
		fontRatio = shelfItemHeight / 300;
	}

	fontRatio = fontRatio > 1.2 ? 1.2 : fontRatio;
	iconRatio = iconRatio > 1.2 ? 1.2 : iconRatio;
	const heroHeight = heroWidth * 0.56;
	var videoSplice = [...videos];
	videoSplice.shift();

	const heroVideoRow = (
		<IonRow
			style={{
				display: 'flex',
				//flex: 1,
				flexWrap: 'wrap',
				//alignItems: 'flex-start',
				//justifyContent: 'center',
				//backgroundColor: 'red',
			}}
		>
			<VideoHero
				index={0}
				data={heroItem}
				onClick={onClick}
				width={heroWidth}
				height={heroHeight}
			/>
		</IonRow>
	);

	const shelfItems = (
		<IonRow
			style={{
				//display: 'flex',
				//flex: 1,
				//width: '100%',
				//height: '100%',
				flexWrap: 'wrap',
				//alignItems: 'flex-start',
				//justifyContent: 'center',
				//justifyContent: 'center',
				//backgroundColor: 'red',
			}}
		>
			<IonRow
				style={{
					//display: 'flex',
					//flex: 1,
					//flexWrap: 'wrap',
					width: holderWidth,
					//backgroundColor: 'green',
					flexDirection: 'row',
					//alignItems: 'flex-start',
					//justifyContent: 'center',
				}}
			>
				{heroVideoRow}
				<IonRow
					style={{
						width: shelfItemWidth,
						height: colNum > 2 ? heroHeight : shelfItemHeight,

						//display: 'flex',
						//flexDirection: 'column',
						//cursor: 'pointer',
						alignItems: 'center',
						justifyContent: 'center',
						//backgroundColor: '#000000',
						margin: '16px',

						backgroundImage: 'url(' + imgScienceMetal + ')',
						backgroundPosition: 'center',
						backgroundSize: '100% 100%',
					}}
				>
					<IonRow
						style={{
							//justifyContent: 'center',
							//alignItems: 'center',
							//justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							//height: '100%',
						}}
					>
						<IonRow
							style={{
								//backgroundColor: 'red',
								marginLeft: '32px',
								width: '100%',
								//marginTop: '32px',
							}}
						>
							<img
								className="scienceIcon blended"
								alt="science_icon"
								id="science_icon"
								height={80 * iconRatio}
								src={svgScienceIcon}
							/>
						</IonRow>
						<IonText
							style={{
								fontSize: 32 * fontRatio,
							}}
							className="metalTitle engraved blended"
						>
							Unlocking nature&apos;s secrets
						</IonText>
						<IonText
							style={{
								fontSize: 20 * fontRatio,
							}}
							className="metalBody engraved blended"
						>
							With our Chief Science Officer Dr Jackie Evans
						</IonText>
					</IonRow>
				</IonRow>
				{videoSplice.map((item, index) => {
					return (
						<IonRow
							className="videoFrame"
							key={'video_' + index}
							style={{
								width: shelfItemWidth,
								height: shelfItemHeight,

								//display: 'flex',
								//flexDirection: 'column',
								cursor: 'pointer',
								//alignItems: 'center',
								//justifyContent: 'center',
								backgroundColor: '#000000',
								margin: '16px',
							}}
						>
							<VideoThumb
								index={index}
								data={item}
								onClick={onClick}
								width={shelfItemWidth}
								height={shelfItemHeight}
							/>
						</IonRow>
					);
				})}
			</IonRow>
		</IonRow>
	);

	return (
		<IonPage className="ion-no-padding ion-no-margin">
			{/*	<IonRow
				className="navBackgroundBlack"
				style={{
					position: 'absolute',
					width: '100%',
					height: '100px',
					zIndex: 1,
				}}
			></IonRow>
			<IonRow
				className="ion-no-padding ion-no-margin"
				//className="videoContainSidebar"
				style={{
					position: 'absolute',
					width: '100%',
					//paddingRight: '16px',
					justifyContent: 'flex-end',
					//	backgroundColor: 'blue',
					zIndex: 2,
				}}
			>
				<BasketNav
					dark={false}
					showBasket={false}
					showClose={true}
					onClose={onClose}
				/>
			</IonRow>*/}
			<IonRow
				className="ion-no-padding ion-no-margin"
				style={{
					position: 'fixed',

					width: '100%',
					height: '100%',
				}}
			>
				<IonContent
					className="videosBackground ion-no-padding ion-no-margin"
					style={{
						backgroundImage: 'url(' + imgScienceBack + ')',
					}}
				>
					<IonGrid
						className="ion-no-padding ion-no-margin"
						style={{
							width: '100%',
							//height: fittedPosition.height,
							marginTop: '80px',
							//display: 'flex',
							flexDirection: 'column',
						}}
					>
						{/*}	<IonRow>
							<div className="mainTitle">Science at Comvita</div>
					</IonRow>*/}
						<IonRow
							style={{
								//display: 'flex',
								width: '100%',
								//paddingTop: '0px',
								//paddingBottom: '0px',
								//flex: 1,
								//backgroundColor: '#ff0000',
							}}
						>
							<IonRow
								style={{
									width: '100%',
									//display: 'flex',
									//flex: 1,
									//flexDirection: 'row',
									//backgroundColor: 'green',
								}}
							>
								<IonRow
									style={{
										//display: 'flex',
										//flex: 1,
										width: '100%',
										paddingLeft: '16px',
										paddingRight: '16px',
										flexDirection: 'row',
										justifyContent: 'center',
										//backgroundColor: '#2dd36f80',
									}}
								>
									{shelfItems}
								</IonRow>
							</IonRow>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonRow>
			<IonRow
				style={{
					//display: 'flex',
					//flex: 1,
					zIndex: 20,
					position: 'absolute',

					width: '100%',
					height: showVideo ? '100%' : 0,
				}}
			>
				<Video
					slug={currentSlug}
					url={videoUrl}
					show={showVideo}
					onClose={onCloseVideo}
					mobileBreak={tabletPortraitBreak}
				/>
			</IonRow>
		</IonPage>
	);
};

Videos.propTypes = {
	allowRender: PropTypes.array,
	history: PropTypes.object,
	onClick: PropTypes.func,
	location: PropTypes.object,
	craftData: PropTypes.object,
};

export default Videos;
