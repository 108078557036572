import * as THREE from 'three';
import React, {
	Suspense,
	useState,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import { IonPage, IonRow, withIonLifeCycle } from '@ionic/react';
import { Canvas, useFrame, extend, useThree } from 'react-three-fiber';
import CanvasEffects from '../effects/CanvasEffects';
import Particles from '../particles/Particles';
import Clouds from '../pano/Clouds';
import StoreForest from '../pano/StoreForest';
import SphereBox from '../pano/SphereBox';
import PanelFloat from '../pano/PanelFloat';
import VideoPanel from '../pano/VideoPanel';
import CubeBox from '../pano/CubeBox';
import PanoToCubeBox from '../pano/PanoToCubeBox';
import HotSpots from '../pano/Hotspots';

import PropTypes from 'prop-types';
import { MathUtils } from 'three';
import { TweenLite, gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import styles from '../styles/pano.scss';
import { Linear } from 'gsap/gsap-core';
import { TweenMax } from 'gsap/gsap-core';
import { Quad } from 'gsap/gsap-core';
gsap.registerPlugin(CSSPlugin);

import { imgForest01 } from './Preloader';
//import { imgForest01Alpha } from './Preloader';
import {
	imgForestAlpha1,
	imgForestAlpha2,
	imgForestAlpha3,
	imgForestClouds,
} from './Preloader';
import { imgForest02, imgForest03, imgForest04 } from './Preloader';
import { imgStore, imgStoreAlpha } from './Preloader';
import { imgPanel1, imgPanel2, imgPanel3, imgPanel4 } from './Preloader';
import { imgHive } from './Preloader';
import CameraControls from './CameraControls';

Box.propTypes = {
	props: PropTypes.object,
};

function Box(props) {
	return (
		<mesh {...props} renderOrder={10}>
			<boxGeometry attach="geometry" args={[10, 1000, 10]} />
			<meshBasicMaterial
				transparent={true}
				opacity={0.1}
				color={'black'}
				attach="material"
				side={THREE.FrontSide}
				depthWrite={false}
				depthTest={false}
			//alphaTest={false}
			/>
		</mesh>
	);
}

class Pano extends React.Component {
	constructor(props) {
		super(props);
		this.state = { show: true, activePano: 1, disableEffects: false };
		this.lastActivePano = 1;
		this.onClickHotSpot = this.onClickHotSpot.bind(this);
		this.onClickPanel = this.onClickPanel.bind(this);
		this.onFadeToBlack = this.onFadeToBlack.bind(this);
		this.onFadeFromBlack = this.onFadeFromBlack.bind(this);
		//this.fadeFromBlackComplete = this.fadeFromBlackComplete.bind(this);

		//this.fadeToBlackComplete = this.fadeToBlackComplete.bind(this);
		this.onSphereTextureloaded = this.onSphereTextureloaded.bind(this);
		this.blackOverlayRef = React.createRef();
		this.panel1 = React.createRef();
		this.panel2 = React.createRef();
		this.panel3 = React.createRef();
		this.panel4 = React.createRef();
		this.fadeTween = null;
		//console.log('set this.blackOverlayRef', this.blackOverlayRef);
	}
	componentDidMount() {
		if (this.props.location.pathname == '/pano/store') {
			this.setState({ activePano: 3, disableEffects: false });
		} else if (this.props.location.pathname == '/pano/front') {
			this.setState({ activePano: 2, disableEffects: false });
		} else if (this.props.location.pathname == '/pano/forest') {
			this.setState({ activePano: 1, disableEffects: false });
		}
	}
	componentWillUnmount() {
		if (this.fadeTween) {
			//this.fadeTween.kill();
		}
	}

	componentDidUpdate(prevProps) {
		//console.log('#componentDidUpdate Pano');
		if (this.props.location.pathname !== prevProps.location.pathname) {
			if (this.props.location.pathname == '/pano/store') {
				this.onFadeToBlack();
				this.setState({ activePano: 3, disableEffects: false });
			} else if (this.props.location.pathname == '/pano/front') {
				this.onFadeToBlack();
				this.setState({ activePano: 2, disableEffects: false });
			} else if (this.props.location.pathname == '/pano/forest') {
				this.onFadeToBlack();
				this.setState({ activePano: 1, disableEffects: false });
			}
		}
	}

	onClickHotSpot(id) {
		//console.log('###onClickHotSpot', id);
		if (id == 100) {
			if (!this.fadeTween?.isActive()) {
				this.onFadeToBlack();
				this.setState({ activePano: 1, disableEffects: true });
				this.props.history.push('/facts/manuka');
				//console.log('this.props.history.push', '/facts/manuka');
			}
		} else if (id == 101) {
			if (!this.fadeTween?.isActive()) {
				this.onFadeToBlack();
				this.setState({ activePano: 1, disableEffects: true });
				this.props.history.push('/facts/bees');
				//console.log('this.props.history.push', '/facts/bees');
			}
		} else if (id == 1) {
			if (!this.fadeTween?.isActive()) {
				this.props.history.push('/pano/forest');
				//console.log('this.props.history.push', '/pano/forest');
			}
		} else if (id == 2) {
			if (!this.fadeTween?.isActive()) {
				this.props.history.push('/pano/front');
				//console.log('this.props.history.push', '/pano/front');
			}
		} else if (id == 3) {
			if (!this.fadeTween?.isActive()) {
				this.props.history.push('/pano/store');
				//console.log('this.props.history.push', '/pano/store');
			}
		}
	}
	onClickPanel(id) {
		this.props.history.push({
			pathname: '/shelf/' + id,
			state: { from: this.props.location.pathname },
		});
	}

	onSphereTextureloaded() {
		//console.log('onSphereTextureloaded');
		this.onFadeFromBlack(0.2);
	}

	onFadeToBlack() {
		if (this.blackOverlayRef.current) {
			this.fadeTween = TweenMax.to(this.blackOverlayRef.current, 0, {
				alpha: 1,
			});
		}
	}
	/*
	fadeToBlackComplete(id, lastActivePano, effects) {
		//console.log('fadeToBlackComplete', id);
		if (effects) {
			if (id == 100) {
				this.props.history.push('/facts/' + 'manuka');
			} else if (id == 101) {
				this.props.history.push('/facts/' + 'bees');
			}
		} else {
			this.setState({ activePano: id, disableEffects: effects });
		}
	}
	*/

	onFadeFromBlack(delay) {
		if (this.fadeTween) {
			this.fadeTween.kill();
		}

		if (this.blackOverlayRef.current) {
			this.fadeTween = TweenMax.fromTo(
				this.blackOverlayRef.current,
				0.6,
				{ autoAlpha: 1 },
				{
					autoAlpha: 0,
					ease: Quad.easeIn,
					delay: delay,
					//onComplete: this.fadeFromBlackComplete,
				}
			);
		}
	}
	fadeFromBlackComplete() {
		console.log('fadeToBlackComplete');
	}

	onFloatingPanelsReveal() { }

	render() {
		//const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		//const mouse = useRef([0, 0]);
		const hotspotData = [
			[
				{
					color: '#ffe297',
					position: [0, -5, -40],
					link: 1,
				},
			],
			[
				{
					color: '#ffe297',
					position: [-32, -16, -70],
					link: 100,
				},
				{
					color: '#ffe297',
					position: [-4, -14, -80],
					link: 2,
				},
				{
					color: '#ffe297',
					position: [25, -18, -70],
					link: 101,
				},
				/*{
					color: '#ffe297',
					position: [0, 0, 40],
					link: 0,
				},*/
			],
			[
				{
					color: '#ffe297',
					position: [4, -18, -40],
					link: 3,
				},
				{
					color: '#ffe297',
					position: [0, -18, 40],
					link: 1,
				},
			],
			[
				{
					color: null,
					position: [20, -5, 50],
					link: 2,
				},
			],
		];

		const sphereTextures = [
			{
				texture: imgForest02,
				textureAlpha: imgForestAlpha1,
				clouds: imgForestClouds,
				rotation: 90,
				backgroundRotation: 0,
				allowAlpha: true,
			},
			{
				texture: imgForest02,
				textureAlpha: imgForestAlpha2,
				clouds: imgForestClouds,
				rotation: 270,
				backgroundRotation: 0,
				allowAlpha: true,
			},
			{
				texture: imgForest03,
				textureAlpha: imgForestAlpha3,
				clouds: imgForestClouds,
				rotation: 270,
				backgroundRotation: 0,
				allowAlpha: true,
			},

			{
				texture: imgStore,
				textureAlpha: imgStoreAlpha,
				clouds: imgForest04,
				rotation: 270,
				backgroundRotation: 270,
				allowAlpha: true,
			},
		];

		const panelTextures = [
			{
				texture: imgPanel2,
				//textureAlpha: '../assets/ui/2x/panel2alpha@2x.jpeg',
				position: [-30, 0, -16],
			},

			{
				texture: imgPanel1,
				//textureAlpha: '../assets/ui/2x/panel1alpha@2x.jpeg',
				position: [-12, 0, -24],
			},
			{
				texture: imgPanel4,
				//textureAlpha: '../assets/ui/2x/panel4alpha@2x.jpeg',
				position: [10, 0, -16],
			},
			{
				texture: imgPanel3,
				//textureAlpha: '../assets/ui/2x/panel3alpha@2x.jpeg',
				position: [36, 0, -24],
			},
		];

		//const loadedPanoId = this.props.match.params.id;
		//console.log('loadedPanoId', this.props.match);
		//console.log('PANO RENDER', this.state.activePano);
		let godrays = this.state.activePano != 3;
		let effects = this.state.activePano != 3;
		var bees = this.state.activePano != 3;
		const storeforest = this.state.activePano == 3;
		godrays = false;
		effects = false;
		//bees = false;
		if (this.state.disableEffects) {
			//effects = false;
			bees = false;
		}

		const showSpheres = true;
		const showClouds = true;

		//if (this.props.disablePano) {
		//console.log('#Disable Pano');
		//return <></>;
		//} else {
		//console.log('#Render Pano');
		return (
			<IonPage
				className="ion-no-padding ion-no-margin "
				style={{
					width: '100%',
					height: '100%',
					//marginTop: '25%',
					//zIndex: 1,
					//justifyContent: 'center',
					//alignItems: 'center',
					//pointerEvents: 'none',
					//backgroundColor: 'blue',
					//display: this.props.disablePano ? 'none' : 'block',
				}}
			>
				<Canvas
					concurrent
					gl={{ antialias: false, alpha: false }}
					camera={{ position: [0, 0, 15], near: 1, far: 10000000 }}
					onCreated={({ gl }) => {
						gl.setClearColor('black');
						gl.toneMapping = THREE.NoToneMapping;
					}}
					pixelRatio={window.devicePixelRatio ? window.devicePixelRatio : 1}
					//pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
					//camera={{ fov: 90, position: [0, 0, 1] }}
					invalidateFrameloop={this.props.disablePano}
					style={{ display: this.props.disablePano ? 'none' : 'block' }}
				>
					<CameraControls
						enableZoom={false}
						enablePan={true}
						enableDamping
						dampingFactor={!this.props.disablePano ? 0.07 : 0}
						autoRotate={false}
						rotateSpeed={!this.props.disablePano ? -0.5 : 0}
					/>
					{bees && (
						<Suspense fallback={null}>
							<Particles count={100} />
						</Suspense>
					)}

					{/*effects && (
						<>
							<CanvasEffects godrays={godrays} />
						
						</>
					)*/}

					{showSpheres && (
						<Suspense fallback={null}>
							<SphereBox
								texture={sphereTextures[this.state.activePano].texture}
								textureAlpha={
									sphereTextures[this.state.activePano].textureAlpha
								}
								allowAlpha={sphereTextures[this.state.activePano].allowAlpha}
								allTextures={sphereTextures}
								activePano={this.state.activePano}
								rotation={sphereTextures[this.state.activePano].rotation}
								size={100}
								onLoaded={this.onSphereTextureloaded}
							/>
						</Suspense>
					)}

					{showClouds && (
						<Clouds
							cubeMode={false}
							allowRotate={storeforest ? false : true}
							texture={sphereTextures[this.state.activePano].clouds}
							rotation={
								sphereTextures[this.state.activePano].backgroundRotation
							}
							size={storeforest ? 100000 : 1000}
						/>
					)}

					<Suspense fallback={null}>
						<HotSpots
							onClick={this.onClickHotSpot}
							data={hotspotData[this.state.activePano]}
						/>
					</Suspense>


					{storeforest && (
						<mesh
							position={[0, 0, -80]}
							rotation={[0, MathUtils.degToRad(0), 0]}
						>
							<VideoPanel
								vimeo={
									'https://player.vimeo.com/external/516385781.hd.mp4?s=3b50df4cbf1df031eb45c2adabca55db09b5dda0&profile_id=174'
									//'/hive.mp4'
								}
								texture={imgHive}
								position={[0, 0, 0]}
							/>
						</mesh>
					)}
					{storeforest && (
						<group
							visible={storeforest ? true : false}
							position={[0, storeforest ? 0 : 0, -20]}
							rotation={[0, MathUtils.degToRad(0), 0]}
						>
							<PanelFloat
								//ref={this.panel1}
								show={storeforest}
								texture={panelTextures[0].texture}
								textureAlpha={panelTextures[0].textureAlpha}
								position={panelTextures[0].position}
								color={panelTextures[0].color}
								onClick={() => this.onClickPanel(0)}
								id={0}
							/>
							<PanelFloat
								//ref={this.panel2}
								show={storeforest}
								texture={panelTextures[1].texture}
								textureAlpha={panelTextures[1].textureAlpha}
								position={panelTextures[1].position}
								color={panelTextures[1].color}
								onClick={() => this.onClickPanel(1)}
								id={1}
							/>
							<PanelFloat
								//ref={this.panel3}
								show={storeforest}
								texture={panelTextures[2].texture}
								textureAlpha={panelTextures[2].textureAlpha}
								position={panelTextures[2].position}
								color={panelTextures[2].color}
								onClick={() => this.onClickPanel(2)}
								id={2}
							/>
							<PanelFloat
								//ref={this.panel4}
								show={storeforest}
								texture={panelTextures[3].texture}
								textureAlpha={panelTextures[3].textureAlpha}
								position={panelTextures[3].position}
								color={panelTextures[3].color}
								onClick={() => this.onClickPanel(3)}
								id={3}
							/>
						</group>
					)}
				</Canvas>
				<div
					ref={this.blackOverlayRef}
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						alpha: 1,
						//zIndex: 999,
						//display: 'none',
						//justifyContent: 'center',
						//alignItems: 'center',
						pointerEvents: 'none',
						backgroundColor: 'black',
					}}
				></div>
			</IonPage>
		);
		//}
	}
}

Pano.propTypes = {
	disablePano: PropTypes.bool,
	disableEffects: PropTypes.bool,
	navigation: PropTypes.object,
	location: PropTypes.object,
	history: PropTypes.object,
	match: PropTypes.object,
};

export default withIonLifeCycle(Pano);
