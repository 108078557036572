import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonList,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonText,
	IonRow,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/shelf.scss';
import { fit, position, fitAndPosition } from 'object-fit-math';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
import BasketNav from '../nav/BasketNav';

import { imgShelfDesktop, imgShelfMobile } from './Preloader';

const Shelf = (props) => {
	if (!props.allowRender) {
		return <></>;
	}

	//console.log('#RENDER Shelf');
	const [pageWidth, setWidth] = useState(window.innerWidth);
	const [pageHeight, setHeight] = useState(window.innerHeight);
	const [value, setValue] = useState(0);
	const [playing, setPlaying] = useState(false);
	const valueRef = useRef();
	const shelfId = props.match.params.id;
	//console.log('shelf pathname', props.location.pathname, 'shelfID', shelfID);
	let cart = CraftHelperUtil.getShelfProducts(props.craftData, shelfId);
	let isLoaded = false;
	React.useEffect(() => {
		//console.log('RENDER SHELF');
		isLoaded = true;
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	const onClick = (route) => {
		props.history.push({
			pathname: '/product/' + route,
			state: { from: props.location.pathname },
		});
	};

	const onClose = () => {
		if (props.location.state) {
			//props.history.goBack();
			props.history.push('/pano/store');
		} else {
			props.history.push('/pano/store');
		}
	};
	const onBasketClick = () => {
		props.onBasketClick();
	};

	let tabletPortraitBreak = pageWidth < 824 ? true : false;
	let mobileBreak = pageWidth < 500 ? true : false; //540

	//check for iPad pro based on ratio
	if (pageWidth < 1050 && pageWidth < pageHeight) {
		tabletPortraitBreak = true;
	}

	const parentSize = { width: pageWidth, height: pageHeight };
	const childSize = {
		width: tabletPortraitBreak ? 1300 : 2500,
		height: tabletPortraitBreak ? 4096 : 1406,
	};
	const fitted = fit(parentSize, childSize, 'cover');

	const fittedPosition = fitAndPosition(
		parentSize,
		childSize,
		tabletPortraitBreak ? 'cover' : 'cover',
		'50%',
		'50%'
	);

	const scaleY = fittedPosition.height / pageHeight;

	const shelfFlexWidthSides = tabletPortraitBreak ? 0.09 : 0.11;
	const shelfFlexHeightTop = tabletPortraitBreak ? 1.02 : 2.1;
	const shelfFlexHeightBottom = tabletPortraitBreak ? 0.55 : 1.24;
	const shelfFlexMiddle = tabletPortraitBreak ? 10 : 6.4;

	const shelfBorderHorizontal = tabletPortraitBreak
		? fittedPosition.width * 0.2
		: fittedPosition.width * 0.19;

	const shelfBorderVertical = tabletPortraitBreak
		? fittedPosition.height * 0.14
		: fittedPosition.height * 0.34;

	const shelfItemWidth = tabletPortraitBreak
		? (fittedPosition.width - shelfBorderHorizontal) / 2
		: (fittedPosition.width - shelfBorderHorizontal) / 5;
	const shelfItemHeight = tabletPortraitBreak
		? (fittedPosition.height - shelfBorderVertical) / 5
		: (fittedPosition.height - shelfBorderVertical) / 2;

	const origShelfHeight = tabletPortraitBreak ? 420 : 463;
	const shelfYScale = shelfItemHeight / origShelfHeight;

	const shelfBackground = tabletPortraitBreak
		? 'ion-no-padding ion-no-margin shelfBackgroundMobile'
		: 'ion-no-padding ion-no-margin shelfBackgroundDesktop';

	const showProducts = true;

	const shelfItems = (
		<IonRow
			key={'shelf_'}
			style={{
				display: 'flex',
				flex: 1,
				//flexWrap: 'wrap',
				flexDirecton: 'column',
				//width: '100%',
				//height: '100%',
				//backgroundColor: '#ff000080',
				//height: shelvesHeight / (cart.length + 1) + 'px',
				//flexDirection: 'row',
				alignItems: 'flex-end',
				justifyContent: 'center',
			}}
		>
			{cart.map((data, index) => {
				const item = data.product[0];
				const productScale = item.productScale / 100;
				const productOffset = tabletPortraitBreak
					? item.productYOffset * shelfYScale
					: item.productYOffset * shelfYScale;
				const yOffset = -productOffset;
				const productWidth = productScale
					? shelfItemWidth * productScale
					: shelfItemWidth;
				const productHeight = productScale
					? shelfItemHeight * productScale
					: shelfItemHeight;

				return (
					<div
						key={'item_' + index}
						onClick={() => onClick(item.slug)}
						style={{
							display: 'flex',
							width: shelfItemWidth + 'px',
							height: shelfItemHeight + 'px',
							//flexDirection: 'row',
							alignItems: 'flex-end',
							justifyContent: 'center',
							//	backgroundColor: '#61eb3480',
							//border: '1px solid red',
							//margin: 1,
						}}
					>
						<div
							style={{
								display: 'flex',
								//flexDirection: 'row',
								//marginLeft: 'auto',
								//marginRight: 'auto',
								alignItems: 'flex-end',
								justifyContent: 'center',
								position: 'relative',
								flex: 1,
								cursor: 'pointer',
								touchAction: 'pan-x pan-y',
								//* (item.productScale / 100)
								width: productWidth + 'px',
								height: productHeight + 'px',
								//backgroundColor: '#FFCC0040',
								marginBottom: yOffset,

								//border: '1px solid red',
								//verticalAlign: 'top',
								//transform: 'translateY(' + yOffset + 'px)',
								//flexBasis: splitPercent + '%',
								//margin: 1,
							}}
						>
							<img
								alt="product-image"
								id="product-image"
								height="100%"
								src={item.productImage[0].url}
							/>
						</div>
					</div>
				);
			})}
		</IonRow>
	);

	return (
		<IonPage
			//className="noScroll"
			style={{
				//marginTop: '400px',
				backgroundColor: 'white',
			}}
		>
			<IonRow
				className="navBackgroundBlack"
				style={{
					position: 'absolute',
					width: '100%',
					height: '100px',
					zIndex: 1,
				}}
			></IonRow>
			<IonRow
				className="ion-no-padding ion-no-margin"
				//className="videoContainSidebar"
				style={{
					position: 'absolute',
					width: '100%',
					//paddingRight: '16px',
					justifyContent: 'flex-end',
					//	backgroundColor: 'blue',
					zIndex: 2,
				}}
			>
				<BasketNav
					dark={false}
					showBasket={false}
					showClose={true}
					onClose={onClose}
					onBasketClick={onBasketClick}
				/>
			</IonRow>
			<IonContent className="ion-no-padding ion-no-margin prevent-elestic-scroll">
				<IonGrid
					className={shelfBackground}
					style={{
						backgroundImage: tabletPortraitBreak
							? 'url(' + imgShelfMobile + ')'
							: 'url(' + imgShelfDesktop + ')',
						width: fittedPosition.width,
						height: tabletPortraitBreak
							? fittedPosition.height
							: fittedPosition.height,
						position: tabletPortraitBreak ? 'relative' : 'fixed',
						//minWidth: '315px',
						//overflow: tabletPortraitBreak ? 'auto' : 'hidden',
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						transformOrigin: 'center center',

						transform: tabletPortraitBreak
							? null
							: 'translateX(' +
							fittedPosition.x +
							'px)' +
							'translateY(' +
							fittedPosition.y +
							'px)',
					}}
				>
					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							display: 'flex',
							width: '100%',
							//height: topPad + 'px',
							flex: shelfFlexHeightTop,
							//backgroundColor: '#ff000080',
						}}
					></IonRow>
					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							display: 'flex',
							flex: shelfFlexMiddle,

							//height: midHeight + 'px',
							//backgroundColor: '#61eb3480',
							flexDirection: 'row',
						}}
					>
						<IonCol
							className="ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								flex: shelfFlexWidthSides,
								//backgroundColor: '#61eb3480',
							}}
						></IonCol>
						<IonCol
							className="ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								flexDirection: 'column',
								//backgroundColor: '#ff000080',
							}}
						>
							{showProducts && !isLoaded && shelfItems}
						</IonCol>
						<IonCol
							className="ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								flex: shelfFlexWidthSides,
								//backgroundColor: '#61eb3480',
							}}
						></IonCol>
					</IonRow>

					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							display: 'flex',
							flex: shelfFlexHeightBottom,
							//	backgroundColor: '#ff000080',
						}}
					></IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

Shelf.propTypes = {
	allowRender: PropTypes.array,
	location: PropTypes.object,
	history: PropTypes.object,
	onClick: PropTypes.func,
	craftData: PropTypes.object,
	match: PropTypes.object,
	onBasketClick: PropTypes.func,
};

export default Shelf;
