class UrlTest {
	checkProductionDomain() {
		const isProduction = /^(http(s)?(:\/\/))?(www\.)?wellnesslab\.comvita\.co\.nz(\/.*)?$/.test(
			window.location.href
		);
		return isProduction;
	}
}

export default new UrlTest();
