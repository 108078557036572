import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
} from '@ionic/react';
import CameraControls from '../screens/CameraControls';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFrame, useLoader, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import {
	Box3,
	Vector3,
	UnsignedByteType,
	PMREMGenerator,
	FlatShading,
} from 'three';

import { envStoreMap } from './Preloader';

const pointLight = new THREE.PointLight(0xfffec6);
pointLight.position.set(50, 20, 100);
const pointLight2 = new THREE.PointLight(0xfffec6);
pointLight2.position.set(-5, 0, -100);
var directionalLight = new THREE.DirectionalLight(0xfffec6, 0.5);
directionalLight.position.set(20, 10, 100);
var ambientLight = new THREE.AmbientLight(0xfffec6, 1);
const lightHolder = new THREE.Group();
const gltfHolder = new THREE.Group();

//lightHolder.add(directionalLight);
//lightHolder.add(ambientLight);
lightHolder.add(pointLight);
//lightHolder.add(pointLight2);

GLTFItem.propTypes = {
	slug: PropTypes.string,
	url: PropTypes.string,
	scaler: PropTypes.number,
	yOffset: PropTypes.number,
	scale: PropTypes.number,
	rotation: PropTypes.number,
};

function GLTFItem({ url, scaler, yOffset, scale, rotation, slug }) {
	const loader = new GLTFLoader();

	const gltf = useLoader(GLTFLoader, url);
	const { scene, camera, gl } = useThree();
	const gtlfScene = gltf.scene;
	const gtlfRef = useRef();
	gtlfScene.rotation.set(0, 0, 0);
	if (rotation >= 0) {
		gtlfScene.rotation.set(0, THREE.Math.degToRad(rotation), 0);
	}

	let s = scaler;

	if (scale && scale != 0) {
		s = s * (scale / 100);
	}
	gtlfScene.scale.set(s, s, s);
	const helper = new THREE.BoxHelper(gtlfScene);
	helper.geometry.computeBoundingBox();
	//const size = helper.geometry.boundingBox.getSize(new Vector3()).length();
	const center = helper.geometry.boundingBox.getCenter(new Vector3());
	//gtlfScene.scale.set(s, s, s);
	//console.log('center.y', 0 - center.y);

	const yCenter = center.y;
	gtlfScene.position.set(
		gtlfScene.position.x - center.x,
		gtlfScene.position.y - center.y,
		gtlfScene.position.z - center.z
	);

	const glass1 =
		'000255_alpha_Manuka Honey Special Reserve UMF 25 logoBack goldrose2.png';
	const glass2 =
		'000255_alpha_Manuka Honey Special Reserve UMF 25 logoBack goldrose2.png';
	const glass3 = 'Manuka Honey Special Reserve UMF 25 Seal.jpg';
	const glass4 = '000255_alpha_UMF25-tex rosegold_.png';
	const glass5 = 'honey-tex-rev3.jpg';
	//console.log('slug', slug);
	if (slug == 'special-reserve-umf-25-manuka-honey') {
		gtlfScene.traverse(function (child) {
			if (child.isMesh) {
				//child.material.envMap = envMap;
				if (child.material.map) {
					//tex.minFilter = alphaTex.minFilter = THREE.LinearFilter;
					//tex.magFilter = alphaTex.magFilter = THREE.NearestFilter;
					//child.material.roughness = 0.9;
					//console.log('###child.material GLASS?', child.material.map.name);
					if (
						child.material.map.name == glass1 ||
						child.material.map.name == glass2 ||
						child.material.map.name == glass3 ||
						child.material.map.name == glass4 ||
						child.material.map.name == glass5
					) {
						//console.log('###child.material GLASS?', child.material.map.name);
						child.material.refractionRatio = 0.98;
						child.material.roughness = 0.1;
						child.material.metalness = 0.2;
					}

					//child.material.map.minFilter = THREE.LinearMipMapLinearFilter;
					//child.material.map.anisotropy = 16;

					//child.material.map.magFilter = THREE.LinearFilter;
					//child.material.map.generateMipmaps = false;
					//child.material.map.needsUpdate = true;
				}
			}
		});
	}
	//console.log('#RENDER GLTFItem', url);
	return <primitive object={gtlfScene} dispose={null} />;
}

export const environments = [
	{
		id: 'comvita_store',
		name: 'comvita_store',
		path: envStoreMap,
		format: '.hdr',
	},
];
function getCubeMapTexture(environment, gl) {
	const { path } = environment;
	const pmremGenerator = new PMREMGenerator(gl);
	pmremGenerator.compileEquirectangularShader();

	// no envmap
	if (!path) return Promise.resolve({ envMap: null });

	return new Promise((resolve, reject) => {
		new RGBELoader().setDataType(UnsignedByteType).load(
			path,
			(texture) => {
				const envMap = pmremGenerator.fromEquirectangular(texture).texture;

				pmremGenerator.dispose();

				resolve({ envMap });
			},
			undefined,
			reject
		);
	});
}

ProductItem.propTypes = {
	slug: PropTypes.string,
	heroRatio: PropTypes.number,
	scale: PropTypes.number,
	rotation: PropTypes.number,
	productData: PropTypes.object,
	mobileBreak: PropTypes.bool,
	reRender: PropTypes.number,
	yOffset: PropTypes.number,
	tabletPortraitBreak: PropTypes.bool,
};

function ProductItem({
	heroRatio,
	productData,
	mobileBreak,
	scale,
	rotation,
	yOffset,
	slug,
}) {
	//console.log('#RENDER ProductItem');
	let glb;
	if (productData.product3d[0]) {
		glb = productData.product3d[0].url;
	}
	//const [isLoaded, setIsLoaded] = useState(false);
	//console.log('glb', glb);
	const { scene, camera, gl } = useThree();

	const ref = useRef();
	let ratio = heroRatio;

	const scaler = mobileBreak ? 100 * 0.7 : ratio * 100 * 0.7;
	//gl.toneMappingExposure = 1;

	//lightHolder.add(directionalLight);
	scene.add(lightHolder);

	//scene.add(ambientLight);
	//scene.add(directionalLight);

	//envMap Stuff
	const pmremGenerator = new THREE.PMREMGenerator(gl);
	pmremGenerator.compileEquirectangularShader();
	const hdr = useLoader(RGBELoader, envStoreMap);
	const envMap = pmremGenerator.fromEquirectangular(hdr).texture;
	hdr.dispose();
	pmremGenerator.dispose();
	scene.environment = envMap;
	//scene.background = envMap;
	//
	//console.log('###SET envStoreMap');

	useFrame(() => lightHolder.quaternion.copy(camera.quaternion));
	useEffect(() => {
		//setIsLoaded(true);
		//getCubeMapTexture(environments[0], gl).then(({ envMap }) => {
		//	scene.environment = envMap;
		//	scene.background = envMap;
		//});

		return () => onUseEffectClose();
	}, []);

	const onUseEffectClose = () => {};

	//lightHolder.quaternion.copy(camera.quaternion);
	if (!glb) {
		return <group></group>;
	}

	if (!yOffset) {
		yOffset = 0;
	} else {
		yOffset = yOffset / 10;
	}

	return (
		<group>
			<CameraControls
				enableZoom={true}
				enablePan={false}
				enableDamping={true}
				dampingFactor={0.07}
				autoRotate={true}
				rotateSpeed={0.5}
				screenSpacePanning={true}
				minDistance={5}
				maxDistance={13}
				//maxAzimuthAngle={Math.PI / 4}
				//minAzimuthAngle={-Math.PI / 4}
				//maxPolarAngle={Math.PI}
				//minPolarAngle={Math.PI / 4}
			/>

			{/*
				<group>
					<ambientLight intensity={0.5} />
					<directionalLight position={[20, 10, -100]} />
				</group>
			*/}
			<Suspense fallback={null}>
				<GLTFItem
					slug={slug}
					yOffset={yOffset}
					rotation={rotation}
					scale={scale}
					url={glb}
					scaler={scaler}
				/>
			</Suspense>
		</group>
	);
}

export default ProductItem;
