class CommerceHelperUtil {
	getVariantBySku(sku, data) {
		let item;
		data.products.results.map((product, i) => {
			const variantsArray = product.masterData.current.allVariants;
			variantsArray.map((variant, j) => {
				const attributeArray = variant.attributesRaw;
				//console.log('getVariantBySku variant', sku, variant.sku);
				if (variant.sku == sku) {
					//console.log('MATCH', sku);
					item = variant;
				}
			});
		});
		return item;
	}
	getVariantAttribute(variant, attr) {
		let item;
		if (!variant) {
			return null;
		}
		variant.attributesRaw.map((attribute, k) => {
			{
				if (attribute.name == attr) {
					item = attribute;
				}
			}
		});
		return item;
	}
	getVariantTitle(sku, data) {
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		let attribute = this.getVariantAttribute(variant, 'VariantName');
		if (!attribute) {
			return null;
		}
		return attribute.value;
	}
	getMainTitle(varientId, data) {
		let title = '';
		data.products.results.map((product, i) => {
			const nameLocalesArray = product.masterData.current.nameAllLocales;
			const nameList = nameLocalesArray.map((name, n) => {
				title = name.value;
			});
		});

		return title;
	}
	normalizeTotalPrice(item) {
		if (!item) {
			return '';
		}

		const currencyCode = item.currencyCode;
		const centAmount = item.centAmount;
		//console.log('centAmount', centAmount);

		var dollars;
		if (centAmount) {
			dollars = centAmount / 100;
			dollars = dollars.toLocaleString('en', {
				style: 'currency',
				currency: 'NZD',
			});
		}
		return dollars;
	}
	normalizeLineItemDiscountPrice(variant) {
		if (!variant) {
			return '';
		}
		let dollars;
		let currencyCode;
		let centAmount;
		if (variant.price) {
			if (variant.price.discounted) {
				currencyCode = variant.price.discounted.value.currencyCode;
				centAmount = variant.price.discounted.value.centAmount;
			}
		}

		if (centAmount) {
			dollars = centAmount / 100;
			dollars = dollars.toLocaleString('en', {
				style: 'currency',
				currency: 'NZD',
			});
		}
		return dollars;
	}

	normalizeLineItemPrice(variant) {
		if (!variant) {
			return '';
		}

		const currencyCode = variant.price.value.currencyCode;
		const centAmount = variant.price.value.centAmount;
		//console.log('centAmount', centAmount);

		var dollars;
		if (centAmount) {
			dollars = centAmount / 100;
			dollars = dollars.toLocaleString('en', {
				style: 'currency',
				currency: 'NZD',
			});
		}
		return dollars;
	}


	normalizePrice(variant) {
		if (!variant) {
			return '';
		}
		let centAmount;
		let currencyCode;
		if (variant.price) {
			currencyCode = variant.price.value.currencyCode;
			centAmount = variant.price.value.centAmount;
			//console.log('centAmount', centAmount);
		}
		var dollars;
		if (centAmount) {
			dollars = centAmount / 100;
			dollars = dollars.toLocaleString('en', {
				style: 'currency',
				currency: 'NZD',
			});
		}
		return dollars;
	}
	normalizeDiscountPrice(variant) {
		if (!variant) {
			return '';
		}
		let centAmount;
		let currencyCode;
		let dollars;

		if (variant.price) {
			//console.log('variant.price.discounted', variant.price.discounted);
			if (variant.price.discounted) {
				currencyCode = variant.price.discounted.value.currencyCode;
				centAmount = variant.price.discounted.value.centAmount;
			}
		}

		if (centAmount) {
			dollars = centAmount / 100;
			dollars = dollars.toLocaleString('en', {
				style: 'currency',
				currency: 'NZD',
			});
		}
		return dollars;
	}

	getPrice(sku, data) {
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		const price = this.normalizePrice(variant);
		return price;
	}
	getDiscountedPrice(sku, data) {
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		const price = this.normalizeDiscountPrice(variant);
		return price;
	}

	getSize(sku, data) {
		let size;
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		const attributeSize = this.getVariantAttribute(variant, 'Size');
		const attributeHoneySize = this.getVariantAttribute(variant, 'HoneySize');
		const attributeCount = this.getVariantAttribute(variant, 'Count');
		if (attributeSize) {
			size = attributeSize.value.label;
		}
		if (attributeHoneySize) {
			size = attributeHoneySize.value.label;
		}
		if (attributeCount) {
			size = attributeCount.value;
		}
		return size;
	}
	getVariantId(sku, data) {
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		return variant.id;
	}

	getProductId(id, data) {
		let productId = null;

		if (!data.products) {
			return null;
		}
		const hasVariant = this.getVariantBySku(id, data);
		if (!hasVariant) {
			return null;
		}
		data.products.results.map((product, i) => {
			productId = product.id;
		});
		return productId;
	}
	getVariants(id, data) {
		const varientsArray =
			data.products.results[0].masterData.current.allVariants;
		return varientsArray;
	}

	getAvailableQuanity(sku, channelKey, data) {
		let available = 0;
		const variant = this.getVariantBySku(sku, data);
		if (!variant) {
			return null;
		}
		const channels = variant.availability.channels.results;
		channels.map((item, i) => {
			if (item.channel.key == channelKey) {
				//console.log('sku:', sku, 'channelKey:', channelKey, 'item:', item);
				if (item.availability.isOnStock) {
					//console.log('getAvailableQuanity channel', item.availability);
					available = item.availability.availableQuantity;
				}
			}
		});

		return available;
	}

	getAvailableQuanityFromCartLineItem(channelKey, variant) {
		let available = 0;
		if (!variant) {
			return null;
		}
		const channels = variant.availability.channels.results;
		channels.map((item, i) => {
			if (item.channel.key == channelKey) {
				//console.log('sku:', sku, 'channelKey:', channelKey, 'item:', item);
				if (item.availability.isOnStock) {
					//console.log('getAvailableQuanity channel', item.availability);
					available = item.availability.availableQuantity;
				}
			}
		});

		return available;
	}
}

export default new CommerceHelperUtil();
