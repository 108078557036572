import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

const DATA = gql`
	query getSiteData {
		entries(section: "stores", limit: 1) {
			... on stores_stores_Entry {
				title
				slug
				#ctDevClientid
				#ctDevClientsecret
				#ctProdClientid
				#ctProdClientsecret
				ctDevClientidEnc
				ctDevClientsecretEnc
				ctProdClientidEnc
				ctProdClientsecretEnc
				introVideoUrl
				homeTitle
				homeBody
				homeImage {
					url: url
					width: width
					height: height
				}
				bookTitle
				bookBody
				bookImage {
					url: url(transform: "hero")
					width: width(transform: "hero")
					height: height(transform: "hero")
				}
				scienceTitle
				scienceBody
				scienceImage {
					url: url(transform: "hero")
					width: width(transform: "hero")
					height: height(transform: "hero")
				}
				panos {
					... on panos_panos_Entry {
						title
						id
						panoImage {
							width
							height
							url
						}
						panoAlpha {
							width
							height
							url
						}
						panoHotspots {
							... on panoHotspots_BlockType {
								hotspot {
									x
									y
								}
								panoLink {
									typeHandle
									id
									slug
									... on facts_facts_Entry {
										title
										slug
										factIntro
										factBody
										factVideoUrl
										factHeroVideoUrl
										productVideoHeight
										factHeroImage {
											url: url(transform: "large")
											width: width(transform: "large")
											height: height(transform: "large")
										}
										factImages {
											url: url(transform: "medium")
											width: width(transform: "medium")
											height: height(transform: "medium")
										}
									}
								}
							}
						}
					}
				}
				productGroups {
					... on productGroups_groups_Entry {
						id
						slug
						title
						categoryBody
						categoryIcon {
							width
							height
							url
						}
						productsList {
							... on productsList_BlockType {
								product {
									... on products_products_Entry {
										id
										title
										slug
										commercetoolsSku {
											... on commercetoolsSku_BlockType {
												productSKU
												productDefault
											}
										}
										productImage {
											url: url(transform: "product")
											width: width(transform: "product")
											height: height(transform: "product")
										}
										product3d {
											id
											url
										}
										productVideoUrl
										productVideoHeight
										productScale
										productYOffset
										product3DScale
										product3dRotation
										product3DYOffset
										descriptionTitle
										descriptionBody
										directionsTitle
										directionsBody
										flavourTitle
										flavourBody
										warningsTitle
										warningsBody
										infoImages {
											url: url(transform: "medium")
											width: width(transform: "medium")
											height: height(transform: "medium")
										}
									}
								}
							}
						}
					}
				}
				videoGridItems {
					... on videoGrid_videoGrid_Entry {
						title
						slug
						videoSubtitle
						videoGridUrl
					}
				}
			}
		}
	}
`;
CraftData.propTypes = {
	props: PropTypes.object,
	loaded: PropTypes.func,
};
export default function CraftData(props) {
	//console.log('#####CraftContent useQuery');
	const { loading, error, data } = useQuery(DATA);

	if (loading) {
		//console.log('#####Loading CraftCMS', loading);
		return <div></div>;
	}

	if (error) {
		{
			//console.log('#####error CraftcMS', error);
			return <div></div>;
		}
	}
	props.loaded(data);

	return <div>Loaded</div>;
}
