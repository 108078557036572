import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth';

export function shouldEncode(url, options) {
	if (process.env.NODE_ENV === 'development') return false;
	if (!options.method || options.method.toLowerCase() !== 'post') return false;
	url = url.split('?')[0].split('#')[0];
	if (!url.endsWith('/graphql')) return false;
	return true;
}

const myFetchImplementation = (url, options) => {
	options = options || {};
	const isEncoding = shouldEncode(url, options);
};

//https://itnext.io/graphql-data-hiding-using-apollo-stack-ad1ea92fa85c
const httpLink = createHttpLink({
	uri:
		'https://wellnesslabcms.comvita.co.nz/index.php?p=admin/actions/graphql/api/',
	//fetch: myFetchImplementation, // overwriting the fetch function
});

const token = process.env.REACT_APP_CRAFTCMS_TOKEN;

//const authLink = setContext((_, { headers = {} }) => {
const authLink = setContext(async (req, { headers }) => {
	return {
		...headers,
		headers: {
			//'Accept-Encoding': 'gzip',
			//Accept: 'application/json',
			//'Content-Type': 'application/json',
			authorization: token ? `Bearer ${token}` : null,
		},
	};
});

const cache = new InMemoryCache({});

export default new ApolloClient({
	link: authLink.concat(httpLink),
	cache: cache,
});
