import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
function VideoBtn(props) {
	const [mouseOver, setMouseOver] = useState(false);
	let btnClass = 'pageBtn ';
	let btnOverClass = 'pageBtn pageBtnOver';
	if (props.btnClass) {
		btnClass = 'pageBtn ' + props.btnClass;
	}
	if (props.btnOverClass) {
		btnOverClass = 'pageBtn pageBtnOver ' + props.btnOverClass;
	}
	useEffect(() => {
		if (props.tabletPortraitBreak && !props.playing) {
			setMouseOver(true);
		} else {
			setMouseOver(false);
		}
	}, [props.tabletPortraitBreak]);

	return (
		<IonRow
			className="ion-no-padding ion-no-margin navItem"
			style={{
				cursor: 'pointer',
				width: '100%',
				height: '100%',
				marginTop: '0px',
				//zIndex: 50,
				//marginLeft: '-20px',
				//alignItems: 'flex-start',
				//paddingBottom: '20px',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#00000080',
				//backgroundColor: 'red',
				opacity: mouseOver ? 1 : 0,
			}}
			textAlign="left"
			size="large"
			slots="start"
			onMouseEnter={() => setMouseOver(true)}
			onMouseDown={() => setMouseOver(true)}
			onMouseUp={() => setMouseOver(false)}
			onMouseLeave={() => setMouseOver(false)}
			onClick={props.onClick}
			color="transparent"
		>
			{/*<img className="navIcon" alt="nav_icon" height="40px" src={props.icon} />*/}

			<IonText className={mouseOver ? btnOverClass : btnClass}>
				{props.label}
			</IonText>
		</IonRow>
	);
}

VideoBtn.propTypes = {
	props: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	id: PropTypes.number,
	color: PropTypes.string,
	icon: PropTypes.string,
	btnClass: PropTypes.string,
	btnOverClass: PropTypes.string,
	tabletPortraitBreak: PropTypes.bool,
	playing: PropTypes.bool,
};

export default VideoBtn;
