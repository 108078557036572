import React, { useEffect, useState } from 'react';
import { IonCol, IonRow, IonText } from '@ionic/react';
import PropTypes, { bool } from 'prop-types';
import QuantityBtn from './QuantityBtn';

QuantityGroup.propTypes = {
	availableQuantity: PropTypes.number,
	startCount: PropTypes.number,
	props: PropTypes.object,
	type: PropTypes.string,
	num: PropTypes.number,
	onClick: PropTypes.func,
	scale: PropTypes.number,
	lineItemId: PropTypes.string,
	allowRemove: PropTypes.bool,
	allowAddItem: PropTypes.bool,
	sku: PropTypes.string,
	title: PropTypes.string,
};

export default function QuantityGroup({
	type,
	num,
	onClick,
	availableQuantity,
	startCount,
	scale,
	lineItemId,
	allowRemove,
	allowAddItem,
	sku,
	title,
}) {
	const [selected, setSelected] = useState(0);
	const [count, setCount] = useState(1);
	useEffect(() => {
		if (startCount) {
			setCount(startCount);
		} else {
			setCount(1);
		}
	}, [availableQuantity]);
	useEffect(() => {
		setCount(startCount);
	}, [startCount]);

	let currentStockCount = availableQuantity;
	if (currentStockCount > 999) {
		currentStockCount = 999;
	}
	//onClick(1);
	const onAdd = () => {
		//console.log('onAdd count', count, currentStockCount);
		if (allowAddItem) {
			if (count + 1 <= currentStockCount) {
				setCount(count + 1);
				onClick(count + 1, lineItemId, 'add', sku, title);
			} else {
				setCount(currentStockCount);
				onClick(currentStockCount, lineItemId, 'add', sku, title);
			}
		}
	};
	const onRemove = () => {
		if (allowAddItem) {
			if (count > 1) {
				if (count - 1 <= currentStockCount) {
					setCount(count - 1);
					onClick(count - 1, lineItemId, 'remove', sku, title);
				} else {
					setCount(currentStockCount);
					onClick(currentStockCount, lineItemId, 'remove', sku, title);
				}
			} else {
				//count is zero - remove the item if allowed
				if (allowRemove) {
					onClick(0, lineItemId, 'remove', sku, title);
				}
			}
		}
	};

	return (
		<IonRow
			style={{
				display: 'flex',
				flex: 1,
				width: '140px',
				marginRight: '16px',
				//marginBottom: '16px',
				//backgroundColor: 'red',
				//height: '55px',
			}}
		>
			{!scale && (
				<IonRow
					style={{
						width: '160px',
						height: '30px',

						//backgroundColor: 'blue',
						//paddingBottom: '16px',
					}}
				>
					<IonText className="productSizeTitle">Quantity</IonText>
				</IonRow>
			)}
			<IonRow
				style={{
					transform: scale ? 'scale(' + scale + ', ' + scale + ')' : '',
				}}
			>
				<QuantityBtn onClick={onRemove} type="remove" />
				<IonCol
					style={{
						display: 'flex',
						flex: 1,

						//height: '67px',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<IonText
						className="productCount"
						style={{
							width: '50px',
							//backgroundColor: 'red',
						}}
					>
						{count}
					</IonText>
				</IonCol>
				<QuantityBtn onClick={onAdd} type="add" />
			</IonRow>
		</IonRow>
	);
}
