import React, {
	Suspense,
	useLayoutEffect,
	useState,
	useRef,
	useEffect,
} from 'react';
import {
	Canvas,
	useLoader,
	useThree,
	useFrame,
	useResource,
} from 'react-three-fiber';
import * as THREE from 'three';
import {
	CubeTextureLoader,
	TextureLoader,
	WebGLCubeRenderTarget,
	RGBEEncoding,
	NearestFilter,
	MathUtils,
} from 'three';
import PropTypes, { bool } from 'prop-types';
import CubeBox from '../pano/CubeBox';

Spherical.propTypes = {
	texture: PropTypes.string,
	allowRotate: PropTypes.bool,
	size: PropTypes.number,
	rotation: PropTypes.number,
};

function Spherical({ texture, size, allowRotate, rotation }) {
	const ref = useRef();
	useEffect(() => {
		if (ref.current) {
			ref.current.setMatrixAt(0, new THREE.Matrix4());
			ref.current.scale.x = -1;
		}
	}, []);
	useFrame(() => {
		if (ref.current && allowRotate) {
			ref.current.rotation.y -= 0.0004;
		}
	});
	const tex = useLoader(THREE.TextureLoader, texture);
	tex.encoding = RGBEEncoding;
	//tex.mapping = THREE.EquirectangularReflectionMapping;
	tex.minFilter = NearestFilter;
	tex.magFilter = NearestFilter;
	tex.flipY = true;
	//console.log('tex', tex);
	return (
		<instancedMesh
			ref={ref}
			args={[null, null, 1]}
			rotation={[0, MathUtils.degToRad(rotation), 0]}
		>
			<sphereBufferGeometry attach="geometry" args={[size, 60, 40]} />
			<meshBasicMaterial
				attach="material"
				map={tex}
				side={THREE.BackSide}
				depthWrite={false}
				//depthTest={false}
				//transparent={true}
			/>
		</instancedMesh>
	);
}

Clouds.propTypes = {
	props: PropTypes.object,
	texture: PropTypes.string,
	allowRotate: PropTypes.bool,
	size: PropTypes.number,
	rotation: PropTypes.number,
};

export default function Clouds(props) {
	return (
		<Suspense fallback={null}>
			<Spherical
				texture={props.texture}
				allowRotate={props.allowRotate}
				rotation={props.rotation}
				size={props.size}
			/>
		</Suspense>
	);
}
