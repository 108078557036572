import ReactGA from 'react-ga';
import UrlTest from './UrlTest';

//Videos
const CATEGORY_VIDEO_INTRO = 'Video Intro';
const CATEGORY_VIDEO_PRODUCT = 'Video Product';
const CATEGORY_VIDEO_SCIENCE = 'Video Science';
const CATEGORY_VIDEO_FACTS = 'Video Facts';
const ACTION_VIDEO_VIEWED = 'viewed';
const ACTION_VIDEO_COMPLETE = 'complete';
const ACTION_VIDEO_SKIPPED = 'skipped';
const LABEL_VIDEO_INTRO = 'intro';
const LABEL_VIDEO_PRODUCT = 'product';
const LABEL_VIDEO_SCIENCE = 'science';
const LABEL_VIDEO_FACTS = 'facts';
export {
	CATEGORY_VIDEO_INTRO,
	CATEGORY_VIDEO_PRODUCT,
	CATEGORY_VIDEO_SCIENCE,
	CATEGORY_VIDEO_FACTS,
	LABEL_VIDEO_INTRO,
	LABEL_VIDEO_PRODUCT,
	LABEL_VIDEO_SCIENCE,
	LABEL_VIDEO_FACTS,
};

//Images
const CATEGORY_CAROUSEL_IMAGES = 'Carousel Images';
const ACTION_SWIPE_IMAGE = 'swipe image';
const LABEL_IMAGES_PRODUCTS = 'products';
const LABEL_IMAGES_FACTS = 'facts';

//Store
const CATEGORY_SHELF_SECTION = 'Product Category';
const ACTION_PANEL_CLICKED = 'panel clicked';

//Product
const CATEGORY_PRODUCT = 'Product';
const ACTION_ROTATE_PRODUCT = 'rotate product';
const ACTION_ZOOM_PRODUCT = 'zoom product';

//Cart

const CATEGORY_PRODUCT_CART = 'Cart';
const ACTION_CHECKOUT = 'checkout';
const ACTION_CHANGE_SIZE = 'change size';
const ACTION_ADD_PRODUCT = 'add product';
const ACTION_REMOVE_PRODUCT = 'remove product';
const ACTION_INCREASE_QUANTITY = 'increase quantity';
const ACTION_DECREASE_QUANTITY = 'decrease quantity';
const ACTION_OPEN_CART = 'open cart';
const ACTION_CLOSE_CART = 'close cart';
const ACTION_AUTO_OPEN_CART = 'auto open cart';

//Book
const CATEGORY_BOOK = 'Book';
const ACTION_BOOK = 'book clicked';

//Load
const CATEGORY_LOAD = 'Load';
const ACTION_LOAD_JS = 'JS Libraries';
const ACTION_LOAD_IMAGES = 'Images';
const ACTION_LOAD_3D = '3d Models';

let allowTracking = true;
const isProductionDomain = UrlTest.checkProductionDomain();
let DEV = '';
if (!isProductionDomain) {
	//DEV = 'Dev ';
	allowTracking = false;
}

class GAEvent {
	//Cart Events
	checkoutCart(cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(DEV + CATEGORY_PRODUCT_CART + id, ACTION_CHECKOUT, id);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_CHECKOUT,
				label: cartId,
			});
		}
	}
	addToCart(sku, title, value, cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(
			DEV + CATEGORY_PRODUCT_CART + id,
			ACTION_ADD_PRODUCT,
			sku + ' - ' + title,
			value,
			id
		);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_ADD_PRODUCT,
				label: sku + ' - ' + title,
				value: value,
			});
		}
	}
	removeFromCart(sku, title, cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(
			DEV + CATEGORY_PRODUCT_CART + id,
			ACTION_REMOVE_PRODUCT,
			sku + ' - ' + title,
			id
		);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_REMOVE_PRODUCT,
				label: sku + ' - ' + title,
			});
		}
	}
	increaseQuantity(sku, title, cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(
			DEV + CATEGORY_PRODUCT_CART + id,
			ACTION_INCREASE_QUANTITY,
			sku + ' - ' + title,
			1,
			id
		);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_INCREASE_QUANTITY,
				label: sku + ' - ' + title,
				value: 1,
			});
		}
	}
	decreaseQuantity(sku, title, cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(
			DEV + CATEGORY_PRODUCT_CART + id,
			ACTION_DECREASE_QUANTITY,
			sku + ' - ' + title,
			-1,
			id
		);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_DECREASE_QUANTITY,
				label: sku + ' - ' + title,
				value: -1,
			});
		}
	}

	chooseSize(sku, title, value, cartId) {
		const id = cartId ? ' ' + cartId : '';
		console.log(
			DEV + CATEGORY_PRODUCT_CART + id,
			ACTION_CHANGE_SIZE,
			sku + ' - ' + title + ' - ' + value,
			id
		);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_PRODUCT_CART + id,
				action: ACTION_CHANGE_SIZE,
				label: sku + ' - ' + title + ' - ' + value,
			});
		}
	}

	//Video Events
	videoViewed(category, label) {
		console.log(DEV + category, ACTION_VIDEO_VIEWED, label);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + category,
				action: ACTION_VIDEO_VIEWED,
				label: label,
			});
		}
	}

	videoCompleted(category, label) {
		console.log(DEV + category, ACTION_VIDEO_COMPLETE, label);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + category,
				action: ACTION_VIDEO_COMPLETE,
				label: label,
			});
		}
	}
	videoSkipped(category, label) {
		console.log(DEV + category, ACTION_VIDEO_SKIPPED, label);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + category,
				action: ACTION_VIDEO_SKIPPED,
				label: label,
			});
		}
	}

	//Book
	bookClicked() {
		console.log(DEV + CATEGORY_BOOK, DEV + ACTION_BOOK);
		if (allowTracking) {
			ReactGA.event({
				category: DEV + CATEGORY_BOOK,
				action: ACTION_BOOK,
			});
		}
	}

	//generic events - DONT USE
	trackEvent(category, action, value) {
		console.log('trackEvent', category, action, value);
		if (allowTracking) {
			if (value) {
				ReactGA.event({
					category: category,
					action: action,
					value: value,
				});
			} else {
				ReactGA.event({
					category: category,
					action: action,
				});
			}
		}
	}

	trackTiming(category, action, value, label) {
		console.log('trackTiming', category, action, value, label);
		if (allowTracking) {
			ReactGA.event({
				category: category,
				variable: action,
				value: value,
				label: label,
			});
		}
	}
}

export default new GAEvent();
