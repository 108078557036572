import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
	IonText,
	withIonLifeCycle,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import React, { createRef, Suspense, useEffect, useRef, useState } from 'react';
import {
	Canvas,
	useFrame,
	extend,
	useThree,
	useLoader,
} from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { fit, position, fitAndPosition } from 'object-fit-math';
import PropTypes, { bool } from 'prop-types';
import ReactPlayer from 'react-player';
import ProductItem from '../screens/ProductItem';

import productStyles from '../styles/product.scss';
import factsStyles from '../styles/facts.scss';
import navStyles from '../styles/nav.scss';
import videoStyles from '../styles/video.scss';
import PageBtn from '../nav/PageBtn';
//import CommerceProducts from '../commercetools/CommerceProducts';
//import CommerceCart from '../commercetools/CommerceCart';
import CloseBtn from '../nav/CloseBtn';
import BasketBtn from '../nav/BasketBtn';
//import CommerceAddToCart from '../commercetools/CommerceAddToCart';
import { gql, useQuery } from '@apollo/client';
import VideoBtn from '../nav/VideoBtn';
import BasketNav from '../nav/BasketNav';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
//import CommerceHelperUtil from '../commercetools/CommerceHelperUtil';
import SizeGroup from '../nav/SizeGroup';
import QuantityGroup from '../nav/QuantityGroup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SizeQuantity from './ProductControl';
import ProductControl from './ProductControl';
//import CommerceState from '../commercetools/CommerceState';
import { getGlobalCartCount, setGlobalCartCount } from '../services/CartCount';
import {
	getGlobalCommerceData,
	setGlobalCommerceData,
} from '../services/CommerceData';

import imgHeroPlatform from '../assets/products/goldcircle.png';
import { imgProductBack } from './Preloader';
import GAEvent, {
	CATEGORY_VIDEO_PRODUCT,
	LABEL_VIDEO_PRODUCT,
} from '../services/GAEvent';

const PRODUCTS = gql`
	query ProductBySku($skus: [String!]) {
		products(limit: 100, skus: $skus) {
			total
			results {
				__typename
				key
				id
				skus
				masterData {
					published
					current {
						nameAllLocales {
							locale
							value
						}
						descriptionAllLocales {
							locale
							value
						}
						slugAllLocales {
							locale
							value
						}
						categories {
							__typename
							id
						}
						allVariants {
							__typename
							id
							sku
							attributesRaw {
								name
								value
							}
							availability {
								channels {
									total
									results {
										channel {
											__typename
											id
											key
											roles
										}
										availability {
											isOnStock
											restockableInDays
											availableQuantity
										}
									}
								}
							}
							prices {
								value {
									centAmount
									currencyCode
								}
							}
							price(currency: "NZD") {
								value {
									centAmount
									currencyCode
								}
								discounted {
									value {
										centAmount
										currencyCode
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

function Product(props) {
	if (!props.allowRender) {
		return <></>;
	}
	//console.log('#RENDER Product');
	const [pageWidth, setWidth] = useState(window.innerWidth);
	const [pageHeight, setHeight] = useState(window.innerHeight);
	const globalCommerceData = getGlobalCommerceData();
	const [playing, setPlaying] = useState(false);
	const [muted, setMuted] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [showMobileSideBar, setShowMobileSideBar] = useState(false);

	const valueRef = useRef();
	let playerRef = useRef();
	const slug = props.match.params.id;
	//setIsLoaded(false);
	let productData = CraftHelperUtil.getProduct(props.craftData, slug);
	let waitVideo;

	React.useEffect(() => {
		//setIsLoaded(true);
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener('resize', handleWindowResize);
		//waitVideo = setTimeout(delayVideoPlayback, 2000);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	//React.useEffect(() => {
	//setShowMobileSideBar(false);
	//}, [props.craftData]);

	useIonViewWillEnter(() => {
		setShowMobileSideBar(false);
		setIsLoaded(true);
		//delay the video playback here
		if (waitVideo) {
			clearTimeout(waitVideo);
		}
		waitVideo = setTimeout(delayVideoPlayback, 2000);
		//console.log('RENDER PRODUCT useIonViewWillEnter setIsLoaded()', isLoaded);
	});

	const delayVideoPlayback = () => {
		setPlaying(true);
	};

	useIonViewWillLeave(() => {
		//console.log('ionViewWillLeave event fired');
		//setIsLoaded(false);
	});

	useIonViewDidEnter(() => {
		setPlaying(false);
		setMuted(true);
	});

	useIonViewDidLeave(() => {
		if (waitVideo) {
			clearTimeout(waitVideo);
		}
		setPlaying(false);
	});

	const craftCMSSkus = [];
	productData.commercetoolsSku.map((item, i) => {
		craftCMSSkus.push(item.productSKU);
	});

	//Remove commercetools
	/*
	const { data, loading, error } = useQuery(PRODUCTS, {
		variables: { skus: craftCMSSkus },
	});
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	const commerceData = data;
	*/
	//const variantTitle = CommerceHelperUtil.getVariantTitle(activeSku, data);

	const onClose = () => {
		setIsLoaded(false);
		//console.log('onClose');
		//setShowMobileSideBar(false);

		if (props.location.state) {
			props.history.goBack();
			//props.history.push('/shelf/0');
		} else {
			props.history.push('/shelf/0');
		}
	};
	const onBasketClick = () => {
		props.onBasketClick();
	};

	const onAddtoCart = () => {
		props.onBasketClick();
		//props.onClick();
	};

	const onProgress = ({ playedSeconds }) => {
		//console.log(valueRef.current);
	};

	const onPlayVideo = () => {
		console.log('onPlayVideo');
		GAEvent.videoViewed(CATEGORY_VIDEO_PRODUCT, slug);
	};

	const playVideo = (url) => {
		//console.log('playVideo');
		if (playerRef) {
			playerRef.seekTo(0);
		}
		setMuted(false);

		setPlaying(true);
	};

	const onExplore = () => {
		setShowMobileSideBar(true);
	};

	const onEnded = () => {
		GAEvent.videoCompleted(CATEGORY_VIDEO_PRODUCT, slug);
	};

	//skus

	/*const variantTitle = CommerceHelperUtil.getVariantTitle(
		productData.commercetoolsSku[0].productSKU,
		commerceData
	);*/

	//102518,102519
	let navProductBreak = pageWidth < 1300 ? true : false;
	let tabletPortraitBreak = pageWidth < 1030 ? true : false;
	let mobileBreak = pageWidth < 400 ? true : false; //540

	//React.useEffect(() => {
	//if (tabletPortraitBreak) {
	//setShowMobileSideBar(false);
	//}
	//}, [props.craftData]);

	const platformHeight = 200;
	//let sidebarEval = pageWidth * 0.3;
	let sidebarEval = 535;
	if (pageWidth < 1172) {
		sidebarEval = 400;
	}
	const heroImageSpaceWidth = tabletPortraitBreak
		? pageWidth
		: pageWidth - sidebarEval - 10;
	const heroImageSpaceHeight = pageHeight;

	const parentSize = {
		width: tabletPortraitBreak ? pageWidth : sidebarEval,
		height: tabletPortraitBreak ? pageWidth * 0.55 : 236,
	};
	const productVideoHeight = productData.productVideoHeight
		? productData.productVideoHeight
		: 1080;
	const childSize = { width: 1920, height: productVideoHeight };
	const fitted = fit(parentSize, childSize, 'cover');
	const fittedPosition = fitAndPosition(
		parentSize,
		childSize,
		'cover',
		'50%',
		'50%'
	);

	const platformParentSize = {
		width: heroImageSpaceWidth,
		height: heroImageSpaceHeight * 0.14,
	};
	const platformSize = { width: 1523, height: 234 };
	const platformFitted = fit(platformParentSize, platformSize, 'contain');
	const platformFittedPosition = fitAndPosition(
		platformParentSize,
		platformSize,
		'contain',
		'50%',
		'50%'
	);

	let heroRatio = heroImageSpaceWidth / pageWidth;
	const productId = props.match.params.id;

	const isSurgeDomain = /^(http(s)?(:\/\/))?(www\.)?comvitabasket\.surge\.sh(\/.*)?$/.test(
		window.location.href
	);
	const testProducts = isSurgeDomain ? true : false;
	//console.log('commerceData', commerceData);
	//console.log('product3dRotation', productData.product3dRotation);
	//console.log('productData.product3DYOffset', productData.product3DYOffset);

	//console.log('###Render Product isLoaded', isLoaded);
	return (
		<IonPage
			className={
				tabletPortraitBreak
					? showMobileSideBar
						? 'ion-no-padding ion-no-margin'
						: 'ion-no-padding ion-no-margin factsBackgroundDesktop'
					: 'ion-no-padding ion-no-margin factsBackgroundDesktop'
			}
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: 'white',
				backgroundImage: 'url(' + imgProductBack + ')',
				//justifyContent: 'center',
				//alignItems: 'center',
				//pointerEvents: 'none',
				//backgroundColor: 'blue',
			}}
		>
			<IonRow
				style={{
					width: '100%',
					height: '100%',
					//flexDirection: 'column',
					//backgroundColor: 'red',
					//justifyContent: 'center',
					//alignItems: 'center',
					//pointerEvents: 'none',
					//backgroundColor: 'blue',
				}}
			>
				<IonGrid
					className="ion-no-padding ion-no-margin"
					style={{
						display: tabletPortraitBreak
							? showMobileSideBar
								? 'none'
								: 'block'
							: 'block',
						//display: 'none',
						//backgroundColor: 'pink',
						//position: 'absolute',
						flexDirection: 'column',
						//justifyContent: 'flex-start',
						//alignItems: 'center',
						width: heroImageSpaceWidth,
						height: '100%',
						//zIndex: 2,
					}}
				>
					{tabletPortraitBreak && (
						<IonRow
							className="ion-no-padding ion-no-margin"
							//className="videoContainSidebar"
							style={{
								position: 'absolute',
								width: fittedPosition.width,
								//paddingRight: '16px',
								justifyContent: 'flex-end',
								zIndex: 99,
							}}
						>
							<BasketNav
								dark={true}
								showBasket={navProductBreak ? true : false}
								showClose={true}
								onClose={onClose}
								onBasketClick={onBasketClick}
							/>
						</IonRow>
					)}
					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							//display: 'flex',
							//flex: 1,
							//marginTop: '50px',
							//	flexDirection: 'column',
							//backgroundColor: 'red',
							//justifyContent: 'center',
							//alignItems: 'center',
							//backgroundColor: 'yellow',
							height: heroImageSpaceHeight,
							width: heroImageSpaceWidth,
							justifyContent: 'center',
							alignItems: 'center',
							//transform: ' scale3d(1, 1, 1)',
						}}
					>
						<IonRow
							className="ion-no-padding ion-no-margin"
							style={{
								//display: 'flex',
								//flex: 1,
								height: tabletPortraitBreak
									? heroImageSpaceHeight + productData.product3DYOffset
									: heroImageSpaceHeight + productData.product3DYOffset,
								width: heroImageSpaceWidth,
								marginTop: tabletPortraitBreak
									? productData.product3DYOffset
									: +productData.product3DYOffset,

								//backgroundColor: 'pink',
								zIndex: 2,
							}}
						>
							<Canvas
								concurrent
								gl={{ antialias: true, alpha: true }}
								camera={{ position: [0, 0, -12], fov: 50 }}
								//onCreated={({ gl }) => gl.setClearColor('white')}
								//renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1)
								//renderer.physicallyCorrectLights(true)
								//physicallyCorrectLights={true}
								pixelRatio={
									window.devicePixelRatio ? window.devicePixelRatio : 1
								}
							//pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
							//camera={{ fov: 100, position: [0, 0, 30] }}
							//<spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
							>
								{/*<ambientLight intensity={0.5} />*/}
								{/*<directionalLight position={[20, 10, -100]} />*/}

								{heroRatio > 0 && isLoaded && (
									<Suspense fallback={null}>
										<ProductItem
											yOffset={productData.product3DYOffset}
											rotation={productData.product3dRotation}
											scale={
												tabletPortraitBreak
													? productData.product3DScale * 0.5
													: productData.product3DScale
											}
											mobileBreak={mobileBreak}
											heroRatio={heroRatio}
											productData={productData}
											slug={slug}
										/>
									</Suspense>
								)}
							</Canvas>
						</IonRow>
						<IonRow
							style={{
								display: 'flex',
								flex: 1,
								position: 'absolute',
								//flexDirection: 'row',
								//marginTop:
								//heroImageSpaceWidth > heroImageSpaceHeight
								//? -platformFitted.height * 1.6
								//: -pageHeight * 0.5 + platformFitted.height * 1.6,
								//marginBottom: '200px',
								//height: heroImageSpaceHeight * 0.2,
								//width: "heroImageSpaceWidth * (heroImageSpaceHeight * 0.15)",
								width: '100%',
								height: '100%',

								//paddingBottom: '32px',
								alignItems: 'flex-end',

								//backgroundColor: 'blue',
								justifyContent: 'center',
							}}
						>
							<div>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										//justifyContent: 'center',
										alignItems: 'center',
										zIndex: 1,
										//backgroundColor: 'yellow',
										marginBottom: tabletPortraitBreak ? '16px' : '100px',
										//backgroundColor: 'red',
									}}
								>
									<img
										alt="hero-platform"
										id="hero-platform"
										src={imgHeroPlatform}
										width={platformFitted.width}
										height={platformFitted.height}
									//width="auto"
									//height="auto"
									//width={platformBreak ? '80%' : '640px'}
									/>
								</div>

								{tabletPortraitBreak && (
									<div
										style={{
											display: 'flex',
											flex: 1,
											flexDirection: 'column',
											pointerEvents: 'none',
											width: heroImageSpaceWidth,
											alignItems: 'center',
											justifyContent: 'center',
											//backgroundColor: 'red',

											marginBottom: '10px',
										}}
									>
										<div
											style={{
												display: 'flex',
												//flex: 1,
												pointerEvents: 'none',
												height: '40px',
												//backgroundColor: 'green',
												alignItems: 'center',
												justifyContent: 'center',
												//paddingBottom: '15px',
											}}
										>
											<div
												className="productHeroTitle"
												style={{
													//display: 'flex',
													//flex: 1,
													pointerEvents: 'none',
													width: heroImageSpaceWidth,
													alignItems: 'center',
													justifyContent: 'center',
													//paddingBottom: '15px',
												}}
											>
												{productData.title}
											</div>
										</div>

										<div
											style={{
												zIndex: 2,
												//marginBottom: '16px',
												pointerEvents: 'all',
												marginBottom: 16,
												//backgroundColor: 'red',
											}}
										>
											<PageBtn
												marginTop={8}
												id={0}
												label="EXPLORE"
												onClick={() => onExplore()}
											></PageBtn>
										</div>
									</div>
								)}
							</div>
						</IonRow>
					</IonRow>
				</IonGrid>
				{/*testProducts && (
					<IonContent
						className="ion-no-padding ion-no-margin "
						style={{
							//display: 'none',
							display: 'flex',
							position: 'fixed',
							zIndex: 10,
							backgroundColor: 'white',
							width: '300px',
						}}
					>
						<CommerceProducts onClick={onAddtoCart()} />
					</IonContent>
					)*/}
				{/*Content Bar*/}

				<IonContent
					className="ion-no-padding ion-no-margin"
					style={{
						display: tabletPortraitBreak
							? showMobileSideBar
								? 'block'
								: 'none'
							: 'block',
						//display: 'block',
						//flex: 1,
						//float: 'right',
						zIndex: 10,
						position: tabletPortraitBreak ? 'absolute' : 'relative',
						//backgroundColor: 'red',
						//pointerEvents: 'auto',
						//flexDirection: 'column',
						//zIndex: 1,
					}}
				>
					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							//display: 'flex',
							//flex: 1,
							justifyContent: 'flex-end',
							height: '100vh',
							//flexBasis: tabletPortraitBreak ? '100%' : '30%',
							//flexGrow: 0,
							//flexShrink: 0,
							//width: tabletPortraitBreak ? '100%' : '30%',
							//minWidth: '420px',
							//pointerEvents: 'none',
							//backgroundColor: 'blue',
							//flexDirection: 'column',
							//zIndex: 1,
						}}
					>
						<IonCol
							className="ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								//pointerEvents: 'auto',
								flex: 1,
								//flexBasis: tabletPortraitBreak ? '100%' : '30%',
								//flexGrow: 0,
								//flexShrink: 0,
								//width: tabletPortraitBreak ? '100%' : '30%',
								//minWidth: '420px',

								//marginTop: mobileBreak ? '0px' : '0px',
								//position: 'fixed',
								//
								backgroundColor: 'white',
								//zIndex: 99,
							}}
						>
							<IonRow
								className="ion-no-padding ion-no-margin"
								style={{
									//display: 'flex',
									//display: 'block',
									flexDirection: 'column',
									width: '100%',
									//width: mobileBreak ? 'calc(100% - 32px)' : '100%',
									borderRadius: '0',
									boxShadow: 'none',
									backgroundColor: 'white',
								}}
							>
								<IonRow
									className="ion-no-padding ion-no-margin"
									//className="videoContainSidebar"
									style={{
										width: fittedPosition.width,
										position: 'absolute',
										//paddingRight: '16px',
										justifyContent: 'flex-end',
										zIndex: 99,
									}}
								>
									<BasketNav
										dark={false}
										showBasket={navProductBreak ? true : false}
										showClose={true}
										onClose={onClose}
										onBasketClick={onBasketClick}
									/>
								</IonRow>

								<IonRow
									className="ion-no-padding ion-no-margin"
									style={{
										//display: 'block',
										//display: 'flex',
										//position: 'absolute',
										//justifyContent: 'center',
										//marginLeft: '16px',
										//marginTop: '-60px',
										//marginRight: '16px',
										//width: mobileBreak ? 'calc(100% - 50px)' : '100%',
										width: fittedPosition.width,
										height: fittedPosition.height,

										backgroundColor: 'white',
										//left: fittedPosition.x,
										//top: fittedPosition.y,
									}}
								>
									<ReactPlayer
										//className="slideVideoSideBar"
										//ref={(player) => {
										//myPlayer = player;
										//}}
										//onEnded={onEnded}
										//onProgress={onProgress}
										//progressInterval={1}

										ref={(player) => {
											playerRef = player;
										}}
										loop={false}
										url={
											productData.productVideoUrl
												? productData.productVideoUrl
												: 'https://vimeo.com/518353278'
										}
										playing={
											tabletPortraitBreak
												? showMobileSideBar
													? playing
													: false
												: playing
										}
										autoPlay={true}
										playsinline={true}
										controls={true}
										volume={1.0}
										muted={false}
										width={fittedPosition.width}
										height={fittedPosition.height}
										onPlay={onPlayVideo}
										onEnded={onEnded}
									></ReactPlayer>
									{/*}
									<div
										className="ion-no-padding ion-no-margin"
										//className="videoContainSidebar"
										style={{
											//display: 'flex',
											width: fittedPosition.width,
											height: fittedPosition.height - 10,
											position: 'absolute',
											//backgroundColor: 'blue',
											//justifyContent: 'center',
											//alignItems: 'center',
										}}
									>
										<VideoBtn
											tabletPortraitBreak={tabletPortraitBreak}
											playing={playing}
											//icon="/ui/SVG/basket.svg"
											btnClass="videoLightBtn"
											btnOverClass="videoLightBtnOver"
											label="WATCH FULL VIDEO"
											onClick={() =>
												playVideo(
													productData.productVideoUrl
														? productData.productVideoUrl
														: 'https://vimeo.com/518353278'
												)
											}
										/>
										</div>*/}
								</IonRow>
								<IonCardContent
									style={{
										display: 'flex',
										//flex: 1,
										flexDirection: 'column',
										width: fittedPosition.width,
										//alignItems: 'flex-end',
										//marginLeft: '6px',
										//marginRight: '6px',
										////backgroundColor: 'blue',
									}}
								>
									<IonCardTitle className="productTitle">{productData.title}</IonCardTitle>
									{/*	<ProductControl
										productData={productData}
										commerceData={commerceData}
										onBasketClick={props.onBasketClick}
								/>*/}
								</IonCardContent>
								<IonCardContent
									style={{
										display: 'flex',
										//flex: 1,
										flexDirection: 'column',
										width: fittedPosition.width,
										//alignItems: 'flex-end',
										//marginLeft: '6px',
										//marginRight: '6px',
										////backgroundColor: 'blue',
									}}
								>

									<IonRow
										style={{
											marginTop: '-12px',
										}}
										className="factsContent"
									>
										{productData.descriptionTitle && (
											<h2 style={{ textTransform: 'uppercase' }}>
												{productData.descriptionTitle}
											</h2>
										)}
										{productData.descriptionBody && (
											<p>{productData.descriptionBody}</p>
										)}

										{productData.directionsTitle && (
											<h2 style={{ textTransform: 'uppercase' }}>
												{productData.directionsTitle}
											</h2>
										)}
										{productData.directionsBody && (
											<p>{productData.directionsBody}</p>
										)}
										{productData.flavourTitle && (
											<h2 style={{ textTransform: 'uppercase' }}>
												{productData.flavourTitle}
											</h2>
										)}
										{productData.flavourBody && (
											<p>{productData.flavourBody}</p>
										)}
										{productData.warningsTitle && (
											<h2 style={{ textTransform: 'uppercase' }}>
												{productData.warningsTitle}
											</h2>
										)}
										{productData.warningsBody && (
											<p>{productData.warningsBody}</p>
										)}

										{productData.infoImages.length > 0 && (
											<OwlCarousel
												className="owl-theme"
												margin={32}
												autoheight="true"
												items={1}
											>
												{productData.infoImages.map((image, index) => (
													<div
														className="carousel-item"
														key={'productImage' + index}
													>
														<img
															alt={
																'product-image' +
																productData.infoImages[index].title
															}
															id="product-image"
															src={productData.infoImages[index].url}
														/>
													</div>
												))}
											</OwlCarousel>
										)}
									</IonRow>
								</IonCardContent>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonContent>
			</IonRow>
		</IonPage>
	);
}
Product.propTypes = {
	allowRender: PropTypes.array,
	id: PropTypes.string,
	props: PropTypes.object,
	navigation: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	onBasketClick: PropTypes.func,
	//showCart: PropTypes.bool,
	onClick: PropTypes.func,
	craftData: PropTypes.object,
	onClose: PropTypes.func,
	refresh: PropTypes.bool,
};

export default Product;
