import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonList,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonText,
	IonRow,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from '../styles/video.scss';
import PropTypes from 'prop-types';
import svgPlayBtn from '../assets/ui/SVG/btn_play.svg';

const VideoHero = ({ onClick, data, width, height, index }) => {
	const [mouseOver, setMouseOver] = useState(false);
	const ratio = width / 1024;
	return (
		<IonRow
			className="videoFrame"
			style={{
				width: width,
				height: height,

				display: 'flex',
				flexDirection: 'column',
				cursor: 'pointer',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#000000',
				margin: '16px',
			}}
		>
			<IonRow
				style={{
					backgroundColor: 'black',
					//position: 'absolute',
					width: width,
					height: height,
					//alignItems: 'center',
					//justifyContent: 'center',
				}}
			>
				<ReactPlayer
					//className="slideVideoSideBar"
					//ref={(player) => {
					//myPlayer = player;
					//}}
					//onEnded={onEnded}
					//onProgress={onProgress}
					//progressInterval={1}
					light={true}
					loop={false}
					url={data.videoGridUrl}
					autoPlay={false}
					playsinline={false}
					playIcon={<></>}
					//muted=tabletPortraitBreak ? (playing ? false : true) : false

					volume={1.0}
					muted={true}
					width={width}
					height={height}
				></ReactPlayer>
			</IonRow>
			<IonRow
				onClick={() => onClick(data.videoGridUrl, data.slug)}
				onMouseEnter={() => setMouseOver(true)}
				onMouseLeave={() => setMouseOver(false)}
				style={{
					//backgroundColor: 'red',
					position: 'absolute',
					flexDirection: 'column',
					width: width,
					height: height,
					alignItems: 'flex-start',
					justifyContent: 'flex-end',
					paddingBottom: 32,
					//paddingTop: '32px',
				}}
			>
				<IonRow
					style={{
						position: 'absolute',
						//marginBottom: '64px',
						width: width,
						justifyContent: 'center',
						paddingBottom: 200,
						//marginTop: '-64px',
						//paddingTop: '32px',
					}}
				>
					<img
						style={{ opacity: mouseOver ? 1 : 0 }}
						className="btnPlay"
						alt="btnPlay"
						height="40px"
						src={svgPlayBtn}
					/>
				</IonRow>
				<div
					style={{
						marginLeft: '32px',
					}}
					className="videoTitle"
				>
					{data.title}
				</div>
			</IonRow>
		</IonRow>
	);
};

VideoHero.propTypes = {
	onClick: PropTypes.func,
	data: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
	index: PropTypes.number,
};

export default VideoHero;
