import React, { createRef, useEffect, useRef } from 'react';
import { Canvas, useFrame, extend, useThree } from 'react-three-fiber';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import ProductItem from '../screens/ProductItem';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

function CameraControls(props) {
	const { camera, gl } = useThree();
	const ref = useRef();
	useFrame(() => ref?.current?.update());
	return (
		<orbitControls
			ref={ref}
			passive={false}
			//target={[0, -2, 0]}
			{...props}
			args={[camera, gl.domElement]}
		/>
	);
}

export default CameraControls;
