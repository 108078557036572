import React, { useContext, useEffect, useState } from 'react';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import PropTypes from 'prop-types';
import { getGlobalCartCount, setGlobalCartCount } from '../services/CartCount';
import {
	getGlobalCommerceData,
	setGlobalCommerceData,
} from '../services/CommerceData';

import styles from '../styles/cart.scss';
function BasketCount(props) {
	//const { state, dispatch } = useContext(AppContext);

	//const cartCount = localStorage.getItem('cartCount');
	//console.log('##localStorage GET cartCount', cartCount);cartCount
	useEffect(() => {}, []);
	//get cartcount singleton
	const cartCount = getGlobalCartCount();
	return (
		<>
			{cartCount > 0 && (
				<IonRow
					className="ion-no-padding ion-no-margin"
					style={{
						position: 'absolute',
						width: '16px',
						height: '16px',
						flexDirection: 'column',
						marginLeft: props.marginLeft ? props.marginLeft : '10px',
						marginTop: props.marginTop ? props.marginTop : '10px',
						//padding: '5px',
						borderRadius: '20px',
						border: '0.2px solid white',
						backgroundColor: '#D1AC68',
						alignItems: 'center',
						justifyContent: 'center',
					}}

					//color="white"
				>
					<IonText
						className="ion-no-padding ion-no-margin"
						style={{
							fontFamily: 'MarkProMedium',
							color: 'white',
							fontSize: '11px',
						}}
					>
						{cartCount}
					</IonText>
				</IonRow>
			)}
		</>
	);
}

BasketCount.propTypes = {
	//showCart: PropTypes.bool,
	props: PropTypes.object,
	count: PropTypes.number,
	marginLeft: PropTypes.string,
	marginTop: PropTypes.string,
};

export default BasketCount;
