import ReactDOM from 'react-dom';
import React from 'react';
import App from './App';
import { IonApp, IonItem, IonItemGroup, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

ReactDOM.render(
	<IonReactRouter>
		<App />
	</IonReactRouter>,
	document.getElementById('root')
);
