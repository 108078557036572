import React from 'react';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import {
	IonButton,
	IonIcon,
	IonContent,
	IonPage,
	IonNav,
	RouteAction,
	IonRow,
	IonCol,
	IonGrid,
	IonText,
} from '@ionic/react';
import { star } from 'ionicons/icons';
import NavBtn from './NavBtn';
import PropTypes from 'prop-types';
import HamburgerMenu from 'react-hamburger-menu';
/* Styles */
import styles from '../styles/nav.scss';
import PageBtn from './PageBtn';
import BasketCount from './BasketCount';
import CloseBtn from './CloseBtn';
import UrlTest from '../services/UrlTest';
import GAEvent from '../services/GAEvent';

import svgWellnessLogo from '../assets/ui/SVG/nav_wellness_logo.svg';
import svgNavBasket from '../assets/ui/SVG/nav_basket.svg';
import svgNavFlower from '../assets/ui/SVG/nav_flower.svg';
import svgNavHoney from '../assets/ui/SVG/nav_honey.svg';
import svgNavScience from '../assets/ui/SVG/nav_science.svg';
import svgNavBook from '../assets/ui/SVG/nav_book.svg';

const BasketComponent = ({ toggleCart }) => {
	return (
		<IonRow
			style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				width: 100,
				marginLeft: 0,
			}}
		>
			<BasketCount />
			<NavBtn
				margin={false}
				id={3}
				color="navBtnColor1"
				icon={svgNavBasket}
				label="BASKET"
				onClick={toggleCart}
			/>
		</IonRow>
	);
};

BasketComponent.propTypes = {
	//showCart: PropTypes.bool,
	toggleCart: PropTypes.func,
};

class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0, openMobileNav: false };
		this.prevRoute;
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleHamburger = this.handleHamburger.bind(this);
		this.handleHamburgerBroke = this.handleHamburgerBroke.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	onClick(route) {
		this.setState({
			openMobileNav: false,
		});
		//console.log('route', route);
		//this.props.history.push('/pano/forest');
		//console.log('route', route);
		//console.log('this.prevRoute', this.prevRoute);
		//console.log('this.props.location.pathname', this.props.location.pathname);
		if (route != this.props.location.pathname) {
			//console.log('ALLOW NAV CHANGE');
			this.prevRoute = route;
			this.props.history.push(route);
		}
		//this.props.onClick(route);
	}
	onUrlClick(url) {
		console.log(url);
		const win = window.open(url, '_blank');
		if (win != null) {
			win.focus();
		}
	}
	onCloseProducts() {
		this.props.history.goBack();
	}

	handleHamburger() {
		console.log('handleHamburgerClick', this.state.openMobileNav);
		this.setState({
			openMobileNav: !this.state.openMobileNav,
		});
	}
	handleHamburgerBroke() {
		this.setState({
			openMobileNav: !this.state.openMobileNav,
		});
	}

	onClickBookUrl(url) {
		GAEvent.bookClicked();
		const win = window.open(url, '_blank');
		if (win != null) {
			win.focus();
		}
	}

	render() {
		//console.log('width', this.state.width, 'height', this.state.height);
		const pageWidth = this.state.width;
		const navProductBreak = pageWidth < 1300 ? true : false;
		const tabletPortraitBreak = pageWidth < 1030 ? true : false;
		const mobileBreak = pageWidth < 800 ? true : false; //540
		let showNav = true;
		let sidebarEval = 535;
		if (pageWidth < 1172) {
			sidebarEval = 400;
		}
		const navWidth = pageWidth - sidebarEval - 10;
		const isProductPage = this.props.location.pathname.match('product');
		const isFactsPage = this.props.location.pathname.match('facts');
		if (tabletPortraitBreak && isProductPage) {
			showNav = false;
		}
		if (tabletPortraitBreak && isFactsPage) {
			showNav = false;
		}
		if (!showNav) {
			return <></>;
		}
		const isProductionDomain = UrlTest.checkProductionDomain();
		//const showScience = !isProductionDomain;
		const showScience = false;
		return (
			<IonNav
				style={{
					width: this.props.moveBasket ? navWidth : '100%',
					height: mobileBreak
						? this.state.openMobileNav
							? '100%'
							: '100px'
						: '100px',
					//position: 'fixed',
					//zIndex: 10,
					//backgroundColor: 'green',
				}}
				className="ion-no-padding ion-no-margin"
			>
				<IonRow
					className={
						showNav
							? mobileBreak
								? this.state.openMobileNav
									? 'navBackgroundMobile'
									: 'navBackgroundBlack'
								: 'navBackgroundBlack'
							: ''
					}
					style={{
						width: this.props.moveBasket ? navWidth : '100%',
						minWidth: navWidth,
						alignItems: 'flex-start',
						//backgroundColor: 'pink',
						height: mobileBreak
							? this.state.openMobileNav
								? '100%'
								: '100px'
							: '100px',
					}}
				>
					<IonCol
						style={{
							display: 'flex',
							flexDirection: mobileBreak ? 'column' : 'row',
							flex: 5,
							//justifyContent: 'flex-start',
							alignItems: 'flex-start',
							//textAlign: 'center',
							//backgroundColor: 'pink',

							marginLeft: mobileBreak ? '20px' : '20px',
						}}
					>
						{showNav && (
							<img
								onClick={() => this.onClick('/')}
								//onClick={() => this.onUrlClick('https://www.comvita.co.nz/')}
								style={{ pointerEvents: 'all' }}
								className="navLogo"
								alt="welcome_logo"
								height="30px"
								//src="/ui/SVG/nav_logo.svg"
								src={svgWellnessLogo}
							/>
						)}

						<IonRow
							style={{
								display: 'flex',
								flexDirection: mobileBreak ? 'column' : 'row',
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
								//width: '450px',
								marginTop: mobileBreak ? '140px' : '0px',
								marginLeft: mobileBreak ? '25px' : '0px',
								//backgroundColor: 'blue',
							}}
						>
							{showNav && (
								<IonRow
									className="ion-no-padding ion-no-margin"
									style={{
										flexDirection: mobileBreak ? 'column' : 'row',
										transform: mobileBreak ? 'scale(1.8, 1.8)' : 'scale(1, 1)',
									}}
								>
									<NavBtn
										align={mobileBreak ? 'flex-start' : 'center'}
										id={0}
										color="navBtnColor1"
										icon={svgNavFlower}
										label={tabletPortraitBreak ? 'FOREST' : 'FOREST'}
										onClick={() => this.onClick('/pano/forest')}
									/>
									<NavBtn
										align={mobileBreak ? 'flex-start' : 'center'}
										id={1}
										color="navBtnColor1"
										icon={svgNavHoney}
										label={tabletPortraitBreak ? 'PRODUCTS' : 'PRODUCTS'}
										onClick={() => this.onClick('/pano/store')}
									/>
									{showScience && (
										<NavBtn
											align={mobileBreak ? 'flex-start' : 'center'}
											id={2}
											color="navBtnColor1"
											icon={svgNavScience}
											label={tabletPortraitBreak ? 'SCIENCE' : 'SCIENCE'}
											onClick={() => this.onClick('/science')}
										/>
									)}
									<NavBtn
										align={mobileBreak ? 'flex-start' : 'center'}
										id={2}
										color="navBtnColor1"
										icon={svgNavBook}
										label={tabletPortraitBreak ? 'BOOK' : 'BOOK'}
										onClick={() =>
											this.onClickBookUrl(
												'https://www.comvita.co.nz/store/new_comvita/pages/wellness-lab/wellness-lab.jsp'
											)
										}
									//onClick={() => this.onClick('/book')}
									/>
									{/*mobileBreak && (
										<IonRow
											className="ion-no-padding ion-no-margin"
											style={{
												marginLeft: '32px',
												//display: 'flex',
												//flexDirection: 'column',
												//alignItems: 'flex-end',
											}}
										>
											<BasketComponent
												toggleCart={this.props.toggleCart}
											//showCart={this.props.showCart}
											/>
										</IonRow>
										)*/}
								</IonRow>
							)}
						</IonRow>
					</IonCol>

					{!mobileBreak && !navProductBreak && this.props.moveBasket && (
						<IonRow
							className="ion-no-padding ion-no-margin"
							style={{
								//display: 'flex',
								flexDirection: 'row',
								//alignItems: 'flex-end',
							}}
						>
							<IonCol
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								{/*	<BasketComponent
									toggleCart={this.props.toggleCart}
								//showCart={this.props.showCart}
							/>*/}
							</IonCol>
							{/*
							<IonCol
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
									marginTop: '6px',
									//marginTop: '56px',
									//marginRight: '-6px',
								}}
							>
								<CloseBtn
									mode="light"
									onClick={this.onCloseProducts}
								></CloseBtn>
							</IonCol*/}
						</IonRow>
					)}
					{/*!mobileBreak && !this.props.moveBasket && !this.props.hideBasket && (
						<IonCol
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
							}}
						>
							<BasketComponent
								toggleCart={this.props.toggleCart}
							//showCart={this.props.showCart}
							/>
						</IonCol>
						)*/}
					{mobileBreak && showNav && (
						<IonCol
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
								paddingTop: '6px',
								paddingRight: '12px',
							}}
						>
							<IonButton
								onClick={() => this.handleHamburger()}
								color="transparent"
							>
								<HamburgerMenu
									isOpen={this.state.openMobileNav}
									menuClicked={() => this.handleHamburgerBroke()}
									width={28}
									height={25}
									strokeWidth={1}
									rotate={0}
									color="#FFE297"
									borderRadius={0}
									animationDuration={0.5}
								/>
							</IonButton>
						</IonCol>
					)}
				</IonRow>
			</IonNav>
		);
	}
}
Nav.propTypes = {
	history: PropTypes.object,
	onClick: PropTypes.func,
	//showCart: PropTypes.bool,
	moveBasket: PropTypes.bool,
	hideBasket: PropTypes.bool,
	toggleCart: PropTypes.func,
	location: PropTypes.object,
};

export default withRouter(Nav);
