import React, {
	Suspense,
	useLayoutEffect,
	useState,
	useRef,
	useEffect,
} from 'react';
import {
	Canvas,
	useLoader,
	useThree,
	useFrame,
	useResource,
} from 'react-three-fiber';
import * as THREE from 'three';
import { CubeTextureLoader, WebGLCubeRenderTarget, TextureLoader } from 'three';

import PropTypes from 'prop-types';

function EquirectangularToCubemap(renderer) {
	const { camera, gl, scene } = useThree();
	//this.renderer = gl;
	//this.scene = new THREE.Scene();

	//var gl = this.renderer.getContext();
	this.maxSize = gl.getContext().getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE);

	this.camera = new THREE.CubeCamera(1, 100000, 1);

	this.material = new THREE.MeshBasicMaterial({
		map: null,
		side: THREE.BackSide,
	});

	this.mesh = new THREE.Mesh(
		new THREE.IcosahedronGeometry(100, 4),
		this.material
	);
	scene.add(this.mesh);
}

EquirectangularToCubemap.prototype.convert = function (source, size) {
	console.log('####EquirectangularToCubemap');
	var mapSize = Math.min(size, this.maxSize);
	this.camera = new THREE.CubeCamera(1, 100000, mapSize);
	this.material.map = source;

	this.camera.update(this.renderer, this.scene);

	return this.camera.renderTarget.texture;
};

Cube.propTypes = {
	textures: PropTypes.array,
	texture: PropTypes.string,
	textureAlpha: PropTypes.string,
	alphas: PropTypes.array,
	size: PropTypes.number,
};

function Cube({ size, texture, textureAlpha }) {
	const { camera, gl } = useThree();
	//var loader = new THREE.TextureLoader();
	const map = useLoader(TextureLoader, texture);
	//map.mapping = THREE.CubeReflectionMapping;

	var equiToCube = new EquirectangularToCubemap(gl);
	console.log('equiToCube', equiToCube);
	var envMap = equiToCube.convert(8000, 1024);
	const ref = useRef();
	useEffect(() => {
		ref.current.setMatrixAt(0, new THREE.Matrix4());
	}, []);

	return (
		<instancedMesh ref={ref} args={[null, null, 1]}>
			<boxBufferGeometry attach="geometry" args={[-size, size, size]} />
			<meshBasicMaterial
				attachArray="material"
				map={map}
				//envMap={envMap}
				//alphaMap={alpha1}
				//transparent
				side={THREE.DoubleSide}
			/>
		</instancedMesh>
	);
}

PanoToCubeBox.propTypes = {
	props: PropTypes.object,
	texture: PropTypes.string,
	textureAlpha: PropTypes.string,
	size: PropTypes.number,
};

export default function PanoToCubeBox(props) {
	return (
		<Suspense fallback={null}>
			<Cube
				textureAlpha={props.textureAlpha}
				texture={props.texture}
				size={props.size}
			/>
		</Suspense>
	);
}

//	<Loader />
// envMap: cubeTexture
