import React, { Component } from 'react';
import {
	BrowserRouter,
	Redirect,
	Route,
	Switch,
	withRouter,
} from 'react-router-dom';
import { Canvas, useFrame, extend, useThree } from 'react-three-fiber';
import {
	IonApp,
	IonItem,
	IonItemGroup,
	IonRouterOutlet,
	IonPage,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import PropTypes from 'prop-types';
//Google
//import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import IntroSeo from './screens/IntroSeo';
import Pano from './screens/Pano';
import Product from './screens/Product';
import Shelf from './screens/Shelf';
import Facts from './screens/Facts';
import Science from './screens/Science';
import Book from './screens/Book';
import Video from './screens/Video';
import IntroVideo from './screens/IntroVideo';
import Videos from './screens/Videos';
import Home from './screens/Home';
import Error from './screens/Error';
import Cart from './screens/Cart';
import PanoOverlay from './screens/PanoOverlay';
import Nav from './nav/Nav';
import HotSpotParticles from './particles/HotSpotParticles';
import Preloader from './screens/Preloader';

/* Ionic */
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
//import '@ionic/react/css/ionic.bundle.css';
import '@ionic/react/css/utils.bundle.css';
//import '@ionic/react/css/global.bundle.css';

/* Basic CSS for apps built with Ionic */
//import '@ionic/react/css/normalize.css';
//import '@ionic/react/css/structure.css';
//import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
//import '@ionic/react/css/padding.css';
//import '@ionic/react/css/float-elements.css';
//import '@ionic/react/css/text-alignment.css';
//import '@ionic/react/css/text-transformation.css';
//import '@ionic/react/css/flex-utils.css';
//import '@ionic/react/css/display.css';

import { ApolloProvider } from '@apollo/client';
//Commerce Apollo
//import CommerceApollo, { setCraftData } from './commercetools/CommerceApollo';
//CraftcCMS Apollo
import CraftApollo from './craftCMS/CraftApollo';
import CraftData from './craftCMS/CraftData';

/* Fonts */
import './fonts/MarkPro-Light.otf';
import './fonts/MarkPro-Light.eot';
import './fonts/MarkPro-Light.woff';
import './fonts/MarkPro-Light.woff2';

/* Styles */
import styles from './styles/styles.scss';
import appStyles from './styles/App.scss';
import variableStyles from './theme/variables.scss';
import CoachForest from './coachscreens/CoachForest';
import VideoSeo from './screens/VideoSeo';

//const tagManagerArgs = {
// gtmId: 'GTM-'
//};

//TagManager.initialize(tagManagerArgs)

const TRACKING_ID = 'UA-9572492-26';
ReactGA.initialize(TRACKING_ID);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showNav: false,
			showPano: false,
			moveBasket: true,
			hideBasket: false,
			showCart: false,
			imagesLoaded: false,
			craftLoaded: false,
			showForestCoach: false,
			playIntro: false,
			commerceCartRefresh: false,
		};

		this.doneForestCoach = true;
		this.loadedCraftData = null;
		this.loadCraftOnce = false;
		this.currentApollo = CraftApollo;
		this.appVersion = process.env.REACT_APP_VERSION;
		this.disablePano = true;
		this.onVideoComplete = this.onVideoComplete.bind(this);
		this.toggleCart = this.toggleCart.bind(this);
		this.closeCart = this.closeCart.bind(this);
		this.openCart = this.openCart.bind(this);
		this.onCheckout = this.onCheckout.bind(this);
		this.volume = 0;
		this.fadeTimeout = 0;
		this.audio = new Audio(
			'https://storage.googleapis.com/wellneslab-bucket/images/audio/forestloop.mp3'
		);
		this.myCustomPageTransition = this.myCustomPageTransition.bind(this);
		this.handleImagesLoaded = this.handleImagesLoaded.bind(this);
		this.googleAnalyticsPageTrack = this.googleAnalyticsPageTrack.bind(this);
		this.handleCraftDataLoaded = this.handleCraftDataLoaded.bind(this);
		this.handleShowForestCoach = this.handleShowForestCoach.bind(this);
		this.handleHideForestCoach = this.handleHideForestCoach.bind(this);
		this.playIntro = this.playIntro.bind(this);
		this.hideIntro = this.hideIntro.bind(this);
		this.hideIntroTimeout = this.hideIntroTimeout.bind(this);
		this.setDoneForestCoach = this.setDoneForestCoach.bind(this);
		this.setDoneForestCoachTimeout = this.setDoneForestCoachTimeout.bind(this);
		this.playForestAudio = this.playForestAudio.bind(this);
		this.stopForestAudio = this.stopForestAudio.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	componentDidMount(nextProps) {
		this.doneForestCoach = false;
		/*this is a hack to get 2x GraphQL services working*/
		this.currentApollo = CraftApollo;
		this.setPanoNavState();
		console.log('####Comvita Wellness Lab v' + this.appVersion);
		window.addEventListener("focus", this.onFocus)
		window.addEventListener('blur', this.onBlur);
		//localStorage.setItem('cartCount', 0);
	}
	componentWillUnmount() {
		window.removeEventListener("focus", this.onFocus)
		window.removeEventListener("focus", this.onBlur)
	}

	onFocus() {
		console.log('onFocus');
		if (this.props.location.pathname.match('/pano/forest')) {
			this.playForestAudio();
		}
		if (this.props.location.pathname.match('/pano/front')) {
			this.playForestAudio();
		}
	}
	onBlur() {
		console.log('onBlur');
		this.stopForestAudio();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location != this.props.location) {
			this.setPanoNavState();
		}
	}

	googleAnalyticsPageTrack() {
		let isProductionDomain = /^(http(s)?(:\/\/))?(www\.)?wellnesslab\.comvita\.co\.nz(\/.*)?$/.test(
			window.location.href
		);
		isProductionDomain = true;
		if (isProductionDomain) {
			ReactGA.set({ page: this.props.location.pathname });
			ReactGA.pageview(this.props.location.pathname);
			//console.log('ReactGA', ReactGA);
		}
	}

	playForestAudio() {
		//this.audio.addEventListener('ended', () => this.setState({ play: false }));
		this.volume = 0;
		this.audio.play();
		clearTimeout(this.fadeTimeout);
		this.fadeAudioIn();

		this.audio.loop = true;
		//https://storage.googleapis.com/wellneslab-bucket/images/audio/forestloop.mp3
	}
	stopForestAudio() {
		clearTimeout(this.fadeTimeout);
		this.fadeAudioOut();
		//this.audio.removeEventListener('ended', () =>this.setState({ play: false }));
	}
	fadeAudioIn() {
		if (this.audio) {
			//console.log('##fadeAudioIn', this.audio.volume);
			if (this.volume < 1) {
				this.volume += 0.1;
				if (this.volume > 1) {
					this.volume = 1;
				}
				this.audio.volume = this.volume;
				this.fadeTimeout = setTimeout(() => this.fadeAudioIn(), 100);
			}
		}
	}
	fadeAudioOut() {
		if (this.audio) {
			//console.log('##fadeAudioOut', this.audio.volume);
			if (this.volume > 0) {
				this.volume -= 0.1;
				if (this.volume < 0) {
					this.volume = 0;
				}
				this.audio.volume = this.volume;
				this.fadeTimeout = setTimeout(() => this.fadeAudioOut(), 100);
			} else {
				this.audio.pause();
			}
		}
	}

	setPanoNavState() {
		//Google Analytics
		this.googleAnalyticsPageTrack();
		if (this.props.location.pathname == '/') {
			this.disablePano = true;
			this.stopForestAudio();
			//this.playIntro();
			this.setState({
				playIntro: false,
				showNav: false,
				showPano: false,
				showCart: false,
				hideBasket: false,
			});
		} else if (this.props.location.pathname.match('intro')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				playIntro: true,
				showNav: false,
				showPano: true,
				showCart: false,
				hideBasket: false,
			});
		} else if (this.props.location.pathname.match('product')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				hideBasket: false,
				moveBasket: true,
				showCart: false,
				showNav: true,
				//currentApollo: CommerceApollo,
			});
		} else if (this.props.location.pathname.match('shelf')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				hideBasket: true,
				moveBasket: false,
				showCart: false,
				showNav: false,
				//currentApollo: CommerceApollo,
			});
		} else if (this.props.location.pathname.match('facts')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				hideBasket: false,
				moveBasket: true,
				showCart: false,
				showNav: true,
				//currentApollo: CommerceApollo,
			});
		} else if (this.props.location.pathname.match('view')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				hideBasket: false,
				moveBasket: false,
				showCart: false,
				showNav: false,
				//currentApollo: CommerceApollo,
			});
		} else if (this.props.location.pathname.match('videos')) {
			this.disablePano = true;
			this.stopForestAudio();
			this.setState({
				hideBasket: false,
				moveBasket: false,
				showCart: false,
				showNav: true,
				//currentApollo: CommerceApollo,
			});
		} else if (this.props.location.pathname.match('pano')) {
			this.disablePano = false;
			if (this.props.location.pathname.match('/pano/forest')) {
				this.handleShowForestCoach();
				this.playForestAudio();
			}
			if (this.props.location.pathname.match('/pano/front')) {
				//this.playForestAudio();
			}
			if (this.props.location.pathname.match('/pano/store')) {
				this.stopForestAudio();
			}
			this.setState({
				showNav: true,
				moveBasket: false,
				showCart: false,
				hideBasket: false,
				//currentApollo: CraftApollo,
				showPano: true,
			});
		} else {
			this.disablePano = true;
			this.setState({
				showNav: true,
				moveBasket: false,
				showCart: false,
				hideBasket: false,
				//currentApollo: CraftApollo,
				showPano: false,
			});
		}
	}

	onVideoComplete() {
		this.props.history.push('/pano/forest');
	}

	toggleCart() {
		this.setState({ showCart: !this.state.showCart });
	}
	closeCart() {
		this.setState({ showCart: false });
	}
	onCheckout() {
		//console.log('APP onCheckout');
		this.closeCart();
		//this.setState({ commerceCartRefresh: !this.state.commerceCartRefresh });
	}
	openCart() {
		this.setState({ showCart: true });
	}
	onCloseProduct() {
		console.log('onCloseProduct');
	}
	onHideNav() {
		this.setState({ showNav: false });
	}
	onShowNav() {
		this.setState({ showNav: true });
	}

	myCustomPageTransition(baseEl, opts) {
		var anim1 = this.animationCtrl
			.create()
			.addElement(opts.leavingEl)
			.duration(1000)
			.iterations(10)
			.easing('ease-out')
			.fromTo('opacity', '1', '0');
		var anim2 = this.animationCtrl
			.create()
			.addElement(opts.enteringEl)
			.duration(1000)
			.iterations(10)
			.easing('ease-out')
			.fromTo('opacity', '0', '1');
		var anim3 = this.animationCtrl
			.create()
			.duration(1000)
			.iterations(10)
			.addAnimation([anim1, anim2]);
		return anim3;
	}

	handleImagesLoaded() {
		this.setState({ imagesLoaded: true });
	}

	handleCraftDataLoaded(data) {
		this.loadedCraftData = data;

		setTimeout(() => {
			this.setState({ craftLoaded: true });
			//setCraftData(data);

			//this.currentApollo = CommerceApollo.initAppollo();
		}, 100);
	}

	handleShowForestCoach() {
		this.setState({ showForestCoach: true });
	}
	handleHideForestCoach() {
		this.setState({ showForestCoach: false });
	}

	playIntro() {
		//	this.setState({ playIntro: true });
		this.props.history.push('/intro');
	}
	hideIntro() {
		//this.setState({ playIntro: false });
		//delay this stop intro call...
		setTimeout(this.hideIntroTimeout, 10);
		this.props.history.push('/pano/forest');
	}
	hideIntroTimeout() {
		//console.log('hideIntroTimeout');
		this.setState({ playIntro: false });
	}
	setDoneForestCoach() {
		setTimeout(this.setDoneForestCoachTimeout, 1000);
	}
	setDoneForestCoachTimeout() {
		this.doneForestCoach = true;
	}

	render() {
		//remove trailing slash hack

		const pathname = this.props.location.pathname;
		if (pathname !== '/' && /\/$/.test(pathname)) {
			this.props.history.replace(pathname.slice(0, -1));
		}

		let urlElements = window.location.href.split('/');

		let isProductionDomain = /^(http(s)?(:\/\/))?(www\.)?wellnesslab\.comvita\.co\.nz(\/.*)?$/.test(
			window.location.href
		);
		isProductionDomain = false;
		/*if (isProductionDomain == true) {
			return (
				<IonApp style={{ pointerEvents: 'none' }}>
					<IonRouterOutlet animation={this.myCustomPageTransition}>
						<Route path="/" component={Home} strict={false} exact />
						<Route path="/error" component={Error} strict={false} exact />
					</IonRouterOutlet>
				</IonApp>
			);
		}*/
		//console.log('###RENDER APP', this.state);

		return (
			<ApolloProvider client={this.currentApollo}>
				{/*this is a hack to get 2x GraphQL services working*/}
				{/*opollo federation is a better way to do this - https://www.apollographql.com/docs/federation/*/}
				{!this.state.craftLoaded && (
					<CraftData loaded={this.handleCraftDataLoaded} />
				)}

				{this.state.craftLoaded && (
					<>
						{this.state.showPano && (
							<Pano
								//disablePano={false}
								disablePano={this.disablePano}
								history={this.props.history}
								location={this.props.location}
							/>
						)}
						<IonApp style={!this.disablePano ? { pointerEvents: 'none' } : {}}>
							<IonRouterOutlet
								animation={this.myCustomPageTransition}
								animated={false}
							>
								<Route
									path="/"
									render={(props) => (
										<Home
											{...props}
											imagesLoaded={this.state.imagesLoaded}
											allowRender={this.state.imagesLoaded && this.props.location.pathname == '/'}
											playIntro={this.state.playIntro}
											onClick={this.playIntro}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>

								<Route
									path="/intro"
									render={(props) => <IntroSeo {...props} />}
									strict={false}
									exact
								/>
								<Route
									path="/pano/:id"
									render={(props) => <PanoOverlay {...props} />}
									strict={false}
									exact
								/>

								<Route
									path="/shelf/:id"
									render={(props) => (
										<Shelf
											allowRender={this.props.location.pathname.match('shelf')}
											{...props}
											craftData={this.loadedCraftData}
											onBasketClick={this.openCart}
										/>
									)}
									strict={false}
									exact
								/>

								<Route
									path="/product/:id"
									render={(props) => (
										<Product
											allowRender={this.props.location.pathname.match(
												'product'
											)}
											{...props}
											//refresh={this.state.commerceCartRefresh}
											//onClick={this.openCart}
											onBasketClick={this.openCart}
											//showCart={this.state.showCart}
											//onClose={this.onCloseProduct}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>

								<Route
									path="/facts/:id"
									render={(props) => (
										<Facts
											allowRender={this.props.location.pathname.match('facts')}
											{...props}
											onClose={this.onCloseFact}
											onBasketClick={this.openCart}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>
								<Route
									path="/science"
									render={(props) => (
										<Science
											allowRender={this.props.location.pathname.match(
												'science'
											)}
											{...props}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>
								<Route
									path="/book"
									render={(props) => (
										<Book
											allowRender={this.props.location.pathname.match('book')}
											{...props}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>
								<Route
									path="/science/videos/view/:id"
									render={(props) => <VideoSeo />}
									strict={false}
									exact
								/>
								<Route
									path="/science/videos"
									render={(props) => (
										<Videos
											allowRender={this.props.location.pathname.match('videos')}
											{...props}
											craftData={this.loadedCraftData}
										/>
									)}
									strict={false}
									exact
								/>
								<Route
									path="/error"
									render={(props) => <Error />}
									strict={false}
									exact
								/>
							</IonRouterOutlet>
						</IonApp>
						{this.state.showNav && (
							<Nav
								//showCart={this.state.showCart}

								hideBasket={this.state.hideBasket}
								location={this.props.location}
								moveBasket={this.state.moveBasket}
								toggleCart={this.toggleCart}
							/>
						)}
						{this.state.showCart && (
							<Cart
								onCheckout={this.onCheckout}
								onClose={this.closeCart}
								moveBasket={this.state.moveBasket}
							/>
						)}

						{this.state.showForestCoach && !this.doneForestCoach && (
							<>
								<CoachForest onClick={this.handleHideForestCoach} />
								{this.setDoneForestCoach()}
							</>
						)}

						<IntroVideo
							allowRender={this.props.location.pathname.match('/intro')}
							allowRenderIntro={this.props.location.pathname == '/'}
							hideIntro={this.hideIntro}
							playIntro={this.state.playIntro}
							history={this.props.history}
							location={this.props.location}
							craftData={this.loadedCraftData}
						/>

					</>

				)}
				{!this.state.imagesLoaded && this.state.craftLoaded && (
					<Preloader onLoaded={this.handleImagesLoaded} />
				)}
			</ApolloProvider>
		);
	}
}
App.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withRouter(App);

//https://www.freecodecamp.org/news/react-apollo-client-2020-tutorial/
