import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initCartCount = 0;
let globalCount = () => {
	throw new Error('you must getGlobalCartCount before setting its state');
};

export const getGlobalCartCount = singletonHook(initCartCount, () => {
	const [count, setCount] = useState(initCartCount);
	globalCount = setCount;
	return count;
});

export const setGlobalCartCount = (count) => globalCount(count);
