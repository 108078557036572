import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
	IonTitle,
	withIonLifeCycle,
	CreateAnimation,
	Animation,
	IonItemGroup,
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

class PanoOverlay extends React.Component {
	constructor(props) {
		super(props);
		this.state = { show: true, playAnimation: false };
	}

	ionViewDidEnter() {
		this.setState({ playAnimation: true });
	}

	ionViewDidLeave() {
		//this.setState({ playAnimation: false });
	}
	ionViewWillEnter() {}

	ionViewWillLeave() {
		//this.setState({ playAnimation: false });
	}

	render() {
		return (
			<IonPage>
				<CreateAnimation
					duration={1000}
					fromTo={{
						property: 'opacity',
						fromValue: '0',
						toValue: '1',
					}}
					delay="1000"
					easing="ease-out"
					play={this.state.playAnimation}
				>
					<IonContent>
						<IonGrid
							style={{
								height: '100%',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<IonRow
								style={{
									height: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<h1
									style={{
										backgroundColor: 'black',
									}}
								></h1>
							</IonRow>
						</IonGrid>
					</IonContent>
				</CreateAnimation>
			</IonPage>
		);
	}
}
//Click and drag to look around
export default withIonLifeCycle(PanoOverlay);
