import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import PropTypes, { func } from 'prop-types';
import PageBtn from '../nav/PageBtn';
import svgBasket from '../assets/ui/SVG/basket.svg';

const CART = gql`
	query me {
		me {
			activeCart {
				id
				version
			}
		}
	}
`;

/*
const CREATE_CART = gql`
  mutation {
	createMyCart(draft: {
	  currency: "NZD"
	  shippingAddress: { country: "NZ" }
	}) {
	  id
	  version
	}
  }`;
  */

const CREATE_CART = gql`
	mutation {
		createMyCart(
			draft: {
				currency: "NZD"
				country: "NZ"
				inventoryMode: ReserveOnOrder
				store: { typeId: "store", key: "wellnesslab-nz" }
			}
		) {
			id
			version
			inventoryMode
		}
	}
`;

const ADD_LINE_ITEM = gql`
	mutation(
		$id: String!
		$version: Long!
		$productId: String!
		$variantId: Int!
		$quantity: Long!
	) {
		updateMyCart(
			id: $id
			version: $version
			actions: {
				addLineItem: {
					productId: $productId
					variantId: $variantId
					quantity: $quantity
					# Use both channels for CMS
					supplyChannel: {
						typeId: "channel"
						# id : "d7a473d6-a216-4adc-bb17-818c529c40e4"
						key: "mainfreight-nz-primary"
					}
					distributionChannel: {
						typeId: "channel"
						#id : "4cd91191-0b16-4913-ae7f-529974ac7be2"
						key: "virtual-product-range"
					}
				}
			}
		) {
			id

			version
			lineItems {
				id
				quantity
				price {
					value {
						currencyCode
						centAmount
					}
					discounted {
						value {
							centAmount
							currencyCode
						}
					}
				}
				variant {
					id
					sku
					attributesRaw {
						name
						value
					}
				}
			}

			totalPrice {
				__typename
				type
				currencyCode
				centAmount
			}
		}
	}
`;

CommerceAddToCart.propTypes = {
	productId: PropTypes.string,
	variantId: PropTypes.number,
	variantSku: PropTypes.string,
	onClick: PropTypes.func,
	onClickAnalytic: PropTypes.func,
	quantity: PropTypes.number,
};
//https://www.apollographql.com/docs/tutorial/mutations/
let commerceData;
let allowAddItem = true;
let doAddItem = false;
export default function CommerceAddToCart({
	productId,
	variantId,
	onClick,
	onClickAnalytic,
	variantSku,
	quantity,
}) {
	const [createCart] = useMutation(CREATE_CART);
	const [addLineItem] = useMutation(ADD_LINE_ITEM);
	//const [refreshData, setRefreshData] = useState(false);
	//let allowAddItem = true;
	//let timeoutHandle;
	//console.log('##Init quantity', quantity);

	const { loading, error, data } = useQuery(CART);
	commerceData = data;
	//console.log('###CART DATA', commerceData);

	const getCartId = () => {
		let activeCartId = null;
		if (commerceData) {
			if (commerceData.me) {
				if (commerceData.me.activeCart) {
					activeCartId = commerceData.me.activeCart.id;
				}
			}
		}
		return activeCartId;
	};

	const doAllowAddItem = () => {
		allowAddItem = true;
		//setRefreshData(false);
	};

	const onAddToCart = () => {
		if (allowAddItem == true) {
			//Wait till the cart version has updated
			allowAddItem = false;

			const cart = commerceData.me.activeCart;

			if (!cart) {
				onClick();
				handleItemAdded();
			} else {
				//setRefreshData(true);
				onClick();
				setTimeout(handleItemAdded, 1000);
			}
			//setAllowAddItem(false);

			//handleItemAdded();
		}
	};

	const handleItemAdded = async () => {
		//setAllowAddItem(false);
		console.log('###handleItemAdded commerceData', commerceData);
		const cart = commerceData.me.activeCart
			? commerceData.me.activeCart
			: await createCart({ refetchQueries: ['me'] }).then(
				(result) => result.data.createMyCart
			);

		setTimeout(doAllowAddItem, 700);
		onClickAnalytic(cart.id);
		addLineItem({
			variables: {
				id: cart.id,
				version: cart.version,
				productId: productId,
				variantId: variantId,
				quantity: quantity,
			},
		});
	};

	if (loading) return <p>Loading...</p>;
	if (error)
		return (
			<>
				{console.log(error)}
				<p>{error.toString()}</p>
			</>
		);

	return (
		<>
			<PageBtn
				marginTop={20}
				id={0}
				icon={svgBasket}
				label="Add to Basket"
				onClick={() => onAddToCart()}
			></PageBtn>
		</>
	);
}
