import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
function NavBtn(props) {
	const [mouseOver, setMouseOver] = useState(false);

	let btnClass = 'disable-select  navBtn ' + props.color;
	let btnOverClass = 'disable-select  navBtn navBtnOver';
	//console.log('props.align', props.align);
	return (
		<IonRow
			style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'row',
				cursor: 'pointer',
				width: 120,
				//alignItems: 'center',
				justifyContent: props.align ? props.align : 'flex-start',
				//paddingRight: '10px',
				paddingTop: '12px',
				//paddingRight: '120px',
				marginLeft: props.margin == false ? '0px' : '32px',
				//backgroundColor: 'purple',
			}}
			className="navItem"
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
			onClick={props.onClick}
		//color="transparent"
		>
			<IonRow
				style={{
					width: '26px',
					//backgroundColor: 'red',
				}}
			>
				<img
					className="navIcon"
					alt="nav_icon"
					height="20px"
					src={props.icon}
				/>
			</IonRow>
			<div className={mouseOver ? btnOverClass : btnClass}>{props.label}</div>
		</IonRow>
	);
}

NavBtn.propTypes = {
	props: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	id: PropTypes.number,
	color: PropTypes.string,
	icon: PropTypes.string,
	margin: PropTypes.bool,
	align: PropTypes.string,
};

export default NavBtn;
