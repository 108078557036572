import React, {
	Suspense,
	useLayoutEffect,
	useState,
	useRef,
	useEffect,
} from 'react';
import {
	Canvas,
	useLoader,
	useThree,
	useFrame,
	useResource,
} from 'react-three-fiber';
import * as THREE from 'three';
import {
	CubeTextureLoader,
	TextureLoader,
	WebGLCubeRenderTarget,
	RGBEEncoding,
	NearestFilter,
	MathUtils,
} from 'three';
import { OrbitControls, Loader } from '@react-three/drei';
import PropTypes, { number } from 'prop-types';

let isLoading = false;

const SphereBox = ({
	texture,
	textureAlpha,
	size,
	onLoaded,
	rotation,
	allowAlpha,
}) => {
	const ref = useRef();
	//if (isLoading) {
	//	return <></>;
	//}
	//isLoading = true;

	useEffect(() => {
		if (ref.current) {
			ref.current.setMatrixAt(0, new THREE.Matrix4());
			ref.current.scale.x = -1;
		}
	}, []);

	//allTextures.map((item, i) => {
	//if (i == activePano) {
	//console.log('SphereBox load texture', isLoading, texture);
	const tex = useLoader(THREE.TextureLoader, texture);
	const alphaTex = useLoader(THREE.TextureLoader, textureAlpha);
	tex.encoding = alphaTex.encoding = RGBEEncoding;
	//tex.mapping = THREE.EquirectangularReflectionMapping;
	tex.minFilter = alphaTex.minFilter = THREE.LinearFilter;
	tex.magFilter = alphaTex.magFilter = THREE.NearestFilter;
	tex.flipY = alphaTex.flipY = true;
	isLoading = false;
	//console.log('SphereBox create ');
	onLoaded();

	return (
		<instancedMesh
			ref={ref}
			args={[null, null, 1]}
			renderOrder={1}
			rotation={[0, MathUtils.degToRad(rotation), 0]}
		>
			<sphereBufferGeometry attach="geometry" args={[size, 60, 40]} />
			<meshBasicMaterial
				attach="material"
				map={tex}
				alphaMap={alphaTex}
				transparent={true}
				depthWrite={true}
				depthTest={true}
				side={THREE.BackSide}
			/>
		</instancedMesh>
	);
};

SphereBox.propTypes = {
	props: PropTypes.object,
	activePano: PropTypes.number,
	allTextures: PropTypes.array,
	texture: PropTypes.string,
	textureAlpha: PropTypes.string,
	cloudRotation: PropTypes.number,
	rotation: PropTypes.number,
	size: PropTypes.number,
	onLoaded: PropTypes.func,
	allowAlpha: PropTypes.bool,
};

export default SphereBox;

//  <Loader />
