import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonList,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonText,
	IonRow,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef, useRef, useState } from 'react';
import styles from '../styles/video.scss';
import PropTypes from 'prop-types';
import svgPlayBtn from '../assets/ui/SVG/btn_play.svg';

const VideoThumb = ({ onClick, data, width, height, index }) => {
	const [mouseOver, setMouseOver] = useState(false);
	const ratio = width / 1024;
	const iconRatio = width / 500;
	return (
		<IonRow
			onClick={() => onClick(data.videoGridUrl, data.slug)}
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
			className={index % 2 === 0 ? 'videoBackBee' : 'videoBackComb'}
			style={{
				//backgroundColor: 'red',
				position: 'absolute',
				flexDirection: 'column',
				width: width,
				height: height,
				alignItems: 'center',
				justifyContent: 'flex-end',
				paddingBottom: 64 * ratio,
				//paddingTop: '32px',
			}}
		>
			<IonRow
				style={{
					position: 'absolute',
					//marginBottom: '64px',
					paddingBottom: 260 * ratio,
					//marginTop: '-64px',
					//paddingTop: '32px',
				}}
			>
				<img
					style={{ opacity: mouseOver ? 1 : 0 }}
					className="btnPlay"
					alt="btnPlay"
					height={40 * iconRatio}
					src={svgPlayBtn}
				/>
			</IonRow>
			<div className="videoTitle">{data.title}</div>
		</IonRow>
	);
};

VideoThumb.propTypes = {
	onClick: PropTypes.func,
	data: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
	index: PropTypes.number,
};

export default VideoThumb;
