import React from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

const PLACE_ORDER = gql`
	mutation($id: String!, $version: Long!) {
		createMyOrderFromCart(draft: { id: $id, version: $version }) {
			id
		}
	}
`;

CommercePlaceOrder.propTypes = {
	cart: PropTypes.object,
};

export default function CommercePlaceOrder({ cart }) {
	const [placeOrder] = useMutation(PLACE_ORDER);

	return (
		<button
			onClick={() =>
				placeOrder({
					variables: {
						id: cart.id,
						version: cart.version,
					},
					refetchQueries: ['me'],
				})
			}
		>
			Place order
		</button>
	);
}
