import * as THREE from 'three';
import ReactDOM from 'react-dom';
import React, { Suspense, useRef, useState, useEffect } from 'react';
import {
	CubeTextureLoader,
	TextureLoader,
	WebGLCubeRenderTarget,
	RGBEEncoding,
	NearestFilter,
} from 'three';

import {
	Canvas,
	extend,
	useFrame,
	useThree,
	useLoader,
} from 'react-three-fiber';
import PropTypes from 'prop-types';
import '../styles/styles.scss';

VidTexture.propTypes = {
	props: PropTypes.object,
	texture: PropTypes.string,
	position: PropTypes.array,
};

function VidTexture({ position, texture }) {
	const ref = useRef();

	//useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

	const tex = useLoader(THREE.TextureLoader, texture);
	tex.encoding = RGBEEncoding;
	//tex.mapping = THREE.EquirectangularReflectionMapping;
	tex.minFilter = THREE.LinearFilter;
	tex.magFilter = THREE.LinearFilter;
	//tex.flipX = true;

	return (
		<mesh
			ref={ref}
			position={[-4, 8.7, -20]}
			//renderOrder={100}
		>
			<planeGeometry attach="geometry" args={[16 * 3.8, 9 * 3.8, 10]} />
			<meshBasicMaterial
				attach="material"
				map={tex}
				//transparent={true}
				//opacity={1}
				//color={color}
				//depthWrite={false}
				//depthTe st={false}
				//side={THREE.DoubleSide}
				//alphaTest={false}
			/>
		</mesh>
	);
}

VidVimeo.propTypes = {
	props: PropTypes.object,
	vimeo: PropTypes.string,
	position: PropTypes.array,
};

function VidVimeo({ position, vimeo }) {
	const videoRef = useRef();

	//const [video] = useState(() => {
	const vid = document.createElement('video');
	vid.crossOrigin = 'anonymous';
	vid.setAttribute('playsinline', true);
	vid.playsinline = true;
	vid.playsInline = true;
	vid.loop = true;
	vid.muted = true;
	vid.volume = 0;
	vid.autoplay = true;

	getMediaURLForTrack(vid, vimeo);
	async function getMediaURLForTrack(vid, passed_url) {
		await fetch(passed_url, {
			method: 'HEAD',
		}).then((response) => {
			vid.src = response.url;
		});
	}
	//vid.src = vimeo;

	var promise = vid.play();
	if (promise !== undefined) {
		promise.catch((error) => {}).then(() => {});
	}
	//vid.play();
	//vid.pause();
	//vid.play();
	//return vid;
	//});
	//	position={[-4, 7.7, 0]}
	return (
		<group>
			<mesh rotation={[0, 0, 0]} position={[-3.5, 8.2, -20]}>
				<planeGeometry args={[157 * 0.38, 86 * 0.38]} />
				<meshBasicMaterial>
					<videoTexture attach="map" args={[vid]} />
				</meshBasicMaterial>
			</mesh>
		</group>
	);
}

VideoPanel.propTypes = {
	vimeo: PropTypes.string,
	props: PropTypes.object,
	texture: PropTypes.string,
	position: PropTypes.array,
};
export default function VideoPanel(props) {
	return (
		<Suspense fallback={null}>
			{/*<VidVimeo position={props.position} vimeo={props.vimeo} />*/}
			<VidTexture position={props.position} texture={props.texture} />
		</Suspense>
	);
}
