import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRow,
} from '@ionic/react';
import React from 'react';
import PropTypes from 'prop-types';

const Error = () => {
	return (
		<IonPage>
			<IonContent>
				<IonGrid
					className="ion-no-padding ion-no-margin"
					style={{
						height: '100%',
						width: '100%',
					}}
				>
					<IonRow
						style={{
							height: '100%',
							width: '100%',
						}}
					>
						<IonCard
							className="ion-no-padding ion-no-margin"
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '0',
								boxShadow: 'none',
								backgroundColor: 'white',
							}}
						>
							<IonCardHeader>
								<IonCardTitle>
									<h1>Page not found</h1>
								</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<h1>Bee has been lost...</h1>
							</IonCardContent>
						</IonCard>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Error;
