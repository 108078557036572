import {
	CreateAnimation,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonItemDivider,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
/* Styles */
import styles from '../styles/home.scss';
import fonts from '../styles/fonts.scss';
import { checkProductionDomain } from '../services/UrlTest';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
import parse from 'html-react-parser';
import { TweenLite, gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { TweenMax } from 'gsap/gsap-core';
import { Quad } from 'gsap/gsap-core';
gsap.registerPlugin(CSSPlugin);

import svgWelcomeBee from '../assets/ui/SVG/welcome_bee.svg';
import svgWelcomeLogo from '../assets/ui/SVG/welcome_logo2.svg';
import svgWelcomeComvita from '../assets/ui/SVG/welcome_comvita.svg';
import { imgHomeMetal, loadedBee } from './Preloader';




class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mouseOverBtn: false,
			width: window.innerWidth,
			height: window.innerHeight,
		};
		this.animationRef = createRef();
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.blackOverlayRef = React.createRef();
		this.onFadeFromBlack = this.onFadeFromBlack.bind(this);
		this.onFocus = this.onFocus.bind(this);
	}

	onFadeFromBlack(delay) {
		if (this.fadeTween) {
			this.fadeTween.kill();
		}

		if (this.blackOverlayRef.current) {
			this.fadeTween = TweenMax.fromTo(
				this.blackOverlayRef.current,
				0.6,
				{ autoAlpha: 1 },
				{
					autoAlpha: 0,
					ease: Quad.easeIn,
					delay: delay,
					//onComplete: this.fadeFromBlackComplete,
				}
			);
		}
	}
	fadeFromBlackComplete() {
		console.log('fadeToBlackComplete');
	}

	componentDidMount(nextProps) {
		window.addEventListener('resize', this.updateWindowDimensions);
		this.onFadeFromBlack(0.2);
		//window.addEventListener("focus", this.onFocus)
	}

	componentWillUnmount() {
		//window.removeEventListener("focus", this.onFocus)
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.allowRender !== this.props.allowRender) {
			this.onFadeFromBlack(0.2);
		}
	}

	onFocus() {
		//this.onFadeFromBlack(0.2);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	onMouseEnter() {
		this.setState({ mouseOverBtn: true });
	}
	onMouseLeave() {
		this.setState({ mouseOverBtn: false });
	}

	render() {
		const pageHeight = this.state.height;
		let tabletPortraitBreak = this.state.width < 800 ? true : false;
		let mobileBreak = this.state.width < 500 ? true : false; //540
		let pageheightBreak = pageHeight < 700;
		let smallerPageHeightBreak = pageHeight < 500;

		//let commingSoon = checkProductionDomain;
		let commingSoon = false;
		//console.log('###commingSoon', commingSoon);
		let homeTitle = CraftHelperUtil.getText(this.props.craftData, 'homeTitle');
		let homeBody = CraftHelperUtil.getText(this.props.craftData, 'homeBody');
		let homeImage = CraftHelperUtil.getImage(this.props.craftData, 'homeImage');


		//console.log('#RENDER Home');
		return (
			<IonPage>
				<div
					style={{
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						backgroundColor: '#19130D',
						//backgroundColor: 'red',
						width: '100%',
						height: '100%',
					}}
				>
					<div
						id="entercontainer"
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							//flex: 1,
							flexDirection: 'column',
							//backgroundColor: 'blue',
							width: '200px',
							height: '200px',
						}}
					>


						<div
							onClick={() => this.props.onClick()}
							onMouseEnter={() => this.onMouseEnter()}
							onMouseLeave={() => this.onMouseLeave()}
							style={{
								display: 'flex',
								//flex: 1,
								//width: '100%',

								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<img height="200px" alt="enter" src={loadedBee} />
							<div
								style={{
									alignItems: 'center',
									position: 'absolute',
									marginTop: "150px",
									paddingLeft: "8px",

								}}>
								<div className={this.state.mouseOverBtn ? 'newHomeBtn newHomeBtnOver  ' : 'newHomeBtn '}>ENTER</div>
							</div>
						</div>
					</div>
				</div>
				<div
					ref={this.blackOverlayRef}
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						alpha: 1,
						//zIndex: 999,
						//display: 'none',
						//justifyContent: 'center',
						//alignItems: 'center',
						pointerEvents: 'none',
						backgroundColor: '#19130D',
					}}
				></div>
			</IonPage>
			/*
			<IonPage
				style={{
					display: this.props.playIntro == true ? 'none' : 'flex',

					flex: 1,
					margin: 0,
					width: '100%',
					height: '100%',
					//position: 'absolute',
					//overflow: 'hidden',
				}}
			>
				<div
					style={{
						//display: 'flex',
						//flex: 1,
						margin: 0,
						width: '100%',
						height: '100%',
					}}
				>
					<div
						style={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
							width: '100%',
							height: '100%',
						}}
					>
						<div
							className="metalBackground  ion-align-items-center ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								flex: 1,
								width: '100%',
								height: '100%',
								//width: '25%',
								//minWidth: '375px',
								backgroundImage: 'url(' + imgHomeMetal + ')',
								//boxShadow: 'none',
								borderRadius: '0',
								backgroundColor: 'black',
								//display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',

								//marginLeft: '400px',
							}}
						>
							<div
								className="blended"
								style={{
									display: 'flex',
									//flex: 1,
									//height: '400px',
									flexDirection: 'column',
									justifyContent: 'space-around',
									alignItems: 'center',
									//backgroundColor: 'blue',
									width: smallerPageHeightBreak ? '140%' : '100%',
									transform: smallerPageHeightBreak
										? 'scale(0.70)'
										: pageheightBreak
										? 'scale(0.85)'
										: '',
									textAlign: 'center',
								}}
							>
								<div>
									<img
										//className="blended"
										alt="welcome_logo"
										id="header_logo"
										width="200px"
										src={svgWelcomeLogo}
									/>
								</div>

								<div
									style={{
										//display: 'flex',
										//flex: 1,
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',

										textAlign: 'center',
									}}
								>
									{
										<div className="welcome blended  engraved">
											{homeTitle}
										</div>
									}

									<img
										className="blended"
										alt="welcome_lab"
										id="welcome_lab"
										width="260px"
										src={svgWelcomeComvita}
									/>
									<div className="wellness blended  engraved">
										WELLNESS LAB
									</div>
								</div>
								<div
									style={{
										//display: 'flex',
										//flex: 1,
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										textAlign: 'center',
										marginTop: '32px',
										//backgroundColor: 'red',
										maxWidth: '400px',
										marginBottom: smallerPageHeightBreak ? '0px' : '16px',

										paddingRight: smallerPageHeightBreak
											? '0px'
											: pageheightBreak
											? '0px'
											: '32px',
										paddingLeft: smallerPageHeightBreak
											? '0px'
											: pageheightBreak
											? '0px'
											: '32px',
									}}
								>
									<div className="maori  blended  engraved ion-text-center">
										{parse(homeBody)}
									</div>
								</div>
								<div
									style={{
										marginTop: '16px',
										marginLeft: '-50px',
									}}
								>
									{commingSoon && (
										<div
											style={{
												cursor: 'pointer',
											}}
										>
											<img
												className="homeIcon"
												alt="welcome_bee"
												height="40px"
												src={svgWelcomeBee}
											/>

											<div
												className={
													this.state.mouseOverBtn
														? 'homeBtn homeBtnOver blendedOver engraved'
														: 'homeBtn blended engraved'
												}
											>
												Coming Soon
											</div>
										</div>
									)}
									{!commingSoon && (
										<div
											onClick={() => this.props.onClick()}
											onMouseEnter={() => this.onMouseEnter()}
											onMouseLeave={() => this.onMouseLeave()}
											style={{
												display: 'flex',
												flex: 1,
												//width: '100%',
												//justifyContent: 'center',
												cursor: 'pointer',
											}}
										>
											<img
												className="homeIcon"
												alt="welcome_bee"
												height="40px"
												src={svgWelcomeBee}
											/>
											<div
												className={
													this.state.mouseOverBtn
														? 'homeBtn homeBtnOver  engraved'
														: 'homeBtn blended engraved'
												}
											>
												Explore
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flex: tabletPortraitBreak ? 0 : 2,
								backgroundImage: 'url(' + homeImage + ')',
							}}
							className="homeBackground"
						></div>
					</div>
				</div>
			</IonPage>
			*/

		);
	}
}


Home.propTypes = {
	allowRender: PropTypes.bool,
	history: PropTypes.object,
	location: PropTypes.object,
	onClick: PropTypes.func,
	craftData: PropTypes.object,
	playIntro: PropTypes.bool,
	imagesLoaded: PropTypes.bool,
};

export default withIonLifeCycle(Home);
