import * as THREE from 'three';
import ReactDOM from 'react-dom';
import React, { Suspense, useRef, useState, useEffect } from 'react';
import {
	Canvas,
	extend,
	useFrame,
	useThree,
	useLoader,
} from 'react-three-fiber';
import HotSpotParticles from '../particles/HotSpotParticles';

import PropTypes from 'prop-types';
import '../styles/styles.scss';

HotSpot.propTypes = {
	position: PropTypes.array,
	color: PropTypes.string,
	onClick: PropTypes.func,
	link: PropTypes.number,
};

function HotSpot({ position, color, onClick, link }) {
	const ref = useRef();
	const [hovered, set] = useState(false);
	const useParticles = true;

	useEffect(
		() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'),
		[hovered]
	);

	const onPointerUp = () => {
		//console.log('##onPointerUp');
		onClick(link);
	};
	return (
		<group
			ref={ref}
			//scale={hovered ? [1.1, 1.1, 1.1] : [1, 1, 1]}
			position={position}
			renderOrder={100}
		>
			<HotSpotParticles count={50} color={color} hovered={hovered} />
			<mesh
				onPointerUp={onPointerUp}
				onPointerOver={() => set(true)}
				onPointerOut={() => set(false)}
			>
				<sphereGeometry attach="geometry" args={[7, 30, 30]} />
				<meshBasicMaterial
					transparent={true}
					opacity={0}
					color={color}
					attach="material"
					side={THREE.FrontSide}
				//depthWrite={false}
				//depthTest={false}
				//alphaTest={false}
				/>
			</mesh>
		</group>
	);
}

HotSpots.propTypes = {
	props: PropTypes.object,
	data: PropTypes.array,
	position: PropTypes.array,
	color: PropTypes.string,
	onClick: PropTypes.func,
};
export default function HotSpots(props) {

	let hotSpots = props.data.map((item, i) => {

		return (
			<HotSpot
				key={i}
				position={item.position}
				color={item.color}
				link={item.link}
				onClick={props.onClick}
			/>
		);
	});
	return <group>{hotSpots}</group>;
}
