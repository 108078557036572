import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/cart.scss';
import BasketBtn from './BasketBtn';
import CloseBtn from './CloseBtn';
function BasketNav(props) {
	return (
		<IonRow
			style={{
				//width: '100px',
				//float: 'right',
				//marginTop: '-6px',
				//width: '100px',
				//height: '100px',
				marginRight: '16px',
				//backgroundColor: 'red',
				//justifyContent: 'space-between',
			}}
		>
			{/*props.showBasket && (
				<BasketBtn
					mode={props.dark ? 'dark' : 'light'}
					onClick={props.onBasketClick}
				></BasketBtn>
			)*/}
			{props.showClose && (
				<CloseBtn
					mode={props.dark ? 'dark' : 'light'}
					onClick={props.onClose}
				></CloseBtn>
			)}
		</IonRow>
	);
}

BasketNav.propTypes = {
	props: PropTypes.object,
	dark: PropTypes.bool,
	count: PropTypes.number,
	onBasketClick: PropTypes.func,
	onClose: PropTypes.func,
	showClose: PropTypes.bool,
	showBasket: PropTypes.bool,
};

export default BasketNav;
