import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import CommercePlaceOrder from './CommercePlaceOrder';
import PropTypes from 'prop-types';
import styles from '../styles/cart.scss';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonModal,
	IonPopover,
	IonNav,
	IonRow,
	IonText,
} from '@ionic/react';
import PageBtn from '../nav/PageBtn';
import BasketBtn from '../nav/BasketBtn';
import QuantityGroup from '../nav/QuantityGroup';
import { getGlobalCartCount, setGlobalCartCount } from '../services/CartCount';
import {
	getGlobalCommerceData,
	setGlobalCommerceData,
} from '../services/CommerceData';
import CommerceState from './CommerceState';
import CommerceHelperUtil from './CommerceHelperUtil';

import svgBasket from '../assets/ui/SVG/basket.svg';
import svgCheckoutBasket from '../assets/ui/SVG/checkout_basket.svg';
import GAEvent from '../services/GAEvent';

const CART = gql`
	query me {
		me {
			activeCart {
				id
				version
				lineItems {
					id
					name(locale: "en")
					price {
						value {
							currencyCode
							centAmount
						}
						discounted {
							value {
								centAmount
								currencyCode
							}
						}
					}

					quantity
					variant {
						id
						sku
						attributesRaw {
							name
							value
						}
						images {
							url
						}
						availability {
							channels {
								total
								results {
									channel {
										__typename
										id
										key
										roles
									}
									availability {
										isOnStock
										restockableInDays
										availableQuantity
									}
								}
							}
						}
					}
				}
				totalPrice {
					currencyCode
					centAmount
				}
			}
		}
	}
`;

const CREATE_CART = gql`
	mutation {
		createMyCart(
			draft: {
				currency: "NZD"
				country: "NZ"
				inventoryMode: ReserveOnOrder
				store: { typeId: "store", key: "wellnesslab-nz" }
			}
		) {
			id
			version
			inventoryMode
		}
	}
`;

const UPDATE_LINE_ITEM_QUANTITY = gql`
	mutation(
		$id: String!
		$lineItemId: String!
		$version: Long!
		$quantity: Long!
	) {
		updateMyCart(
			id: $id
			version: $version
			actions: {
				changeLineItemQuantity: { lineItemId: $lineItemId, quantity: $quantity }
			}
		) {
			id
			version
			lineItems {
				id
				quantity
				variant {
					id
					sku
					attributesRaw {
						name
						value
					}
					availability {
						channels {
							total
							results {
								channel {
									__typename
									id
									key
									roles
								}
								availability {
									isOnStock
									restockableInDays
									availableQuantity
								}
							}
						}
					}
				}
			}
			totalPrice {
				__typename
				type
				centAmount
			}
		}
	}
`;

CommerceCart.propTypes = {
	props: PropTypes.object,
	onGetCartId: PropTypes.func,
	onCheckout: PropTypes.func,
	marginTop: PropTypes.string,
};

let isProductionDomain = /^(http(s)?(:\/\/))?(www\.)?wellnesslab\.comvita\.co\.nz(\/.*)?$/.test(
	window.location.href
);

//isProductionDomain = true;
let cartUrl = 'https://comvita.noodle-uat.com/cart/?cartId=';
if (isProductionDomain) {
	cartUrl = 'https://checkout.comvita.co.nz/cart/?cartId=';
}
cartUrl = null;
//console.log('####cartUrl', cartUrl);

const basketHeader = (status, marginTop) => {
	const basketTitle = 'MY BASKET';
	return (
		<IonCardHeader
			style={{
				marginTop: marginTop ? marginTop : '0px',
				//width: '100%',
				//backgroundColor: 'red',
			}}
		>
			<IonRow
				style={{
					display: 'flex',
					//width: '100%',
				}}
			>
				<IonRow
					className="ion-no-padding ion-no-margin"
					style={{
						//display: 'flex',
						//flexDirection: 'column',
						marginTop: '-px',
						marginRight: '8px',
						marginLeft: '0px',
						//justifyContent: 'center',
						//alignItems: 'flex-start',
					}}
				>
					<img
						className="basketIcon"
						alt="btn_baskey"
						height="20px"
						src={svgCheckoutBasket}
					/>
				</IonRow>
				<IonCardTitle color="primary" className="cartTitle">
					{basketTitle}
				</IonCardTitle>
			</IonRow>

			<IonText color="tertiary" className="cartCountMessage">
				{status}
			</IonText>
			<div className="cartLine"></div>
		</IonCardHeader>
	);
};

export default function CommerceCart(props) {
	//const [getUserQuantity, setUserQuantity] = useState(1);

	const [updateLineItemQuantity] = useMutation(UPDATE_LINE_ITEM_QUANTITY);
	const [createCart] = useMutation(CREATE_CART);
	const [allowAddItem, setAllowAddItem] = useState(true);

	const onQuantityClick = (count, id, type, sku, title) => {
		//setUserQuantity(count);
		//console.log('###onQuantityClick', lineItemId, 'count', count);
		if (allowAddItem) {
			if (type == 'add') {
				GAEvent.increaseQuantity(sku, title, getCartId());
			} else if (type == 'remove') {
				if (count == 0) {
					GAEvent.removeFromCart(sku, title, getCartId());
				} else {
					GAEvent.decreaseQuantity(sku, title, getCartId());
				}
			}
			handleUpdateLineItemQuantity(data, count, id);
		}
		setAllowAddItem(false);
	};

	const handleUpdateLineItemQuantity = async (data, count, lineItemId) => {
		const cart = data.me.activeCart
			? data.me.activeCart
			: await createCart({ refetchQueries: ['me'] }).then(
				(result) => result.data.createMyCart
			);
		setTimeout(setAllowAddItem, 700, true);
		//console.log('lineItemId', lineItemId, 'count', count);
		updateLineItemQuantity({
			variables: {
				id: cart.id,
				version: cart.version,
				lineItemId: lineItemId,
				quantity: count,
			},
		});
	};
	const { loading, error, data } = useQuery(CART);

	const basketTitle = 'MY BASKET';
	const globalCommerceData = getGlobalCommerceData();

	if (loading)
		return <>{basketHeader('Loading your basket...', props.marginTop)}</>;
	if (error)
		return (
			<>
				{console.log(error)}
				<p>{error.toString()}</p>
			</>
		);

	//console.log('COMMERCECART data.me.activeCart', data.me.activeCart);
	if (!data.me.activeCart)
		return (
			<>{basketHeader('No items added to your basket yet', props.marginTop)}</>
		);

	const getCartId = () => {
		if (data.me.activeCart) {
			return data.me.activeCart.id;
		} else {
			return null;
		}
	};
	//const commerceData = getData();
	//console.log('data', data);

	//localStorage.setItem('cartCount', cartCount);
	const stockChannel = 'mainfreight-nz-primary';
	let cartCount = 0;

	const lineItemList = data.me.activeCart.lineItems.map((lineItem) => {
		cartCount += lineItem.quantity;
		//console.log('cartCount', cartCount, 'lineItem.quantity', lineItem.quantity);
		const attributeArray = lineItem.variant.attributesRaw;
		let size = '';
		let title = '';
		//console.log('lineItem', lineItem);
		const varientAttributeList = attributeArray.map((attribute, l) => {
			{
				//console.log('attribute', attribute);
				if (attribute.name == 'Size') {
					size = attribute.value.key;
				}
				if (attribute.name == 'VariantName') {
					title = attribute.value;
				}
			}
		});

		//console.log('##lineItem sku', lineItem.variant.sku, 'title', title);
		let availableQuantity;

		availableQuantity = CommerceHelperUtil.getAvailableQuanityFromCartLineItem(
			stockChannel,
			lineItem.variant
		);
		//console.log(lineItem.variant.sku, 'availableQuantity', availableQuantity);

		const price = CommerceHelperUtil.normalizeLineItemPrice(lineItem);
		const discountedPrice = CommerceHelperUtil.normalizeLineItemDiscountPrice(
			lineItem
		);

		return (
			<div key={lineItem.id}>
				<div className="cartLineItemTitle">{title} </div>
				{/*<div className="cartLineItemSize">Size: {size} </div>*/}
				<div
					style={{
						display: 'flex',
						flex: 1,
						width: '100%',
						flexDirection: 'row',
						marginTop: '8px',

						justifyContent: 'space-between',
					}}
				>
					{lineItem.variant.images.length > 0 && (
						<div style={{ marginRight: '40px', height: '80px', width: '40px' }}>
							<img
								alt="cart-image"
								id="cart-image"
								src={lineItem.variant.images[0].url}
								height="80px"
								width="auto"

							//height="auto"
							//width={platformBreak ? '80%' : '640px'}
							/>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							flex: 1,
							width: '100%',
							//backgroundColor: 'red',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								//width: '100px',

								//height: '55px',
								alignItems: 'center',
							}}
						>
							<QuantityGroup
								allowAddItem={allowAddItem}
								allowRemove={true}
								scale={0.8}
								startCount={lineItem.quantity}
								sku={lineItem.variant.sku}
								title={title}
								cartData={data}
								lineItemId={lineItem.id}
								onClick={onQuantityClick}
								stockChannel={stockChannel}
								availableQuantity={availableQuantity}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								flex: 1,
								flexDirection: 'column',
							}}
						>
							<div
								className="cartLineItemPrice"
								style={{
									textDecoration: discountedPrice ? 'line-through' : 'none',
								}}
							>
								{price}
							</div>
							{discountedPrice && (
								<div className="cartLineItemPrice" style={{}}>
									{discountedPrice}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="cartItemLine"></div>
			</div>
		);
	});
	let cartCountInfo = cartCount > 1 ? ' items' : ' item';
	cartCountInfo = cartCount + cartCountInfo + ' added to your basket';

	const onCheckout = (cartId) => {
		GAEvent.checkoutCart(cartId);
		props.onCheckout();

		if (cartId != 0) {
			const url = cartUrl + cartId;
			const win = window.open(url, '_blank');
			if (win != null) {
				win.focus();
			}
		}
	};

	return (
		<>
			<>{basketHeader(cartCountInfo, props.marginTop)}</>
			<CommerceState cartCount={cartCount} />
			<IonCardContent>
				{lineItemList}
				<IonRow
					style={{
						display: 'flex',
						flex: 1,
						width: '100%',
						heigth: '100%',

						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<IonCol className="ion-no-padding cartLineItemTotal">
						Total Price
					</IonCol>

					<IonCol className="ion-no-padding cartLineItemTotalPrice ion-text-end">
						{CommerceHelperUtil.normalizeTotalPrice(
							data.me.activeCart.totalPrice
						)}
					</IonCol>
				</IonRow>
				<PageBtn
					marginTop={20}
					id={0}
					icon={svgBasket}
					label="Proceed to Secure Payment"
					onClick={() => onCheckout(data.me.activeCart.id)}
				/>
			</IonCardContent>
		</>
	);
}
