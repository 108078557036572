import * as THREE from 'three';
import React, { useRef, useMemo, Suspense } from 'react';
import { useFrame, useLoader, useThree } from 'react-three-fiber';
import '../styles/styles.scss';
import PropTypes from 'prop-types';
import Sprite from './Sprite';
import beeTexture from '../assets/bees/bee01.png';

Particles.propTypes = {
	count: PropTypes.number,
	mouse: PropTypes.object,
};

export default function Particles({ count, mouse }) {
	const mesh = useRef();
	const sprite = useRef();

	const light = useRef();
	const { size, viewport, scene } = useThree();
	const aspect = size.width / viewport.width;

	const spriteTexture = useLoader(
		THREE.TextureLoader,
		beeTexture
		//'https://i.imgur.com/Oj6RJV9.png'
	);

	const dummy = useMemo(() => new THREE.Object3D(), []);
	// Generate some random positions, speed factors and timings
	const particles = useMemo(() => {
		const temp = [];
		for (let i = 0; i < count; i++) {
			const t = Math.random() * 100;
			const factor = 20 + Math.random() * 100;
			const speed = 0.01 + Math.random() / 200;
			const xFactor = -50 + Math.random() * 100;
			const yFactor = -50 + Math.random() * 100;
			const zFactor = -50 + Math.random() * 100;

			temp.push({
				t,
				factor,
				speed,
				xFactor,
				yFactor,
				zFactor,
				mx: 0,
				my: 0,
			});
		}
		return temp;
	}, [count]);
	// The innards of this hook will run every frame
	useFrame((state) => {
		// Makes the light follow the mouse
		// light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 0)
		// Run through the randomized data to calculate some movement

		particles.forEach((particle, i) => {
			let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
			// There is no sense or reason to any of this, just messing around with trigonometric functions
			t = particle.t += speed / 2;
			const a = Math.cos(t) + Math.sin(t * 1) / 10;
			const b = Math.sin(t) + Math.cos(t * 2) / 10;
			const s = Math.cos(t);
			//particle.mx += (mouse.current[0] - particle.mx) * 0.01;
			//particle.my += (mouse.current[1] * -1 - particle.my) * 0.01;
			// Update the dummy object
			dummy.position.set(
				(particle.mx / 10) * a +
				xFactor +
				Math.cos((t / 10) * factor) +
				(Math.sin(t * 1) * factor) / 10,
				(particle.my / 10) * b +
				yFactor +
				Math.sin((t / 10) * factor) +
				(Math.cos(t * 2) * factor) / 10,
				(particle.my / 10) * b +
				zFactor +
				Math.cos((t / 10) * factor) +
				(Math.sin(t * 3) * factor) / 10
			);
			//dummy.scale.set(s, s, s);
			//dummy.rotation.set(s * 5, s * 5, s * 5);
			dummy.updateMatrix();
			// And apply the matrix to the instanced item
			if (mesh.current) {
				mesh.current.setMatrixAt(i, dummy.matrix);
			}
		});
		if (mesh.current) {
			mesh.current.instanceMatrix.needsUpdate = true;
		}
	});

	return (
		<>
			{/*<ambientLight />*/}
			{/*<pointLight ref={light} distance={1000} intensity={200} color="white" />*/}

			<ambientLight intensity={[1]} />
			<instancedMesh ref={mesh} args={[null, null, count]} renderOrder={10}>
				<boxGeometry attach="geometry" args={[0.8, 0.8, 0]} />
				<meshStandardMaterial
					map={spriteTexture}
					transparent={true}
					attach="material"
					opacity={1}
					side={THREE.FrontSide}
					depthWrite={false}
					depthTest={false}
				/>
			</instancedMesh>
		</>
	);
}

// <pointLight ref={light} distance={40} intensity={8} color="lightblue" />
