import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonModal,
	IonPopover,
	IonNav,
	IonRow,
	IonText,
} from '@ionic/react';
import React from 'react';
import PropTypes from 'prop-types';
import CommerceCart from '../commercetools/CommerceCart';
import PageBtn from '../nav/PageBtn';
import BasketBtn from '../nav/BasketBtn';
import CloseBtn from '../nav/CloseBtn';
import BasketNav from '../nav/BasketNav';

class Cart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,
		};

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	onBasketClick() {
		console.log('onBasketClick');
	}

	//	////

	render() {
		const pageWidth = this.state.width;
		let navProductBreak = pageWidth < 1300 ? true : false;
		let tabletPortraitBreak = pageWidth < 1030 ? true : false;
		let mobileBreak = pageWidth < 400;

		let sidebarEval = 535;
		if (pageWidth < 1172) {
			sidebarEval = 400;
		}

		const navWidth = pageWidth - sidebarEval - 10;
		const cartPos = sidebarEval;
		//console.log('this.props.moveBasket', this.props.moveBasket, cartPos);

		return (
			<IonRow
				style={{
					float: 'right',
					marginTop: tabletPortraitBreak ? '0px' : '20px',
					//flexDirection: 'column',
					width: tabletPortraitBreak ? '100%' : '375px',
					height: tabletPortraitBreak ? '100%' : '100px',
					marginRight: tabletPortraitBreak
						? '0px'
						: this.props.moveBasket
						? cartPos + 20 + 'px'
						: '20px',
				}}
				className="ion-no-padding ion-no-margin"
			>
				<IonCard
					className="ion-no-padding ion-no-margin"
					style={{
						width: '100%',
						//width: mobileBreak ? '100%' : '100px',
						paddingBottom: '6px',
						flexDirection: 'column',
						//padding: '5px',
						borderRadius: tabletPortraitBreak ? 0 : 16,
						backgroundColor: 'white',
					}}
				>
					{/*Commerce cart items*/}
					{/*	<BasketNav
							onClose={this.onBasketClose}
							onBasketClick={this.onBasketClick}
							//this.props.onClose
					/>*/}

					<IonRow
						style={{
							width: '100%',
							position: 'absolute',
							justifyContent: 'flex-end',
							zIndex: 999,
							//backgroundColor: 'blue',
						}}
					>
						<CloseBtn mode="dark" onClick={this.props.onClose}></CloseBtn>
					</IonRow>
					<IonRow
						style={{
							width: '100%',
							flexDirection: 'column',
							//zIndex: 1,
							//backgroundColor: 'red',
						}}
					>
						<CommerceCart
							onCheckout={this.props.onCheckout}
							marginTop={tabletPortraitBreak ? '0px' : '0px'}
						/>
					</IonRow>
				</IonCard>
			</IonRow>
		);
	}
}

Cart.propTypes = {
	moveBasket: PropTypes.bool,
	onClose: PropTypes.func,
	onCheckout: PropTypes.func,
};

export default Cart;

//Future notes
//Commertools library https://github.com/lauraluiz/commercetools-graphql-react
