import { Circle } from '@react-three/drei';
import {
	EffectComposer,
	Noise,
	Vignette,
	HueSaturation,
	GodRays,
	Bloom,
} from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import React, { Suspense, forwardRef, useEffect } from 'react';
import { useResource } from 'react-three-fiber';
import { Controls, useControl } from 'react-three-gui';
import { Mesh } from 'three';
import { OverrideMaterialManager } from 'postprocessing';
OverrideMaterialManager.workaroundEnabled = true;
import PropTypes from 'prop-types';

const Sun = forwardRef(function Sun(props, forwardRef) {
	return (
		<Circle
			args={[50, 50]}
			ref={forwardRef}
			position={[0, 50, -100]}
			renderOrder={2}
		>
			<meshBasicMaterial
				transparent={true}
				opacity={0.7}
				depthWrite={false}
				//depthTest={false}
				color={'white'}
			/>
		</Circle>
	);
});

CanvasEffects.propTypes = {
	props: PropTypes.object,
	godrays: PropTypes.bool,
};

export default function CanvasEffects({ godrays }) {
	const sunRef = useResource();
	// const sunRef = useResource<Mesh>()
	const bloom = true;

	const hue = useControl('Hue', {
		group: 'Postprocessing - HueSaturation',
		value: 3.11,
		min: 0,
		max: Math.PI * 2,
		type: 'number',
	});
	const saturation = useControl('saturation', {
		group: 'Postprocessing - HueSaturation',
		value: 2.05,
		min: 0,
		max: Math.PI * 2,
		type: 'number',
	});
	const noise = useControl('Opacity', {
		group: 'Postprocessing - Noise',
		value: 0.47,
		min: 0,
		max: 1,
		type: 'number',
	});

	const exposure = useControl('exposure', {
		group: 'PostProcessing - GodRays',
		value: 0.34,
		min: 0,
		max: 1,
		type: 'number',
	});

	const decay = useControl('decay', {
		group: 'PostProcessing - GodRays',
		value: 0.9,
		min: 0,
		max: 1,
		step: 0.6,
		type: 'number',
	});

	const blur = useControl('blur', {
		group: 'PostProcessing - GodRays',
		value: false,
		type: 'boolean',
	});

	useEffect(() => {}, []);

	return (
		<Suspense fallback={null}>
			{godrays && <Sun ref={sunRef} />}

			<>
				<EffectComposer multisampling={0}>
					{/*<HueSaturation hue={hue} saturation={saturation} />*/}
					{bloom && (
						<Bloom
							luminanceThreshold={0}
							luminanceSmoothing={0.9}
							height={300}
							opacity={0.2}
						/>
					)}
					{sunRef.current && godrays && (
						<GodRays
							sun={sunRef.current}
							exposure={exposure}
							decay={decay}
							blur={blur}
						/>
					)}
					<Vignette />
				</EffectComposer>
			</>
		</Suspense>
	);
}
//<GodRays sun={sunRef.current} exposure={exposure} decay={decay} blur={blur} />

// <Noise opacity={noise}  premultiply blendFunction={BlendFunction.ADD} />
// <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} opacity={2} /></EffectComposer>
//  <Vignette eskil={false} offset={0.1} darkness={1.1} />
//<Noise opacity={0.02} />
// <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} />
