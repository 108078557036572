import React, { useState } from 'react';
import { IonCol, IonRow } from '@ionic/react';
import PropTypes from 'prop-types';

import btnRemove from '../assets/ui/SVG/quantity_remove.svg';
import btnRemoveOver from '../assets/ui/SVG/quantity_remove_over.svg';
import btnAdd from '../assets/ui/SVG/quantity_add.svg';
import btnAddOver from '../assets/ui/SVG/quantity_add_over.svg';

QuantityBtn.propTypes = {
	props: PropTypes.object,
	type: PropTypes.string,
	onClick: PropTypes.func,
};

export default function QuantityBtn({ type, onClick }) {
	const [mouseOver, setMouseOver] = useState(false);

	const icon = type == 'add' ? btnAdd : btnRemove;
	const iconSelected = type == 'add' ? btnAddOver : btnRemoveOver;

	return (
		<div
			className="ion-no-padding ion-no-margin"
			style={{
				display: 'flex',
				//flex: 1,
				cursor: 'pointer',
				//	backgroundColor: 'green',
				//height: '67px',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
			}}
			onClick={onClick}
			onMouseDown={() => setMouseOver(true)}
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
			onMouseUp={() => setMouseOver(false)}
		>
			<img
				className="productRemoveIcon"
				alt="removeItem"
				height="40px"
				src={mouseOver ? iconSelected : icon}
			/>
		</div>
	);
}
