import * as THREE from 'three';
import ReactDOM from 'react-dom';
import React, {
	Suspense,
	useRef,
	useState,
	useEffect,
	forwardRef,
} from 'react';
import {
	CubeTextureLoader,
	TextureLoader,
	WebGLCubeRenderTarget,
	RGBEEncoding,
	NearestFilter,
} from 'three';

import {
	Canvas,
	extend,
	useFrame,
	useThree,
	useLoader,
} from 'react-three-fiber';
import PropTypes from 'prop-types';
import '../styles/styles.scss';

import gsap, { Sine, Quad, TweenMax } from 'gsap/gsap-core';

Panel.propTypes = {
	show: PropTypes.bool,
	props: PropTypes.object,
	texture: PropTypes.string,
	textureAlpha: PropTypes.string,
	position: PropTypes.array,
	color: PropTypes.string,
	onClick: PropTypes.func,
	link: PropTypes.number,
	id: PropTypes.number,
};

function Panel({
	position,
	color,
	onClick,
	link,
	texture,
	id,
	textureAlpha,
	show,
}) {
	const ref = useRef();
	//const [hovered, set] = useState(false);
	//useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])
	let moveTween;
	let doOnce = false;
	const tex = useLoader(THREE.TextureLoader, texture);
	tex.encoding = RGBEEncoding;
	//tex.mapping = THREE.EquirectangularReflectionMapping;
	//console.log('textureAlpha', textureAlpha);
	//const alphaTex = useLoader(THREE.TextureLoader, textureAlpha);

	tex.minFilter = THREE.LinearMipMapLinearFilter;
	tex.magFilter = THREE.LinearFilter;
	tex.anisotropy = 16;
	//tex.magFilter = THREE.LinearFilter;
	//tex.flipX = true;
	let allowHover = true;

	useEffect(() => {
		if (moveTween) {
			moveTween.kill();
		}
		allowHover = true;
		if (show) {
			moveTween = TweenMax.fromTo(
				ref.current.position,
				{
					delay: 0,
					duration: 0,
					y: 60,
					//autoAlpha: 0,
				},
				{
					delay: 1 + id * 0.3,
					duration: 1.4,
					y: 0,
					//autoAlpha: 1,
					onComplete: slideComplete,
					ease: Quad.easeOut,
				}
			);
		}

		return () => killTweens();
	}, []);

	const killTweens = () => {
		if (moveTween) {
			moveTween.kill();
		}
	};

	const slideComplete = () => {
		allowHover = true;
	};
	const onHover = () => {
		if (allowHover == true) {
			//set(true);
			document.body.style.cursor = 'pointer';
			if (ref.current) {
				moveTween = TweenMax.to(ref.current.position, {
					delay: 0,
					duration: 2,
					y: 3,
					ease: 'elastic',
				});
			}
		}
	};
	const onLeave = () => {
		if (allowHover == true) {
			//set(false);
			document.body.style.cursor = 'auto';
			if (ref.current) {
				moveTween = TweenMax.to(ref.current.position, {
					delay: 0,
					duration: 2,
					y: 0,
					ease: 'elastic',
				});
			}
		}
	};
	return (
		<mesh
			ref={ref}
			//scale={hovered ? [1.2, 1.2, 1.2] : [1, 1, 1]}
			position={position}
			renderOrder={100}
			onPointerUp={() => onClick(link)}
			onPointerOver={() => onHover()}
			onPointerOut={() => onLeave()}
		>
			<planeGeometry attach="geometry" args={[20 * 0.8, 30 * 0.8, 10]} />
			<meshBasicMaterial
				attach="material"
				map={tex}
				//alphaMap={alphaTex}
				transparent={true}
				//opacity={1}
				//color={color}
				depthWrite={true}
				depthTest={true}
				//side={THREE.DoubleSide}
				//alphaTest={true}
			/>
		</mesh>
	);
}

function PanelFloat(props) {
	return (
		<Suspense fallback={null}>
			<Panel
				position={props.position}
				color={props.color}
				link={props.link}
				onClick={props.onClick}
				texture={props.texture}
				textureAlpha={props.textureAlpha}
				id={props.id}
				show={props.show}
			/>
		</Suspense>
	);
}

PanelFloat.propTypes = {
	show: PropTypes.bool,
	props: PropTypes.object,
	textureAlpha: PropTypes.string,
	texture: PropTypes.string,
	position: PropTypes.array,
	color: PropTypes.string,
	onClick: PropTypes.func,
	link: PropTypes.number,
	id: PropTypes.number,
};

export default PanelFloat;
