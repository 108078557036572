import {
	CreateAnimation,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonItemDivider,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { fit, position, fitAndPosition } from 'object-fit-math';
/* Styles */
import styles from '../styles/video.scss';
import fonts from '../styles/fonts.scss';
import VideoBtn from '../nav/VideoBtn';
import { TweenMax } from 'gsap/gsap-core';
import { Sine } from 'gsap/gsap-core';
import gsap from 'gsap/gsap-core';
import { TweenLite } from 'gsap/gsap-core';
import CloseBtn from '../nav/CloseBtn';
import GAEvent, { CATEGORY_VIDEO_SCIENCE } from '../services/GAEvent';

class Video extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			playing: false,
			playFade: false,
			refresh: false,
			mouseOverBtn: false,
			width: 0,
			height: 0,
		};
		this.playerRef = React.createRef();
		this.closeRef = React.createRef();
		this.closeTween;

		this.onProgress = this.onProgress.bind(this);
		this.onEnded = this.onEnded.bind(this);
		this.onClose = this.onClose.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	componentDidMount(prevProps) {
		//console.log('componentDidMount this.slug', this.props.slug);
		window.addEventListener('resize', this.updateWindowDimensions);
		this.updateWindowDimensions();
	}
	componentDidUpdate(prevProps) {
		//console.log('componentDidUpdate this.slug', this.props.slug);
		if (prevProps.show != this.props.show) {
			this.setState({
				playing: this.props.show,
				playFade: this.props.show,
				refresh: !this.state.refresh,
				show: this.props.show,
			});

			if (this.closeRef.current && this.props.show == true) {
				this.closeTween = TweenMax.fromTo(
					this.closeRef.current,
					{
						delay: 0,
						duration: 0,
						autoAlpha: 0,
					},
					{
						delay: 1,
						duration: 1.4,
						autoAlpha: 1,
					}
				);
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	ionViewWillEnter() { }

	ionViewWillLeave() {
		this.setState({ playing: false, playFade: false });
	}
	ionViewDidLeave() { }

	onMouseEnter() {
		this.setState({ mouseOverBtn: true });
	}
	onMouseLeave() {
		this.setState({ mouseOverBtn: false });
	}
	onClose() {
		this.setState({ playing: false, playFade: false, show: false });
		//this.props.hideIntro();
		//console.log('onClose', this.props.slug);
		GAEvent.videoSkipped(CATEGORY_VIDEO_SCIENCE, this.props.slug);
		if (this.playerRef) {
			this.playerRef.seekTo(0);
		}
		this.props.onClose();
	}
	onPlayVideo() {
		if (this.playerRef) {
			this.playerRef.seekTo(0);
		}
		//console.log('onPlayVideo', this.props.slug);

		this.setState({ playing: true, playFade: false, show: true });
	}

	onProgress({ playedSeconds }) { }
	onEnded() {
		GAEvent.videoCompleted(CATEGORY_VIDEO_SCIENCE, this.props.slug);
		this.setState({ playing: false, playFade: false, show: false });
		this.props.onClose();
	}
	onLoaded() {
		this.setState({
			playing: true,
			playFade: true,
			refresh: !this.state.refresh,
			show: true,
		});
	}
	playVideo() {
		this.setState({
			playing: true,
			playFade: true,
			//refresh: !this.state.refresh,
			show: true,
		});
	}

	render() {
		const parentSize = { width: this.state.width, height: this.state.height };
		const childSize = { width: 1920, height: 1010 };
		const fitted = fit(
			parentSize,
			childSize,
			this.props.mobileBreak ? 'contain' : 'cover'
		);
		let isMobile = this.state.width <= 768;
		const fittedPosition = fitAndPosition(
			parentSize,
			childSize,
			this.props.mobileBreak ? 'contain' : 'cover',
			'50%',
			'50%'
		);

		const playIcon = (
			<IonRow className="ion-no-padding ion-no-margin">
				<img
					className="playIcon"
					alt="btn_play"
					height="30px"
					src={'/ui/SVG/nav_basket.svg'}
				/>
			</IonRow>
		);
		return (
			<IonRow
				className="ion-no-padding ion-no-margin"
				style={{
					display: this.state.show == true ? 'flex' : 'none',
					width: '100%',
					height: '100%',
					backgroundColor: 'black',
				}}
			>
				<IonRow
					className="ion-no-padding ion-no-margin"
					id="closebtn"
					ref={this.closeRef}
					style={{
						//backgroundColor: 'blue',
						width: '100%',
						justifyContent: 'flex-end',
						//alignItems: 'flex-end',
						position: 'absolute',
						zIndex: 30,
						//transform: ' scale3d(1, 1, 1)',
					}}
				>
					<CloseBtn mode={'light'} onClick={this.onClose}></CloseBtn>
				</IonRow>
				<IonRow
					//className="videoContain"

					style={{
						//backgroundColor: 'blue',
						position: 'absolute',
						width: fittedPosition.width,
						height: fittedPosition.height,
						left: fittedPosition.x,
						top: fittedPosition.y,
					}}
				>
					<ReactPlayer
						//className="slideVideo"
						ref={(player) => {
							this.playerRef = player;
						}}
						onPlay={this.onPlayVideo}
						onEnded={this.onEnded}
						key={this.props.url}
						url={this.props.url}
						playing={this.state.playing}
						controls={this.props.mobileBreak}
						autoPlay={true}
						playsinline={true}
						loop={false}
						volume={1.0}
						muted={false}
						width="100%"
						height="100%"
					//playIcon="/ui/2x/videoplay@2x.png"
					//light="/ui/2x/videoplay@2x.png"
					/>
				</IonRow>
			</IonRow>
		);
	}
}

Video.propTypes = {
	slug: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	url: PropTypes.string,
	mobileBreak: PropTypes.bool,
};

export default withIonLifeCycle(Video);
