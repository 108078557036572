import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonTitle,
	IonRow,
	IonText,
	withIonLifeCycle,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
	useIonViewWillLeave,
} from '@ionic/react';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { easeSinOut } from 'd3-ease';

import { fit, position, fitAndPosition } from 'object-fit-math';
import PropTypes from 'prop-types';

import fonts from '../styles/coach.scss';
import navStyles from '../styles/nav.scss';

import svgArrowDown from '../assets/ui/SVG/arrow-down.svg';
import svgArrowRightBottom from '../assets/ui/SVG/arrow-right-bottom.svg';
import svgArrowLeftBottom from '../assets/ui/SVG/arrow-left-bottom.svg';
import svgArrowRightSlight from '../assets/ui/SVG/arrow-right-slight.svg';
import svgArrowLeftSlight from '../assets/ui/SVG/arrow-left-slight.svg';

function CoachForest(props) {
	const [pageWidth, setWidth] = useState(window.innerWidth);
	const [pageHeight, setHeight] = React.useState(window.innerHeight);
	const [loaded, setLoaded] = useState(false);
	const [allowClose, setAllowClose] = useState(true);
	const valueRef = useRef();

	React.useEffect(() => {
		const handleWindowResize = () => {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
		};
		window.addEventListener('resize', handleWindowResize);
		window.addEventListener('touchstart', handleTouchStart, { passive: true });

		return () => {
			window.removeEventListener('resize', handleWindowResize);
			window.removeEventListener('touchstart', handleTouchStart);
		};
	}, []);
	useIonViewWillEnter(() => {
		//console.log('ionViewWillEnter event fired');
	});

	useIonViewWillLeave(() => {
		//console.log('ionViewWillLeave event fired');
	});

	useIonViewDidEnter(() => {
		//setPlaying(false);
	});

	useIonViewDidLeave(() => {
		//setPlaying(false);
	});

	const onDragStart = (event) => {
		if (allowClose) {
			setTimeout(onCloseComplete, 500);
		}
		setAllowClose(false);
	};
	const handleTouchStart = () => {
		if (allowClose) {
			setTimeout(onCloseComplete, 500);
		}
		setAllowClose(false);
	};

	const onClose = () => {
		if (allowClose) {
			setTimeout(onCloseComplete, 500);
		}
		setAllowClose(false);
	};

	const onCloseComplete = () => {
		//console.log('onCloseComplete');
		setAllowClose(true);
		props.onClick();
	};

	let navProductBreak = pageWidth < 1200 ? true : false;
	let tabletPortraitBreak = pageWidth < 800 ? true : false;
	let mobileBreak = pageWidth < 400 ? true : false; //540
	let videoWidthBreak = pageWidth < 1000 ? true : false; //540
	let pageheightBreak = pageHeight < 700;
	let smallerPageHeightBreak = pageHeight < 570;

	const parentSize = {
		width: pageWidth,
		height: pageHeight * 0.6,
	};
	const childSize = { width: 800, height: 600 };
	const fittedPosition = fitAndPosition(
		parentSize,
		childSize,
		'contain',
		'50%',
		'50%'
	);
	const treeText1 = 'Click on tree';
	const treeText2 = 'to find out more';
	const beeText1 = 'Click on beehive';
	const beeText2 = 'to find out more';
	const storeText = 'Click to explore the store';
	const swipeText = 'Swipe to look around';

	//Animation props
	const ani1 = useSpring({
		opacity: 1,
		marginTop: 0,
		from: { opacity: 0, marginTop: -100 },
		delay: 2000,
	});
	const ani2 = useSpring({
		opacity: 1,
		marginLeft: 0,
		from: { opacity: 0, marginLeft: -100 },
		delay: 3100,
	});
	const ani3 = useSpring({
		opacity: 1,
		marginRight: 0,
		from: { opacity: 0, marginRight: -100 },
		delay: 4200,
	});
	const ani4 = useSpring({
		opacity: 1,
		marginBottom: 0,
		from: { opacity: 0, marginBottom: 100 },
		delay: 5500,
	});
	return (
		<IonPage
			style={{
				//display: 'flex',
				flex: 1,
				width: '100%',
				height: '100%',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#00000080',
				cursor: 'pointer',
			}}
		//draggable={true}
		//onDragStart={onDragStart}
		>
			<IonGrid
				className="ion-no-padding ion-no-margin disable-select"
				style={{
					pointerEvents: 'none',
					flex: 1,
					display: 'flex',
					width: smallerPageHeightBreak
						? fittedPosition.width * 1.2
						: fittedPosition.width,
					maxWidth: '600px',
					height: smallerPageHeightBreak ? pageHeight * 0.7 : pageHeight * 0.5,
					position: 'fixed',
					//display: 'none',
					//backgroundColor: 'pink',
					//position: 'absolute',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					transform: smallerPageHeightBreak
						? 'scale(0.72)'
						: pageheightBreak
							? 'scale(0.85)'
							: '',
					//transformOrigin: 'center center',

					//zIndex: 2,
				}}
			>
				<>
					<animated.div style={ani1}>
						<IonRow
							className="ion-no-padding ion-no-margin"
							style={{
								display: 'flex',
								flex: 1,
								pointerEvents: 'none',
								//backgroundColor: 'red',

								//marginBottom: '40px',
								marginLeft: '-50px',
								//display: 'none',
								//backgroundColor: 'pink',
								//position: 'absolute',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								//paddingTop: '60px',
								//zIndex: 2,
							}}
						>
							<IonText
								className="coachTitle disable-select"
								style={{
									pointerEvents: 'none',
									marginLeft: '20px',
								}}
							>
								{storeText}
							</IonText>
							<img
								className="coachArrow"
								height="38px"
								alt="arrow-down"
								id="arrow-down"
								src={svgArrowDown}
							/>
						</IonRow>
					</animated.div>
					<IonRow
						className="ion-no-padding ion-no-margin"
						style={{
							display: 'flex',
							flex: 1,
							width: '100%',
							pointerEvents: 'none',
							justifyContent: 'space-between',
							//marginLeft: '-600px',
							//marginTop: '-20px',
							//backgroundColor: 'red',
							flexDirection: 'row',
						}}
					>
						<animated.div style={ani2}>
							<IonRow
								className="ion-no-padding ion-no-margin"
								style={
									(ani2,
									{
										display: 'flex',
										flex: 1,
										//backgroundColor: 'blue',
										paddingLeft: '16px',
										alignItems: 'flex-start',
										pointerEvents: 'none',
										//marginLeft: '-600px',
										//marginTop: '-20px',
										//marginTop: '-30px',
										paddingTop: '-30px',
										flexDirection: 'column',
									})
								}
							>
								<IonText
									className="coachTitle disable-select"
									style={{
										pointerEvents: 'none',
									}}
								>
									{treeText1}
								</IonText>
								<IonText
									className="coachTitle disable-select"
									style={{
										pointerEvents: 'none',
									}}
								>
									{treeText2}
								</IonText>
								<img
									className="coachArrow disable-select"
									height="38px"
									alt="arrow-right"
									id="arrow-right"
									src={svgArrowRightBottom}
								/>
							</IonRow>
						</animated.div>
						<animated.div style={ani3}>
							<IonRow
								className="ion-no-padding ion-no-margin"
								style={{
									display: 'flex',
									flex: 1,
									alignItems: 'flex-end',
									paddingRight: '16px',
									//backgroundColor: 'green',
									//marginLeft: '320px',
									//marginTop: '30px',
									flexDirection: 'column',
								}}
							>
								<IonText
									className="coachTitle disable-select"
									style={{
										pointerEvents: 'none',
									}}
								>
									{beeText1}
								</IonText>
								<IonText
									className="coachTitle disable-select"
									style={{
										pointerEvents: 'none',
									}}
								>
									{beeText2}
								</IonText>
								<img
									className="coachArrow disable-select"
									height="38px"
									alt="arrow-left-bottom"
									id="arrow-left-bottom"
									src={svgArrowLeftBottom}
								/>
							</IonRow>
						</animated.div>
					</IonRow>

					<animated.div style={ani4}>
						<IonRow
							className="ion-no-padding ion-no-margin"
							style={{
								//marginTop: '100px',
								//marginLeft: '0px',
								alignItems: 'flex-end',
								display: 'flex',
								flex: 1,

								//backgroundColor: 'yellow',
							}}
						>
							<img
								className="coachArrow disable-select"
								width="48px"
								alt="arrow-left-slight"
								id="arrow-left-slight"
								src={svgArrowLeftSlight}
							/>
							<IonText
								className="coachTitle disable-select"
								style={{
									pointerEvents: 'none',
								}}
							>
								{swipeText}
							</IonText>
							<img
								className="coachArrow disable-select"
								width="48px"
								alt="arrow-right-slight"
								id="arrow-right-slight"
								src={svgArrowRightSlight}
							/>
						</IonRow>
					</animated.div>
				</>
			</IonGrid>
			<div
				style={{
					width: '100%',
					height: '100%',
					position: 'absolute',

					cursor: 'pointer',
				}}
				onMouseDown={onClose}
			></div>
		</IonPage>
	);
}
CoachForest.propTypes = {
	props: PropTypes.object,
	navigation: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
	onClick: PropTypes.func,
	craftData: PropTypes.object,
	onClose: PropTypes.func,
};

export default CoachForest;
