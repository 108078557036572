import * as THREE from 'three';
import React, { useRef, useMemo, Suspense } from 'react';
import { useFrame, useLoader, useThree } from 'react-three-fiber';
import '../styles/styles.scss';
import PropTypes from 'prop-types';
import Sprite from './Sprite';
import particleTexture from '../assets/bees/bee02.png';

function HotSpotParticles({ count, color, hovered }) {
	const mesh = useRef();
	const light = useRef();
	const { viewport, mouse, size, gl, scene } = useThree();
	const aspect = size.width / viewport.width;
	const spriteTexture = useLoader(
		THREE.TextureLoader,
		particleTexture
		//'https://i.imgur.com/Oj6RJV9.png'
	);

	const dummy = useMemo(() => new THREE.Object3D(), []);
	// Generate some random positions, speed factors and timings
	const particles = useMemo(() => {
		const temp = [];
		for (let i = 0; i < count; i++) {
			const t = Math.random() * 100;
			const factor = 1 + Math.random() * 60;
			const speed = 0.01 + Math.random() / 100;
			//const xFactor = -50 + Math.random() * 100;
			//const yFactor = -50 + Math.random() * 100;
			//const zFactor = -50 + Math.random() * 100;
			const xFactor = 0;
			const yFactor = 0;
			const zFactor = 0;
			temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 });
		}
		return temp;
	}, [count]);
	// The innards of this hook will run every frame
	useFrame((state) => {
		// Makes the light follow the mouse
		//light.current.position.set(
		//(mouse.x * viewport.width) / 2,
		//(mouse.y * viewport.height) / 2,
		//0
		//);
		// Run through the randomized data to calculate some movement

		particles.forEach((particle, i) => {
			let { t, factor, speed, xFactor, yFactor, zFactor } = particle;
			// There is no sense or reason to any of this, just messing around with trigonometric functions
			t = particle.t += speed * 0.4;
			//	const a = Math.sin(t) + Math.sin(t * 1) * 0.1;
			//const b = Math.sin(t) + Math.sin(t * 2) * 0.1;
			const a = 0.1;
			const b = 0.1;
			const s = Math.sin(t) * 0.8;

			const xShape = hovered ? 8 : 10;
			const yShape = hovered ? 10 : 6;
			const zShape = hovered ? 6 : 8;
			const factorAdj = hovered ? factor * 0.8 : factor * 0.5;

			// Update the dummy object
			dummy.position.set(
				particle.mx * 0.5 * a +
				xFactor +
				Math.cos(t * 0.1 * factorAdj) +
				Math.sin(t * xShape) * factorAdj * 0.1,
				particle.my * 0.5 * b +
				yFactor +
				Math.sin(t * 0.1 * factorAdj) +
				Math.cos(t * yShape) * factorAdj * 0.1,
				particle.my * 0.5 * b +
				zFactor +
				Math.cos(t * 0.1 * factorAdj) +
				Math.sin(t * zShape) * factorAdj * 0.1
			);
			dummy.scale.set(s, s, s);
			//dummy.rotation.set(s * 5, s * 5, s * 5);
			dummy.updateMatrix();
			// And apply the matrix to the instanced item
			if (mesh.current) {
				mesh.current.setMatrixAt(i, dummy.matrix);
			}
		});
		if (mesh.current) {
			mesh.current.instanceMatrix.needsUpdate = true;
		}
	});
	return (
		<>
			{color === null &&
				<ambientLight intensity={[1]} />
			}
			<instancedMesh ref={mesh} args={[null, null, count]} renderOrder={10}>
				<boxGeometry attach="geometry" args={[3, 3, 0]} />
				<meshStandardMaterial
					map={spriteTexture}
					transparent={true}
					attach="material"
					opacity={0.6}
					side={THREE.FrontSide}
					depthWrite={false}
					depthTest={false}
				/>
			</instancedMesh>
		</>
	);
}

HotSpotParticles.propTypes = {
	count: PropTypes.number,
	color: PropTypes.string,
	hovered: PropTypes.bool,
};

export default HotSpotParticles;

//research to check
//https://codesandbox.io/s/r3f-particles-ir6mk?file=/src/objects/Swarm.js
