import {
	CreateAnimation,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonItemDivider,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	withIonLifeCycle,
} from '@ionic/react';
import React, { createRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { fit, position, fitAndPosition } from 'object-fit-math';
import CraftHelperUtil from '../craftCMS/CraftHelperUtil';
/* Styles */
import styles from '../styles/video.scss';
import fonts from '../styles/fonts.scss';
import VideoBtn from '../nav/VideoBtn';
import { Sine } from 'gsap/gsap-core';
import { TweenLite, gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { TweenMax } from 'gsap/gsap-core';
import { Quad } from 'gsap/gsap-core';
import CloseBtn from '../nav/CloseBtn';
import GAEvent, {
	CATEGORY_VIDEO_INTRO,
	LABEL_VIDEO_INTRO,
} from '../services/GAEvent';

class IntroVideo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			closeClick: false,
			playing: false,
			playFade: false,
			mouseOverBtn: false,
			width: 0,
			height: 0,
		};
		this.playerRef = React.createRef();
		this.closeRef = React.createRef();
		this.closeTween;

		this.animationRef = createRef();
		this.onProgress = this.onProgress.bind(this);
		this.onEnded = this.onEnded.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onVideoClick = this.onVideoClick.bind(this);
		this.onPlayVideo = this.onPlayVideo.bind(this);
		this.blackOverlayRef = React.createRef();
		this.onFadeFromBlack = this.onFadeFromBlack.bind(this);

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		//this.audio = new Audio(
		//'https://storage.googleapis.com/wellneslab-bucket/images/audio/flyinaudio.mp3'
		//);
	}

	onFadeFromBlack(delay) {
		if (this.fadeTween) {
			this.fadeTween.kill();
		}

		if (this.blackOverlayRef.current) {
			this.fadeTween = TweenMax.fromTo(
				this.blackOverlayRef.current,
				0.8,
				{ autoAlpha: 1 },
				{
					autoAlpha: 0,
					ease: Quad.easeIn,
					delay: 1.2,
					//onComplete: this.fadeFromBlackComplete,
				}
			);
		}
	}
	fadeFromBlackComplete() {
		console.log('fadeToBlackComplete');
	}

	componentDidMount(nextProps) {
		this.setState({ playing: false, show: false, closeClick: false });
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.onFadeFromBlack(1);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.allowRender !== this.props.allowRender) {
			this.onFadeFromBlack(1);
		}
		if (
			this.props.playIntro == true &&
			this.state.playing == false &&
			this.state.closeClick == false
		) {
			this.setState({
				playing: true,
				playFade: true,
				show: true,
			});
			//console.log('PLAY INTRO');

			if (this.closeRef.current) {
				this.closeTween = TweenMax.fromTo(
					this.closeRef.current,
					{
						delay: 0,
						duration: 0,
						autoAlpha: 0,
					},
					{
						delay: 2,
						duration: 1.4,
						autoAlpha: 1,
					}
				);
			}
		}
		if (prevProps.playIntro != this.props.playIntro) {
			this.setState({
				playing: this.props.playIntro,
				playFade: this.props.playIntro,
				show: this.props.playIntro,
				closeClick: false,
			});
			if (this.playerRef) {
				this.playerRef.seekTo(0);
			}
			if (this.closeRef.current) {
				this.closeTween = TweenMax.fromTo(
					this.closeRef.current,
					{
						delay: 0,
						duration: 0,
						autoAlpha: 0,
					},
					{
						delay: 2,
						duration: 1.4,
						autoAlpha: 1,
					}
				);
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	ionViewWillEnter() {
		if (this.playerRef) {
			this.playerRef.seekTo(0);
		}
		//this.setState({ playing: false, playFade: true });
	}

	ionViewWillLeave() {
		this.setState({ playing: false, playFade: false });
	}
	ionViewDidLeave() {
		if (this.animationRef.current) {
			this.animationRef.current.animation.stop();
		}
	}

	onMouseEnter() {
		this.setState({ mouseOverBtn: true });
	}
	onMouseLeave() {
		this.setState({ mouseOverBtn: false });
	}
	onClose() {
		//GAEvent.videoViewed(CATEGORY_VIDEO_INTRO, LABEL_VIDEO_INTRO);
		//this.stopIntroAudio();
		GAEvent.videoSkipped(CATEGORY_VIDEO_INTRO, LABEL_VIDEO_INTRO);
		//GAEvent.videoCompleted(CATEGORY_VIDEO_INTRO, LABEL_VIDEO_INTRO);
		this.setState({
			playing: false,
			playFade: false,
			show: false,
			closeClick: true,
		});
		this.props.hideIntro();
		if (this.playerRef) {
			this.playerRef.seekTo(0);
		}
	}
	onVideoClick() {
		this.setState({
			playing: !this.state.playing,
			playFade: false,
			show: true,
			closeClick: false,
		});
		//this.props.hideIntro();
		if (this.playerRef) {
			//this.playerRef.seekTo(0);
		}
	}

	onPlayVideo() {
		//https://storage.googleapis.com/wellneslab-bucket/images/audio/flyinaudio.mp3
		//this.playIntroAudio();
		GAEvent.videoViewed(CATEGORY_VIDEO_INTRO, LABEL_VIDEO_INTRO);
	}

	/*playIntroAudio() {
		console.log("playIntroAudio");
		this.audio.play();
		this.audio.loop = false;
	}
	stopIntroAudio() {
		this.audio.pause();
	}
*/
	onProgress({ playedSeconds }) {
		if (this.playerRef) {
			//console.log('onProgress', this.playerRef , playedSeconds);

			if (playedSeconds >= 10) {
				//this.setState({ playing: false });
				//this.props.history.push('/pano');
			}
		}
	}
	onEnded() {
		GAEvent.videoCompleted(CATEGORY_VIDEO_INTRO, LABEL_VIDEO_INTRO);
		this.setState({
			playing: false,
			playFade: false,
			show: false,
			closeClick: true,
		});
		this.props.hideIntro();
		if (this.playerRef) {
			this.playerRef.seekTo(0);
		}
	}

	render() {
		const pageWidth = this.state.width;
		let navProductBreak = pageWidth < 1300 ? true : false;
		let tabletPortraitBreak = pageWidth < 1030 ? true : false;
		let mobileBreak = pageWidth < 400 ? true : false; //540
		const parentSize = { width: this.state.width, height: this.state.height };
		const childSize = { width: 1920, height: 1080 };
		const fitted = fit(parentSize, childSize, 'cover');
		const fittedPosition = fitAndPosition(
			parentSize,
			childSize,
			'cover',
			'50%',
			'50%'
		);
		const introUrl = CraftHelperUtil.getIntroVideo(this.props.craftData);
		let btnClass = 'disable-select  navBtn';
		let btnOverClass = 'disable-select  navBtn navBtnOver';
		const skipText = 'SKIP INTRO';
		if (!this.props.allowRender && !this.props.allowRenderIntro) {
			return <></>;
		} else {
			//console.log('#RENDER Intro');
			return (
				<IonPage
					style={{
						display: this.state.show == true ? 'flex' : 'none',
						backgroundColor: 'black',
					}}
				>
					<IonRow
						id="closebtn"
						ref={this.closeRef}
						style={{

							display: 'flex',
							width: '100%',
							//height: '30px',
							position: 'absolute',
							justifyContent: 'flex-end',
							//flexDirection: 'row',
							//alignItems: 'flex-end',
							//backgroundColor: '#c38314',
							zIndex: 999,
							//transform: ' scale3d(1, 1, 1)',
						}}
					>
						<div className="skipBtn">Skip Intro</div>
						<CloseBtn mode={'light'} onClick={this.onClose}></CloseBtn>
					</IonRow>
					<IonRow
						//className="videoContain"
						style={{
							position: 'absolute',
							width: fittedPosition.width,
							height: fittedPosition.height,
							left: fittedPosition.x,
							top: fittedPosition.y,
						}}
					>
						<ReactPlayer
							ref={(player) => {
								this.playerRef = player;
							}}
							onEnded={this.onEnded}
							onProgress={this.onProgress}
							url={introUrl}
							playing={this.state.playing}
							onPlay={this.onPlayVideo}
							playsinline={true}
							loop={false}
							volume={1.0}
							muted={false}
							width={fittedPosition.width}
							height={fittedPosition.height}
							allow={'fullscreen'}
						/>

						<IonRow
							className="ion-no-padding ion-no-margin"
							//className="videoContainSidebar"
							style={{
								//display: 'flex',
								width: '100%',
								height: '100%',
								position: 'absolute',
								zIndex: 10,
								//backgroundColor: 'red',
								//backgroundColor: 'blue',
								//justifyContent: 'center',
								//alignItems: 'center',
							}}
							onClick={tabletPortraitBreak ? this.onVideoClick : null}
						></IonRow>
					</IonRow>
					<div
						ref={this.blackOverlayRef}
						style={{
							width: '100%',
							height: '100%',
							position: 'absolute',
							alpha: 1,
							//zIndex: 999,
							//display: 'none',
							//justifyContent: 'center',
							//alignItems: 'center',
							pointerEvents: 'none',
							backgroundColor: '#19130D',
						}}
					></div>
				</IonPage>
			);
		}
	}
}

IntroVideo.propTypes = {
	allowRenderIntro: PropTypes.bool,
	allowRender: PropTypes.array,
	playIntro: PropTypes.bool,
	hideIntro: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
	craftData: PropTypes.object,
};

export default withIonLifeCycle(IntroVideo);
