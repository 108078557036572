import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getGlobalCartCount, setGlobalCartCount } from '../services/CartCount';
import {
	getGlobalCommerceData,
	setGlobalCommerceData,
} from '../services/CommerceData';

CommerceState.propTypes = {
	cartCount: PropTypes.number,
	commerceData: PropTypes.object,
};

export default function CommerceState({ cartCount, commerceData }) {
	useEffect(() => {
		if (cartCount) {
			setGlobalCartCount(cartCount);
		}
	}, [cartCount]);
	useEffect(() => {
		//console.log('###### CommerceState commerceData', commerceData);
		if (commerceData) {
			setGlobalCommerceData(commerceData);
		}
	}, [commerceData]);

	return <></>;
}
