import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

const initData = new Object();
let globalCommerceData = () => {
	throw new Error('you must useCommerceData before setting its state');
};

export const getGlobalCommerceData = singletonHook(initData, () => {
	const [data, setData] = useState(initData);
	globalCommerceData = setData;
	return data;
});

export const setGlobalCommerceData = (data) => globalCommerceData(data);
