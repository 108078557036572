import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonItem,
	IonGrid,
	IonList,
	IonPage,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
function PageBtn(props) {
	const [mouseOver, setMouseOver] = useState(false);
	let btnClass = 'pageBtn ';
	let btnOverClass = 'pageBtn pageBtnOver';
	if (props.btnClass) {
		btnClass = 'pageBtn ' + props.btnClass;
	}
	if (props.btnOverClass) {
		btnOverClass = 'pageBtn pageBtnOver ' + props.btnOverClass;
	}
	//console.log('props.marginTop', props.marginTop);
	return (
		<IonRow
			style={{
				cursor: 'pointer',
				marginTop: props.marginTop,
				zIndex: 999,
				//marginLeft: '-20px',
				//alignItems: 'flex-start',
			}}
			textAlign="left"
			size="large"
			className="navItem "
			slots="start"
			onMouseEnter={() => setMouseOver(true)}
			onMouseLeave={() => setMouseOver(false)}
			onClick={props.onClick}
			color="transparent"
		>
			{props.icon && (
				<img
					className="navIcon"
					alt="nav_icon"
					height="40px"
					src={props.icon}
				/>
			)}

			<div className={mouseOver ? btnOverClass : btnClass}>{props.label}</div>
		</IonRow>
	);
}

PageBtn.propTypes = {
	marginTop: PropTypes.number,
	props: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	id: PropTypes.number,
	color: PropTypes.string,
	icon: PropTypes.string,
	btnClass: PropTypes.string,
	btnOverClass: PropTypes.string,
};

export default PageBtn;
